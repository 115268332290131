export const Secondary_options = [
    {value: 'not_in_list', label: "Not in list"},
    {value: 'Admiralty Secondary School', label: "Admiralty Secondary School"},
    {value: 'Ahmad Ibrahim Secondary School', label: "Ahmad Ibrahim Secondary School"},
    {value: 'Anderson Secondary School', label: "Anderson Secondary School"},
    {value: 'Anglican High School', label: "Anglican High School"},
    {value: 'Anglo-Chinese School (Barker Road)', label: "Anglo-Chinese School (Barker Road)"},
    {value: 'Anglo-Chinese School (Independent)', label: "Anglo-Chinese School (Independent)"},
    {value: 'Ang Mo Kio Secondary School', label: "Ang Mo Kio Secondary School"},
    {value: 'Assumption English School', label: "Assumption English School"},
    {value: 'Bartley Secondary School', label: "Bartley Secondary School"},
    {value: 'Beatty Secondary School', label: "Beatty Secondary School"},
    {value: 'Bedok Green Secondary School', label: "Bedok Green Secondary School"},
    {value: 'Bedok South Secondary School', label: "Bedok South Secondary School"},
    {value: 'Bedok View Secondary School', label: "Bedok View Secondary School"},
    {value: 'Bendemeer Secondary School', label: "Bendemeer Secondary School"},
    {value: 'Boon Lay Secondary School', label: "Boon Lay Secondary School"},
    {value: 'Bowen Secondary School', label: "Bowen Secondary School"},
    {value: 'Broadrick Secondary School', label: "Broadrick Secondary School"},
    {value: 'Bukit Batok Secondary School', label: "Bukit Batok Secondary School"},
    {value: 'Bukit Merah Secondary School', label: "Bukit Merah Secondary School"},
    {value: 'Bukit Panjang Government High School', label: "Bukit Panjang Government High School"},
    {value: 'Bukit View Secondary School', label: "Bukit View Secondary School"},
    {value: 'Catholic High School', label: "Catholic High School"},
    {value: 'Canberra Secondary School', label: "Canberra Secondary School"},
    {value: "Cedar Girls' Secondary School", label: "Cedar Girls' Secondary School"},
    {value: 'Changkat Changi Secondary School', label: "Changkat Changi Secondary School"},
    {value: 'CHIJ Katong Convent (Secondary)', label: "CHIJ Katong Convent (Secondary)"},
    {value: 'CHIJ Secondary (Toa Payoh)', label: "CHIJ Secondary (Toa Payoh)"},
    {value: "CHIJ St. Joseph's Convent", label: "CHIJ St. Joseph's Convent"},
    {value: "CHIJ St. Nicholas Girls' School", label: "CHIJ St. Nicholas Girls' School"},
    {value: "CHIJ St. Theresa's Convent", label: "CHIJ St. Theresa's Convent"},
    {value: 'Chua Chu Kang Secondary School', label: "Chua Chu Kang Secondary School"},
    {value: 'Christ Church Secondary School', label: "Christ Church Secondary School"},
    {value: 'Chung Cheng High School (Main)', label: "Chung Cheng High School (Main)"},
    {value: 'Chung Cheng High School (Yishun)', label: "Chung Cheng High School (Yishun)"},
    {value: 'Clementi Town Secondary School', label: "Clementi Town Secondary School"},
    {value: 'Commonwealth Secondary School', label: "Commonwealth Secondary School"},
    {value: 'Compassvale Secondary School', label: "Compassvale Secondary School"},
    {value: "Crescent Girls' School", label: "Crescent Girls' School"},
    {value: 'Damai Secondary School', label: "Damai Secondary School"},
    {value: 'Deyi Secondary School', label: "Deyi Secondary School"},
    {value: 'Dunearn Secondary School', label: "Dunearn Secondary School"},
    {value: 'Dunman High School', label: "Dunman High School"},
    {value: 'Dunman Secondary School', label: "Dunman Secondary School"},
    {value: 'East Spring Secondary School', label: "East Spring Secondary School"},
    {value: 'Edgefield Secondary School', label: "Edgefield Secondary School"},
    {value: 'Evergreen Secondary School', label: "Evergreen Secondary School"},
    {value: 'Fairfield Methodist Secondary School', label: "Fairfield Methodist Secondary School"},
    {value: 'Fajar Secondary School', label: "Fajar Secondary School"},
    {value: 'Fuchun Secondary School', label: "Fuchun Secondary School"},
    {value: 'Fuhua Secondary School', label: "Fuhua Secondary School"},
    {value: 'Gan Eng Seng School', label: "Gan Eng Seng School"},
    {value: 'Geylang Methodist School (Secondary)', label: "Geylang Methodist School (Secondary)"},
    {value: 'Greendale Secondary School', label: "Greendale Secondary School"},
    {value: 'Greenridge Secondary School', label: "Greenridge Secondary School"},
    {value: 'Guangyang Secondary School', label: "Guangyang Secondary School"},
    {value: 'Hai Sing Catholic School', label: "Hai Sing Catholic School"},
    {value: 'Hillgrove Secondary School', label: "Hillgrove Secondary School"},
    {value: "Holy Innocents' High School", label: "Holy Innocents' High School"},
    {value: 'Hong Kah Secondary School', label: "Hong Kah Secondary School"},
    {value: 'Hougang Secondary School', label: "Hougang Secondary School"},
    {value: 'Hua Yi Secondary School', label: "Hua Yi Secondary School"},
    {value: 'Hwa Chong Institution', label: "Hwa Chong Institution"},
    {value: 'Junyuan Secondary School', label: "Junyuan Secondary School"},
    {value: 'Jurong Secondary School', label: "Jurong Secondary School"},
    {value: 'Jurong West Secondary School', label: "Jurong West Secondary School"},
    {value: 'Jurongville Secondary School', label: "Jurongville Secondary School"},
    {value: 'Juying Secondary School', label: "Juying Secondary School"},
    {value: 'Kent Ridge Secondary School', label: "Kent Ridge Secondary School"},
    {value: 'Kranji Secondary School', label: "Kranji Secondary School"},
    {value: 'Kuo Chuan Presbyterian Secondary School', label: "Kuo Chuan Presbyterian Secondary School"},
    {value: 'Loyang View Secondary School', label: "Loyang View Secondary School"},
    {value: 'Manjusri Secondary School', label: "Manjusri Secondary School"},
    {value: 'Maris Stella High School', label: "Maris Stella High School"},
    {value: 'Marsiling Secondary School', label: "Marsiling Secondary School"},
    {value: 'Mayflower Secondary School', label: "Mayflower Secondary School"},
    {value: 'Meridian Secondary School', label: "Meridian Secondary School"},
    {value: "Methodist Girls' School (Secondary)", label: "Methodist Girls' School (Secondary)"},
    {value: 'Montfort Secondary School', label: "Montfort Secondary School"},
    {value: 'Nan Chiau High School', label: "Nan Chiau High School"},
    {value: 'Nan Hua High School', label: "Nan Hua High School"},
    {value: "Nanyang Girls' High School", label: "Nanyang Girls' High School"},
    {value: "Nanyang Girls' High School", label: "National Junior College"},
    {value: 'Naval Base Secondary School', label: "Naval Base Secondary School"},
    {value: 'New Town Secondary School', label: "New Town Secondary School"},
    {value: 'Ngee Ann Secondary School', label: "Ngee Ann Secondary School"},
    {value: 'North Vista Secondary School', label: "North Vista Secondary School"},
    {value: 'Northbrooks Secondary School', label: "Northbrooks Secondary School"},
    {value: 'Northland Secondary School', label: "Northland Secondary School"},
    {value: 'NUS High School of Mathematics and Science', label: "NUS High School of Mathematics and Science"},
    {value: 'Orchid Park Secondary School', label: "Orchid Park Secondary School"},
    {value: 'utram Secondary School', label: "utram Secondary School"},
    {value: 'Pasir Ris Crest Secondary School', label: "Pasir Ris Crest Secondary School"},
    {value: 'Pasir Ris Secondary School', label: "Pasir Ris Secondary School"},
    {value: "Paya Lebar Methodist Girls' School (Secondary)", label: "Paya Lebar Methodist Girls' School (Secondary)"},
    {value: 'Pei Hwa Secondary School', label: "Pei Hwa Secondary School"},
    {value: 'Peicai Secondary School', label: "Peicai Secondary School"},
    {value: 'Peirce Secondary School', label: "Peirce Secondary School"},
    {value: 'Ping Yi Secondary School', label: "Ping Yi Secondary School"},
    {value: 'Presbyterian High School', label: "Presbyterian High School"},
    {value: 'Punggol Secondary School', label: "Punggol Secondary School"},
    {value: 'Queenstown Secondary School', label: "Queenstown Secondary School"},
    {value: 'Queensway Secondary School', label: "Queensway Secondary School"},
    {value: "Raffles Girls' School (Secondary)", label: "Raffles Girls' School (Secondary)"},
    {value: 'Raffles Institution', label: "Raffles Institution"},
    {value: 'Regent Secondary School', label: "Regent Secondary School"},
    {value: 'Riverside Secondary School', label: "Riverside Secondary School"},
    {value: 'River Valley High School', label: "River Valley High School"},
    {value: "St. Andrew's Secondary School", label: "St. Andrew's Secondary School"},
    {value: "St. Patrick's School", label: "St. Patrick's School"},
    {value: 'School of Science and Technology, Singapore', label: "School of Science and Technology, Singapore"},
    {value: 'School of the Arts, Singapore', label: "School of the Arts, Singapore"},
    {value: 'Sembawang Secondary School', label: "Sembawang Secondary School"},
    {value: 'Seng Kang Secondary School', label: "Seng Kang Secondary School"},
    {value: 'Serangoon Garden Secondary School', label: "Serangoon Garden Secondary School"},
    {value: 'Serangoon Secondary School', label: "Serangoon Secondary School"},
    {value: 'Shuqun Secondary School', label: "Shuqun Secondary School"},
    {value: "Singapore Chinese Girls' School", label: "Singapore Chinese Girls' School"},
    {value: 'Singapore Sports School', label: "Singapore Sports School"},
    {value: 'Springfield Secondary School', label: "Springfield Secondary School"},
    {value: "St. Anthony's Canossian Secondary Schoo", label: "St. Anthony's Canossian Secondary School"},
    {value: "St. Gabriel's Secondary Schoo", label: "St. Gabriel's Secondary School"},
    {value: "St. Hilda's Secondary School", label: "St. Hilda's Secondary School"},
    {value: "St. Margaret's Secondary School", label: "St. Margaret's Secondary School"},
    {value: "St. Joseph's Institution", label: "St. Joseph's Institution"},
    {value: 'Swiss Cottage Secondary School', label: "Swiss Cottage Secondary School"},
    {value: 'Tanglin Secondary School', label: "Tanglin Secondary School"},
    {value: 'Tampines Secondary School', label: "Tampines Secondary School"},
    {value: "Tanjong Katong Girls' School", label: "Tanjong Katong Girls' School"},
    {value: 'Tanjong Katong Secondary School', label: "Tanjong Katong Secondary School"},
    {value: 'Teck Whye Secondary School', label: "Teck Whye Secondary School"},
    {value: 'Temasek Junior College', label: "Temasek Junior College"},
    {value: 'Temasek Secondary School', label: "Temasek Secondary School"},
    {value: 'Unity Secondary School', label: "Unity Secondary School"},
    {value: 'Victoria School', label: "Victoria School"},
    {value: 'West Spring Secondary School', label: "West Spring Secondary School"},
    {value: 'Westwood Secondary School', label: "Westwood Secondary School"},
    {value: 'Whitley Secondary School', label: "Whitley Secondary School"},
    {value: 'Woodgrove Secondary School', label: "Woodgrove Secondary School"},
    {value: 'Woodlands Ring Secondary School', label: "Woodlands Ring Secondary School"},
    {value: 'Woodlands Secondary School', label: "Woodlands Secondary School"},
    {value: 'Xinmin Secondary School', label: "Xinmin Secondary School"},
    {value: 'Yio Chu Kang Secondary School', label: "Yio Chu Kang Secondary School"},
    {value: 'Yishun Secondary School', label: "Yishun Secondary School"},
    {value: 'Yishun Town Secondary School', label: "Yishun Town Secondary School"},
    {value: 'Yuan Ching Secondary School', label: "Yuan Ching Secondary School"},
    {value: 'Yuhua Secondary School', label: "Yuhua Secondary School"},
    {value: 'Yusof Ishak Secondary School', label: "Yusof Ishak Secondary School"},
    {value: 'Yuying Secondary School', label: "Yuying Secondary School"},
    {value: 'Zhenghua Secondary School', label: "Zhenghua Secondary School"},
    {value: 'Zhonghua Secondary School', label: "Zhonghua Secondary School"}
  ];

  export const Junior_options = [
    {value: 'not_in_list', label: "Not in list"},
    {value: 'Anderson Serangoon JC', label: "Anderson Serangoon JC"},
    {value: 'Anglo-Chinese JC', label: "Anglo-Chinese JC"},
    {value: 'Catholic JC', label: "Catholic JC"},
    {value: 'Dunman High School JC', label: "Dunman High School JC"},
    {value: 'Eunoia JC', label: "Eunoia JC"},
    {value: 'Hwa Chong Institution (JC)', label: "Hwa Chong Institution (JC)"},
    {value: 'Jurong Pioneer JC', label: "Jurong Pioneer JC"},
    {value: 'Millennia Institute', label: "Millennia Institute"},
    {value: 'NUS High School of Mathematics and Science', label: "NUS High School of Mathematics and Science"},
    {value: 'Nanyang JC', label: "Nanyang JC"},
    {value: 'National JC', label: "National JC"},
    {value: 'Raffles Institution (JC)', label: "Raffles Institution (JC)"},
    {value: 'River Valley High School (JC)', label: "River Valley High School (JC)"},
    {value: 'SOTA', label: "SOTA"},
    {value: 'Singapore Sports School', label: "Singapore Sports School"},
    {value: 'SAJC', label: "SAJC"},
    {value: 'SJI (JC)', label: "SJI (JC)"},
    {value: 'Tampines Meridian JC', label: "Tampines Meridian JC"},
    {value: 'Temasek JC', label: "Temasek JC"},
    {value: 'Victoria JC', label: "Victoria JC"},
    {value: 'Yishun Innova JC', label: "Yishun Innova JC"},
  ];

  export const Primary_options = [
    {value: 'not_in_list', label: "Not in list"},
    {value: 'Admiralty Primary School', label: "Admiralty Primary School"},
    {value: 'Ahmad Ibrahim Primary School', label: "Ahmad Ibrahim Primary School"},
    {value: 'Ai Tong School', label: "Ai Tong School"},
    {value: 'Alexandra Primary School', label: "Alexandra Primary School"},
    {value: 'Anchor Green Primary School', label: "Anchor Green Primary School"},
    {value: 'Anderson Primary School', label: "Anderson Primary School"},
    {value: 'Anglo-Chinese School (Junior)', label: "Anglo-Chinese School (Junior)"},
    {value: 'Anglo-Chinese School (Primary)', label: "Anglo-Chinese School (Primary)"},
    {value: 'Angsana Primary School', label: "Angsana Primary School"},
    {value: 'Ang Mo Kio Primary School', label: "Ang Mo Kio Primary School"},
    {value: 'Balestier Hill Primary School', label: "Balestier Hill Primary School"},
    {value: 'Beacon Primary School', label: "Beacon Primary School"},
    {value: 'Bedok Green Primary School', label: "Bedok Green Primary School"},
    {value: 'Bendemeer Primary School', label: "Bendemeer Primary School"},
    {value: 'Blangah Rise Primary School', label: "Blangah Rise Primary School"},
    {value: 'Boon Lay Garden Primary School', label: "Boon Lay Garden Primary School"},
    {value: 'Bukit Panjang Primary School', label: "Bukit Panjang Primary School"},
    {value: 'Bukit Timah Primary School', label: "Bukit Timah Primary School"},
    {value: 'Bukit View Primary School', label: "Bukit View Primary School"},
    {value: 'Canberra Primary School', label: "Canberra Primary School"},
    {value: 'Canossa Catholic Primary School', label: "Canossa Catholic Primary School"},
    {value: 'Cantonment Primary School', label: "Cantonment Primary School"},
    {value: 'Casuarina Primary School', label: "Casuarina Primary School"},
    {value: 'Catholic High School (Primary)', label: "Catholic High School (Primary)"},
    {value: 'Cedar Primary School', label: "Cedar Primary School"},
    {value: 'Changkat Primary Schoolnot_in_list', label: "Changkat Primary School"},
    {value: 'CHIJ (Katong) Primary', label: "CHIJ (Katong) Primary"},
    {value: 'CHIJ (Kellock)', label: "CHIJ (Kellock)"},
    {value: 'CHIJ Our Lady of Good Counsel', label: "CHIJ Our Lady of Good Counsel"},
    {value: 'CHIJ Our Lady of the Nativity', label: "CHIJ Our Lady of the Nativity"},
    {value: 'CHIJ Our Lady Queen of Peace', label: "CHIJ Our Lady Queen of Peace"},
    {value: 'CHIJ Primary (Toa Payoh)', label: "CHIJ Primary (Toa Payoh)"},
    {value: "CHIJ St. Nicholas Girls' School", label: "CHIJ St. Nicholas Girls' School"},
    {value: 'Chongfu School', label: "Chongfu School"},
    {value: 'Chongzheng Primary School', label: "Chongzheng Primary School"},
    {value: 'Chua Chu Kang Primary School', label: "Chua Chu Kang Primary School"},
    {value: 'Clementi Primary School', label: "Clementi Primary School"},
    {value: 'Compassvale Primary School', label: "Compassvale Primary School"},
    {value: 'Concord Primary School', label: "Concord Primary School"},
    {value: 'Coral Primary School', label: "Coral Primary School"},
    {value: 'Corporation Primary School', label: "Corporation Primary School"},
    {value: 'Da Qiao Primary School', label: "Da Qiao Primary School"},
    {value: 'Damai Primary Schoolnot_in_list', label: "Damai Primary School"},
    {value: 'Dazhong Primary School', label: "Dazhong Primary School"},
    {value: 'De La Salle School', label: "De La Salle School"},
    {value: 'East Coast Primary School', label: "East Coast Primary School"},
    {value: 'East Spring Primary Schoolnot_in_list', label: "East Spring Primary School"},
    {value: '"East View Primary School', label: "East View Primary School"},
    {value: 'Edgefield Primary School', label: "Edgefield Primary School"},
    {value: 'Elias Park Primary School', label: "Elias Park Primary School"},
    {value: 'Endeavour Primary School', label: "Endeavour Primary School"},
    {value: 'Eunos Primary School', label: "Eunos Primary School"},
    {value: 'Evergreen Primary Schoo', label: "Evergreen Primary School"},
    {value: 'Fairfield Methodist School (Primary)not_in_list', label: "Fairfield Methodist School (Primary)"},
    {value: 'Farrer Park Primary School', label: "Farrer Park Primary School"},
    {value: 'Fengshan Primary Schoolnot_in_list', label: "Fengshan Primary School"},
    {value: 'Fernvale Primary Schoolnot_in_list', label: "Fernvale Primary School"},
    {value: 'Fernvale Primary School', label: "Fernvale Primary School"},
    {value: 'First Toa Payoh Primary School', label: "First Toa Payoh Primary School"},
    {value: 'Frontier Primary School', label: "Frontier Primary School"},
    {value: 'Fuchun Primary School', label: "Fuchun Primary School"},
    {value: 'Fuhua Primary School', label: "Fuhua Primary School"},
    {value: 'Gan Eng Seng Primary School', label: "Gan Eng Seng Primary School"},
    {value: 'Geylang Methodist School (Primary)', label: "Geylang Methodist School (Primary)"},
    {value: 'Gongshang Primary School', label: "Gongshang Primary School"},
    {value: 'Greendale Primary School', label: "Greendale Primary School"},
    {value: 'Greenridge Primary School', label: "Greenridge Primary School"},
    {value: 'Greenwood Primary School', label: "Greenwood Primary School"},
    {value: 'Guangyang Primary School', label: "Guangyang Primary School"},
    {value: "Haig Girls' School", label: "Haig Girls' School"},
    {value: "Holy Innocents' Primary School", label: "Holy Innocents' Primary School"},
    {value: 'Henry Park Primary School', label: "Henry Park Primary School"},
    {value: 'Hong Wen School', label: "Hong Wen School"},
    {value: 'Horizon Primary School', label: "Horizon Primary School"},
    {value: 'Hougang Primary School', label: "Hougang Primary School"},
    {value: 'Huamin Primary School', label: "Huamin Primary School"},
    {value: 'Innova Primary School', label: "Innova Primary School"},
    {value: 'Jiemin Primary School', label: "Jiemin Primary School"},
    {value: 'Jing Shan Primary Schoolnot_in_list', label: "Jing Shan Primary School"},
    {value: 'Junyuan Primary School', label: "Junyuan Primary School"},
    {value: 'Jurong Primary Schoolnot_in_list', label: "Jurong Primary School"},
    {value: 'Jurong West Primary School', label: "Jurong West Primary School"},
    {value: 'Juying Primary School', label: "Juying Primary School"},
    {value: 'Keming Primary School', label: "Keming Primary School"},
    {value: 'Kheng Cheng School', label: "Kheng Cheng School"},
    {value: 'Kong Hwa School', label: "Kong Hwa School"},
    {value: 'Kranji Primary School', label: "Kranji Primary School"},
    {value: 'Kuo Chuan Presbyterian Primary School', label: "Kuo Chuan Presbyterian Primary School"},
    {value: 'Lakeside Primary School', label: "Lakeside Primary School"},
    {value: 'Lianhua Primary School', label: "Lianhua Primary School"},
    {value: 'Loyang Primary School', label: "Loyang Primary School"},
    {value: 'MacPherson Primary School', label: "MacPherson Primary School"},
    {value: 'Maha Bodhi School', label: "Maha Bodhi School"},
    {value: 'Maris Stella High School', label: "Maris Stella High School"},
    {value: 'Marsiling Primary School', label: "Marsiling Primary School"},
    {value: 'Marymount Convent Schoolnot_in_list', label: "Marymount Convent School"},
    {value: 'Mayflower Primary School', label: "Mayflower Primary School"},
    {value: 'Mee Toh School', label: "Mee Toh School"},
    {value: 'Meridian Primary School', label: "Methodist Girls' School (Primary)"},
    {value: 'Montfort Junior School', label: "Montfort Junior School"},
    {value: 'Nan Chiau Primary School', label: "Nan Chiau Primary School"},
    {value: 'Nan Hua Primary School', label: "Nan Hua Primary School"},
    {value: 'Nanyang Primary School', label: "Nanyang Primary School"},
    {value: 'Ngee Ann Primary School', label: "Ngee Ann Primary School"},
    {value: 'Naval Base Primary School', label: "Naval Base Primary School"},
    {value: 'New Town Primary School', label: "New Town Primary School"},
    {value: 'Northland Primary School', label: "Northland Primary School"},
    {value: 'Northoaks Primary School', label: "Northoaks Primary School"},
    {value: 'North Spring Primary School', label: "North Spring Primary School"},
    {value: 'North View Primary School', label: "North View Primary School"},
    {value: 'North Vista Primary School', label: "North Vista Primary School"},
    {value: 'Oasis Primary School', label: "Oasis Primary School"},
    {value: 'Opera Estate Primary School', label: "Opera Estate Primary School"},
    {value: 'Palm View Primary School', label: "Palm View Primary School"},
    {value: 'Park View Primary School', label: "Park View Primary School"},
    {value: 'Pasir Ris Primary School', label: "Pasir Ris Primary School"},
    {value: 'Paya Lebar Methodist Girls School (Primary)', label: "Paya Lebar Methodist Girls' School (Primary)"},
    {value: 'Pei Chun Public School', label: "Pei Chun Public School"},
    {value: 'Pei Hwa Presbyterian Primary School', label: "Pei Hwa Presbyterian Primary School"},
    {value: 'Pei Tong Primary School', label: "Pei Tong Primary School"},
    {value: 'Peiying Primary School', label: "Peiying Primary School"},
    {value: 'Pioneer Primary School', label: "Pioneer Primary School"},
    {value: 'Poi Ching School', label: "Poi Ching School"},
    {value: 'Princess Elizabeth Primary School', label: "Princess Elizabeth Primary School"},
    {value: 'Punggol Cove Primary School', label: "Punggol Cove Primary School"},
    {value: 'Punggol Green Primary School', label: "Punggol Green Primary School"},
    {value: 'Punggol Primary School', label: "Punggol Primary School"},
    {value: 'Punggol View Primary School', label: "Punggol View Primary School"},
    {value: 'Qifa Primary School', label: "Qifa Primary School"},
    {value: 'Qihua Primary School', label: "Qihua Primary School"},
    {value: 'Queenstown Primary School', label: "Queenstown Primary School"},
    {value: 'Radin Mas Primary School', label: "Radin Mas Primary School"},
    {value: "Raffles Girls' Primary School", label: "Raffles Girls' Primary School"},
    {value: 'Red Swastika School', label: "Red Swastika School"},
    {value: 'Riverside Primary School', label: "Riverside Primary School"},
    {value: 'River Valley Primary School', label: "River Valley Primary School"},
    {value: 'Rivervale Primary School', label: "Rivervale Primary School"},
    {value: 'Rosyth School', label: "Rosyth School"},
    {value: 'Rulang Primary School', label: "Rulang Primary School"},
    {value: 'Sembawang Primary School', label: "Sembawang Primary School"},
    {value: 'Sengkang Green Primary School', label: "Sengkang Green Primary School"},
    {value: 'Seng Kang Primary School', label: "Seng Kang Primary School"},
    {value: 'Shuqun Primary School', label: "Shuqun Primary School"},
    {value: 'Si Ling Primary School', label: "Si Ling Primary School"},
    {value: 'Singapore Chinese Girls’ School (Primary)', label: "Singapore Chinese Girls’ School (Primary)"},
    {value: 'South View Primary School', label: "South View Primary School"},
    {value: 'Springdale Primary School', label: "Springdale Primary School"},
    {value: 'Stamford Primary School', label: "Stamford Primary School"},
    {value: "St. Andrew's Junior School", label: "St. Andrew's Junior School"},
    {value: "St. Anthony's Canossian Primary School", label: "St. Anthony's Canossian Primary School"},
    {value: "St. Anthony's Primary School", label: "St. Anthony's Primary School"},
    {value: "St. Gabriel's Primary School", label: "St. Gabriel's Primary School"},
    {value: "St. Hilda's Primary School", label: "St. Hilda's Primary School"},
    {value: "St. Joseph's Institution Junior", label: "St. Joseph's Institution Junior"},
    {value: "St. Margaret's Primary School", label: "St. Margaret's Primary School"},
    {value: "St. Stephen's School", label: "St. Stephen's School"},
    {value: 'Tampines North Primary School', label: "Tampines North Primary School"},
    {value: 'Tampines Primary School', label: "Tampines Primary School"},
    {value: 'Tanjong Katong Primary School', label: "Tanjong Katong Primary School"},
    {value: 'Tao Nan School', label: "Tao Nan School"},
    {value: 'Teck Ghee Primary School', label: "Teck Ghee Primary School"},
    {value: 'Teck Whye Primary School', label: "Teck Whye Primary School"},
    {value: 'Telok Kurau Primary School', label: "Telok Kurau Primary School"},
    {value: 'Temasek Primary School', label: "Temasek Primary School"},
    {value: 'Townsville Primary School', label: "Townsville Primary School"},
    {value: 'Unity Primary School', label: "Unity Primary School"},
    {value: 'Valour Primary School', label: "Valour Primary School"},
    {value: 'Waterway Primary School', label: "Waterway Primary School"},
    {value: 'Wellington Primary School', label: "Wellington Primary School"},
    {value: 'West Grove Primary School', label: "West Grove Primary School"},
    {value: 'West Spring Primary School', label: "West Spring Primary School"},
    {value: 'Westwood Primary School', label: "Westwood Primary School"},
    {value: 'West View Primary School', label: "West View Primary School"},
    {value: 'White Sands Primary School', label: "White Sands Primary School"},
    {value: 'Woodgrove Primary School', label: "Woodgrove Primary School"},
    {value: 'Woodlands Primary School', label: "Woodlands Primary School"},
    {value: 'Woodlands Ring Primary School', label: "Woodlands Ring Primary School"},
    {value: 'Xinghua Primary School', label: "Xinghua Primary School"},
    {value: 'Xingnan Primary School', label: "Xingnan Primary School"},
    {value: 'Xinmin Primary School', label: "Xinmin Primary School"},
    {value: 'Xishan Primary School', label: "Xishan Primary School"},
    {value: 'Yangzheng Primary School', label: "Yangzheng Primary School"},
    {value: 'Yew Tee Primary School', label: "Yew Tee Primary School"},
    {value: 'Yio Chu Kang Primary School', label: "Yio Chu Kang Primary School"},
    {value: 'Yishun Primary School', label: "Yishun Primary School"},
    {value: 'Yu Neng Primary School', label: "Yu Neng Primary School"},
    {value: 'Yuhua Primary School', label: "Yuhua Primary School"},
    {value: 'Yumin Primary School', label: "Yumin Primary School"},
    {value: 'Zhangde Primary School', label: "Zhangde Primary School"},
    {value: 'Zhenghua Primary School', label: "Zhenghua Primary School"},
    {value: 'Zhonghua Primary School', label: "Zhonghua Primary School"}
  ];

  export const University_options = [
    {value: 'not_in_list', label: "Not in list"},
    {value: 'National University Of Singapore (NUS)', label: "National University Of Singapore (NUS)"},
    {value: 'Nanyang Technological University (NTU)', label: "Nanyang Technological University (NTU)"},
    {value: 'Singapore Management University (SMU)', label: "Singapore Management University (SMU)"},
    {value: 'Singapore University of Social Science (SUSS)', label: "Singapore University of Social Science (SUSS)"},
    {value: 'Singapore University of Technology and Design (SUTD)', label: "Singapore University of Technology and Design (SUTD)"},
    {value: 'Laselle College of the Arts', label: "Laselle College of the Arts"},
    {value: 'Nanyang Academy of Fine Arts', label: "Nanyang Academy of Fine Arts"},
    {value: 'Singapore Institute of Technology (SIT)', label: "Singapore Institute of Technology (SIT)"}
  ]
  