import React, { Component } from 'react';
import Table from '../components/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getRequestsData } from '../actions/admin';
import _ from 'underscore';
import loading from '../img/loading.gif';

class BookingReq extends Component {
  constructor(props) {
    super(props);
    this.state = {
     flag: true,
     reqData:[]
    };
    this.type = props.match.params.type;
  }

  componentDidMount() {
    let context = this;
    let params = {
      token: context.props.user.loginToken,
      type: 2
    };
   context.props.getRequestsData(params, res => {
    if(res){
        this.setState({flag:true});
         }
       });
  }

  render() {
    const columns =[
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Phone Number',
        accessor: 'phone'
      },
      {
        Header: 'Pessangers',
        accessor: 'passengers'
      },    
      {
        Header: 'Car Type',
        accessor: 'cartype'
      },
      {
        Header: 'Date',
        accessor: 'date'
      },
      {
        Header: 'Time',
        accessor: 'time'
      }
    ];

    let data = this.props.user.requestData;
      return (
        <section className="main-content frontend" id="home">
        <section className="content">
        <div className="main-container">
        <div className="container-fluid">
  
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800"> Booking Requests </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 table-custom">
          { this.state.flag ? <Table data={ data } columns={ columns } tableType={ 'webrequests' } fileName={'WebRequests'}/> : (
                    <img src={loading} className="loading" />
                  ) }
          </div>
      </div>
       </div>
    </div>
  </section>
      </section>
  
      );
    }
}

const mapStateToProps = state => ({ user: state.user,  booking : state.booking });
const mapDispatchToProps = dispatch => ({
    getRequestsData: bindActionCreators(getRequestsData, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(BookingReq);
