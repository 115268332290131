/*
 * @file: index.js
 * @description: It Contain Routes for application.
 * @date: 20.11.2018
 * @author: Megha Sethi
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './AppRoute';
import { Auth } from '../auth';
import NotFound from '../components/NotFound';
import { frontLayout, dashboardLayout } from '../components/Layouts';
import Login from '../containers/Login';
import Dashboard from '../containers/Dashboard';
import { login } from '../actions/admin';
import ForgotPassword from '../containers/forgetPassword';
import OtpVerification from '../containers/otpVerification';
import ResetPassword from '../containers/resetPassword';
import Drivers from '../containers/driversTable';
import Riders from '../containers/ridersTable';
import Docdetails from '../containers/docDetails';
import Booking from '../containers/ridesTable';

import Subjects from '../containers/subjects';
import AddSubject from '../components/addSubject';
import Topics from '../containers/topics';
import AddTopic from '../components/addTopic';
import Questions from '../containers/questions';
import AddQuestion from '../components/addQuestion';
import Levels from '../containers/levels';
import AddLevel from '../components/addLevel';
import editUser from '../components/editUser';

import Abuse from '../containers/abuseTable';
import FairMange from '../containers/fairManage';
import Payment from '../containers/payment';
import AddDrivers from '../components/addDriver';
import Reviews from '../containers/reviews';
import RiderReviews from '../containers/ridersReviews';
import VehicleTpes from '../containers/vehicleTypes';
import Pages from '../containers/pages/index';
import AddPage from '../containers/pages/addPage';
import BlockDrivers from '../containers/blockUsers';
import Scheduled from '../containers/scheduled';
import Sos from '../containers/sos';
import RequestsToAdmin from '../containers/reqtoadmin';
import BookingReq from '../containers/bookingRequets';
import ServingArea from '../containers/servingArea';
//import configureClient from './SocketClient';

const Routers = store => {
  const state = store.getState();
  /*********** Socket Initialization in login ***********/
  if (state.user.loggedIn) {
    //const socketClient = configureClient(store);
  }
  return (
    <div>
      <Switch>
        <AppRoute
          exact={true}
          path="/"
          component={Login}
          requireAuth={Auth}
          layout={frontLayout}
          store={store}
          type="public"
        />

        <AppRoute
          exact={true}
          path="/dashboard"
          component={ state.user.loggedIn  ? Dashboard : login }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />

      <AppRoute
          exact={true}
          path="/sosnumber"
          component={ Sos}
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />

      <AppRoute
          exact={true}
          path="/servicingarea"
          component={ ServingArea }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />

      <AppRoute
          exact={true}
          path="/contactus"
          component={ RequestsToAdmin}
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />

      <AppRoute
          exact={true}
          path="/bookingrequests"
          component={ BookingReq }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />
        <AppRoute
          exact={true}
          path="/subjects"
          component={ Subjects }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />
        <AppRoute
          exact={true}
          path="/subjects/addsubject"
          component={ AddSubject }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />
        <AppRoute
          exact={true}
          path="/subjects/edit-subject/:subjectId"
          component={ AddSubject }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />
        <AppRoute
          exact={true}
          path="/topics"
          component={ Topics }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />
        <AppRoute
          exact={false}
          path="/topics/addtopic"
          component={ AddTopic }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />
        <AppRoute
          exact={true}
          path="/topics/edit-topic/:topicId"
          component={ AddTopic }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />
        <AppRoute
          exact={true}
          path="/questions"
          component={ Questions }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />
        <AppRoute
          exact={true}
          path="/questions/add-question"
          component={ AddQuestion }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />
        <AppRoute
          exact={true}
          path="/questions/edit-question/:questionId"
          component={ AddQuestion }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />
        <AppRoute
          exact={true}
          path="/levels"
          component={ Levels }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />
        <AppRoute
          exact={true}
          path="/levels/add-level"
          component={ AddLevel }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />
        <AppRoute
          exact={true}
          path="/levels/edit-level/:levelID"
          component={ AddLevel }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />

        {/* <AppRoute
          exact={true}
          path="/rides/:type"
          component={ Booking }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        /> */}

         <AppRoute
          exact={true}
          path="/scheduled/rides"
          component={ Scheduled }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />


        <AppRoute
          exact={true}
          path="/all-users"
          component={ Drivers }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />

        <AppRoute
          exact={true}
          path="/users/edit-user/:userId"
          component={ editUser }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />

        <AppRoute
          exact={true}
          path="/drivers/adduser"
          component={ AddDrivers }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />

        <AppRoute
          exact={true}
          path="/parents"
          component={ Riders }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />

      <AppRoute
          exact={true}
          path="/blockedusers"
          component={ BlockDrivers }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />


        <AppRoute
          exact={true}
          path="/abuse/rides"
          component={ Abuse }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />


        <AppRoute
          exact={true}
          path="/fairmanage"
          component={ FairMange }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />

        <AppRoute
          exact={true}
          path="/globalfair"
          component={ FairMange }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />


        <AppRoute
          exact={true}
          path="/payment"
          component={ Payment }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />

        <AppRoute
          exact={true}
          path="/driver/reviews"
          component={ Reviews }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />


        <AppRoute
          exact={true}
          path="/rider/reviews"
          component={ RiderReviews }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />

        <AppRoute
          exact={true}
          path="/vehicletypes"
          component={ VehicleTpes }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />

        {/* Start Pages Routes */}
        <AppRoute
          exact={true}
          path="/pages"
          component={ Pages }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />

        <AppRoute
          exact={true}
          path="/addpage"
          component={ AddPage }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />
        {/* End Pages Routes */}

       <AppRoute
          exact={true}
          path="/documents/:userId/:type"
          component={ Docdetails }
          requireAuth={Auth}
          layout={dashboardLayout}
          store={store}
          type="private"
        />

        <AppRoute
          exact={true}
          path="/forgetpassword"
          component={ ForgotPassword }
          requireAuth={Auth}
          layout={frontLayout}
          store={store}
          type="public"
        />

        <AppRoute
          exact={true}
          path="/otpverification"
          component={ OtpVerification }
          requireAuth={Auth}
          layout={frontLayout}
          store={store}
          type="public"
        />


        <AppRoute
          exact={true}
          path="/resetpassword"
          component={ ResetPassword }
          requireAuth={Auth}
          layout={frontLayout}
          store={store}
          type="public"
        />

        <AppRoute
          exact={true}
          path="*"
          component={NotFound}
          requireAuth={Auth}
          layout={frontLayout}
          store={store}
          type="public"
        />


      </Switch>
    </div>
  );
};

export default Routers;
