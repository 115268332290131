import React, { Component } from 'react';
import Table from '../components/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { carStatus, getCarsData, getCardata } from '../actions/admin';
import _ from 'underscore';
import * as toast from '../actions/toast-actions';
import loading from '../img/loading.gif';

class VehicleTpes extends Component {
  constructor(props) {
    super(props);
    this.state = {
     flag: false
    };
  }

  componentDidMount() {
    let context = this;
    let params = {
      token: context.props.user.loginToken
    };
    context.props.getCarsData(params, res => {this.setState({flag:true});});
  }

  changeStatus = (name, sta) => {
    if (sta !== 'Select') {
      sta = sta == 'true' ? true : false;
      let data = { type: name, token:this.props.user.loginToken, status: sta};
      this.props.carStatus(data, res => {
        if (res.status) {
          toast.toastAction(res.status, 'Car status has been changed successfully');
          let updateStatus = _.findWhere( this.props.user.carData, { name: name });
          
          updateStatus = {...updateStatus, activeStatus: sta};
          let index = this.props.user.carData.findIndex(item => item.name === name);
          this.props.user.carData.splice(index, 1, updateStatus);
          this.props.getCardata(this.props.user.carData);
        } else {
          toast.toastAction(res.status, res.message);
        }
      });
    }
  };

  render() {
    const columns =[
        {
            Header: 'Type',
            accessor: 'name'
          },
      {
        Header: 'Price Per Mile',
        accessor: 'perMileFairAmount'
      },
      {
        Header: 'Base Fair',
        accessor: 'baseFair'
      },
      {
        Header: 'Commission(%)',
        accessor: 'commission'
      },
      {
        Header: 'Passenger Capacity',
        accessor: 'passengerCapacity'
      },
      {
        Header: 'Status',
        accessor: 'activeStatus',
         Cell: props => (  
        <i className={props.original.activeStatus  ? 'fa fa-check custom-check' : 'fa fa-clock-o' } aria-hidden="true" ></i>
        )
      },
      {
        Header: 'Action',
        accessor: 'action',
        sortable: false,
        filterable: false,
        Cell: props => (
          <select
          id="chan"
          onClick={e => {
            this.changeStatus(props.original.name, e.target.value);
          }}
        >
          <option value="Select">Select Action</option>
          <option value='true'>Active</option>
          <option value='false'>Deactive</option>
        </select>
        )
      }
    ];
    
    let data = this.props.user.carData;
    return (
      <section className="main-content frontend" id="home">
      <section className="content">
      <div className="main-container">
      <div className="container-fluid">
      
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800"> Vehicle Listing</h1>
      </div>
      <div className="row">
      <div class="col-md-12 col-sm-12 driver-class1">
        <button className='add-driver1' onClick={()=>{this.props.history.push('/fairmanage');}}>Add Fair</button>
        </div>
        <div className="col-xl-12 col-lg-12 table-custom">
        { this.state.flag ? <Table data={ data } columns={ columns } tableType={ 'cars' } fileName={'Vehicle Listing'}/> : (
                  <img src={loading} className="loading" />
                ) }
        </div>
    </div>
     </div>
  </div>
</section>
    </section>

    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  carStatus: bindActionCreators(carStatus, dispatch),
  getCarsData: bindActionCreators(getCarsData, dispatch),
  getCardata: bindActionCreators(getCardata, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(VehicleTpes);
