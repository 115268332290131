/*
 * @file: index.js
 * @description: It Contain User Account Related Action Creators.
 * @date: 20.11.2018
 * @author: Megha Sethi
 */

import RestClient from '../../utilities/RestClient';
import message from '../../constants/messages';
import { API_URL } from '../../constants/connection';
import * as TYPE from '../../constants/actionTypes/user';

//Action Creator For Reducers

export const login_Success = data => ({ type: TYPE.LOGIN_SUCCESS, data: data });
export const log_out = () => ({ type: TYPE.LOG_OUT });
export const getDriverdata = data => ({ type: TYPE.DRIER_DATA, data: data });
export const getSubjectData = data => ({ type: TYPE.SUBJECTS_DATA, data: data });/*  */
export const getLeveltData = data => ({ type: TYPE.LEVELS_DATA, data: data });/*  */
export const getTopicData = data => ({ type: TYPE.TOPICS_DATA, data: data });
export const getQuestionData = data => ({ type: TYPE.QUESTIONS_DATA, data: data });
export const forgetToken = data => ({ type: TYPE.FORGET_TOKEN, data: data });
export const getCardata = data => ({ type: TYPE.CAR_DATA, data: data });
export const getRatingdata = data => ({ type: TYPE.RATING_DATA, data: data });
export const getPagedata = data => ({ type: TYPE.PAGE_DATA, data: data });
export const getPaymentdata = data => ({ type: TYPE.PAYMENT_DATA, data: data });
export const getRequestdata = data => ({ type: TYPE.REQUEST_DATA, data: data });

// Thunk Action Creators For Api

/****** action creator for login ********/
export const login = (params, cb) => {
  return dispatch => {
    RestClient.post(`${API_URL}/admin/login`, params)
      .then(result => {
        if (result.statusCode === 200) {
          dispatch(login_Success(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/******** action creator to log user out of the application **********/
export const logOut = (params, cb) => {
  return dispatch => {
    RestClient.post(`${API_URL}/admin/logout`, params)
      .then(result => {
        if (result) {
          dispatch(log_out());
          let res = {
            status: true,
            message: message.logout,
            type: message.logout
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action geting driver data ********/
export const getDriverData = (params, cb) => {
  //let query = { userId: params.userId },
  return dispatch => {
    RestClient.get(
      `${API_URL}/admin/all-users`,
      {},
      params.token
    )
      .then(result => {
        if (result.statusCode === 200) {
          dispatch(getDriverdata(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action geting subjects data ********/
export const getSubjects = (params, cb) => {
  //let query = { userId: params.userId },
  return dispatch => {
    RestClient.get(
      `${API_URL}/admin/all-subjects`,
      {},
      params.token
    )
      .then(result => {
        if (result.statusCode === 200) {
          dispatch(getSubjectData(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        // cb(res);
      });
  };
};
/****** action geting subjects data ********/
export const getLevels = (params, cb) => {
  //let query = { userId: params.userId },
  return dispatch => {
    RestClient.get(
      `${API_URL}/admin/all-levels`,
      {},
      params.token
    )
      .then(result => {
        if (result.statusCode === 200) {
          dispatch(getLeveltData(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};
/****** action geting subjects data ********/
export const getLevel = (params, cb) => {
  //let query = { userId: params.userId },
  return dispatch => {
    let token = params.token;
    delete params.token;
    RestClient.post(`${API_URL}/admin/get-level`, params, token)
      .then(result => {
        if (result.statusCode === 200) {
          //dispatch(login_Success(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: error.message,
          type: error.type
        };
      //  cb(res);
      });
  };
};

/****** action geting topics data ********/
export const getTopics = (params, cb) => {
  return dispatch => {
    RestClient.get(
      `${API_URL}/admin/all-topics`,
      {},
      params.token
    )
      .then(result => {
        if (result.statusCode === 200) {
          dispatch(getTopicData(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: error.message,
          type: message.error
        };
      //  cb(res);
      });
  };
};

/****** action creator for forget password ********/
export const forgetPassword = (params, cb) => {
  return dispatch => {
    RestClient.post(`${API_URL}/admin/forgetpassword`, params)
      .then(result => {
        if (result.statusCode === 200) {
          dispatch(forgetToken(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for verified otp ********/
export const verification = (params, cb) => {
  return dispatch => {
    let token = params.token;
    delete params.token;
    RestClient.post(`${API_URL}/user/otpverification`, params,  token)
      .then(result => {
        if (result.statusCode === 200) {
          dispatch(forgetToken(result.data.loginToken));
          let res = {
            status: true,
            message: result.message,
            type: message.success
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for verified otp ********/
export const resetPassword = (params, cb) => {
  return dispatch => {
    let token = params.token;
    delete params.token;
    RestClient.put(`${API_URL}/user/resetpassword`, params, token)
      .then(result => {
        if (result.statusCode === 200) {
          dispatch(forgetToken(''));
          let res = {
            status: true,
            message: result.message,
            type: message.success
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** action creator for get Total count ********/
export const totalCount = (params, cb) => {
  return dispatch => {
    RestClient.get(`${API_URL}/admin/totalcount`, {}, params.token)
      .then(result => {
        if (result.statusCode === 200) {
          //dispatch(login_Success(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};

/****** action creator to change status ********/
export const changeStatus = (params, cb) => {
  return dispatch => {
    let token = params.token;
    delete params.token;
    RestClient.post(`${API_URL}/admin/update-user-status`, params, token)
      .then(result => {
        if (result.statusCode === 200) {
          //dispatch(login_Success(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};

/****** action creator to change status ********/
export const changeLevelStatus = (params, cb) => {
  return dispatch => {
    let token = params.token;
    delete params.token;
    RestClient.post(`${API_URL}/admin/update-level-status`, params, token)
      .then(result => {
        if (result.statusCode === 200) {          
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};

/****** action creator to change status ********/
export const changeSubjectStatus = (params, cb) => {
  return dispatch => {
    let token = params.token;
    delete params.token;
    RestClient.post(`${API_URL}/admin/update-subject-status`, params, token)
      .then(result => {
        if (result.statusCode === 200) {          
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};

/****** action creator to update subject ********/
export const updateSubject = (params, token, cb) => {
  return dispatch => {
    RestClient.post(`${API_URL}/admin/edit-subject`, params, token)
      .then(result => {
        if (result.statusCode === 200) {         
          let res = {
            status: true,
            message: result.message,
            type: message.success
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator to delete subject ********/
export const deleteSubject = (params, token ,cb) => {
  return dispatch => {
    
    RestClient.post(`${API_URL}/admin/delete-subject`, params, token)
      .then(result => {
        if (result.statusCode === 200) {
          //dispatch(login_Success(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: error.message,
          type: error.type
        };
      //  cb(res);
      });
  };
};

/****** action creator to delete level ********/
export const deleteLevel = (params, cb) => {
  return dispatch => {
    let token = params.token;
    delete params.token;
    RestClient.post(`${API_URL}/admin/delete-level`, params, token)
      .then(result => {
        if (result.statusCode === 200) {
          //dispatch(login_Success(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: error.message,
          type: error.type
        };
      //  cb(res);
      });
  };
};

/****** action creator to delete topic ********/
export const deleteTopic = (params, cb) => {
  return dispatch => {
    let token = params.token;
    delete params.token;
    RestClient.post(`${API_URL}/admin/delete-topic`, params, token)
      .then(result => {
        if (result.statusCode === 200) {
          //dispatch(login_Success(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};

/****** action creator to search from db ********/
export const getSearchData = (params, cb) => {
  return dispatch => {
    let token = params.token;
    delete params.token;
    RestClient.get(`${API_URL}/admin/search`, params, token)
      .then(result => {
        if (result.statusCode === 200) {
          //dispatch(login_Success(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};

/****** action creator to add driver ********/
export const addDrivers = (params, token, cb) => {
  return dispatch => {
    RestClient.post(`${API_URL}/admin/add-subject`, params, token)
      .then(result => {
        if (result.statusCode === 200) {
          //dispatch(login_Success(result.data));
          let res = {
            status: true,
            message: result.data.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};

/****** action creator to add level ********/
export const addLevel = (params, token, cb) => {
  return dispatch => {
    RestClient.post(`${API_URL}/admin/add-level`, params, token)
      .then(result => {
        if (result.statusCode === 200) {
          //dispatch(login_Success(result.data));
          let res = {
            status: true,
            message: result.data.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};

/****** action creator to update level ********/
export const updateLevel = (params, token, cb) => {
  return dispatch => {
    RestClient.post(`${API_URL}/admin/edit-level`, params, token)
      .then(result => {
        if (result.statusCode === 200) {         
          let res = {
            status: true,
            message: result.message,
            type: message.success
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** action creator to add topic ********/
export const addTopic = (params, token, cb) => {
  return dispatch => {
    RestClient.post(`${API_URL}/admin/add-topic`, params, token)
      .then(result => {
        if (result.statusCode === 200) {
          //dispatch(login_Success(result.data));
          let res = {
            status: true,
            message: result.data.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};

/****** action creator to update topic ********/
export const updateTopic = (params, token, cb) => {
  return dispatch => {
    RestClient.post(`${API_URL}/admin/edit-topic`, params, token)
      .then(result => {
        if (result.statusCode === 200) {         
          let res = {
            status: true,
            message: result.message,
            type: message.success
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** action creator to add topic ********/
export const addQuestion = (params, token, cb) => {
  return dispatch => {
    RestClient.postFormData(`${API_URL}/admin/add-question`, params, token)
      .then(result => {
        console.log(result);
        if (result.data.statusCode == 200) {
          //dispatch(login_Success(result.data));
          let res = {
            status: true,
            message: result.data.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};
/****** action creator to add topic ********/
export const updateQuestion = (params, token, cb) => {
  return dispatch => {
    RestClient.postFormData(`${API_URL}/admin/edit-question`, params, token)
      .then(result => {
        console.log(result);
        if (result.data.statusCode == 200) {
          //dispatch(login_Success(result.data));
          let res = {
            status: true,
            message: result.data.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};

/****** action creator to update User ********/
export const updateUser = (params, token, cb) => {
  return dispatch => {
    RestClient.post(`${API_URL}/admin/edit-user`, params, token)
      .then(result => {
        console.log(result);
        if (result.statusCode == 200) {
          //dispatch(login_Success(result.data));
          let res = {
            status: true,
            message: result.data.message,
            type: message.success,
            data: result.data.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.data.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};

/****** action geting topics data ********/
export const getQuestions = (params, cb) => {
  return dispatch => {
    RestClient.get(
      `${API_URL}/admin/all-questions`,
      {},
      params.token
    )
      .then(result => {
        if (result.statusCode === 200) {
          dispatch(getQuestionData(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: error.message,
          type: message.error
        };
      //  cb(res);
      });
  };
};

/****** action creator to add base commission ********/
export const setCommission = (params, cb) => {
  return dispatch => {
    RestClient.put(
      `${API_URL}/admin/accountmange`,
      { commission: params.commission, sosNumber:params.sosNumber },
      params.token
    )
      .then(result => {
        if (result.statusCode === 200) {
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** action creator to change cars status ********/
export const carStatus = (params, cb) => {
  return dispatch => {
    RestClient.post(
      `${API_URL}/admin/activecars`,
      { type: params.type, status: params.status },
      params.token
    )
      .then(result => {
        if (result.statusCode === 200) {
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action geting cars data ********/
export const getCarsData = (params, cb) => {
  //let query = { userId: params.userId },
  return dispatch => {
    RestClient.get(
      `${API_URL}/user/getcartypes`,
      {},
      params.token
    )
      .then(result => {
        if (result.statusCode === 200) {
         dispatch(getCardata(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action geting users rating data ********/
export const getUserRating = (params, cb) => {
  return dispatch => {
    RestClient.get(
      `${API_URL}/admin/getrating`,
      {type: params.type},
      params.token
    )
      .then(result => {
        if (result.statusCode === 200) {
         dispatch(getRatingdata(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator to add new page ********/
// export const addPage = (params, token, cb) => {
//   return dispatch => {
//     RestClient.postFormData(
//       `${API_URL}/pages/addpage`,
//        params, token
//     )
//       .then(result => {
//         console.log('========',result);
//         if (result.status === 200) {
//           let res = {
//             status: true,
//             message: result.data.message,
//             type: message.success,
//             data: result.data
//           };
//           cb(res);
//         } else {
//           let res = {
//             status: false,
//             message: result.message,
//             type: message.error
//           };
//           cb(res);
//         }
//       })
//       .catch(error => {
//         let res = {
//           status: false,
//           message: message.commonError,
//           type: message.error
//         };
//         cb(res);
//       });
//   };
// };

/****** action creator to update page ********/
// export const updatePage = (params, token, cb) => {

//   return dispatch => {
//     RestClient.postFormData(
//       `${API_URL}/pages/updatepage`,
//        params, token
//     )
//       .then(result => {
//         console.log('========',result);
//         if (result.status === 200) {
//           let res = {
//             status: true,
//             message: result.data.message,
//             type: message.success,
//             data: result.data
//           };
//           cb(res);
//         } else {
          
//           let res = {
//             status: false,
//             message: result.message,
//             type: message.error
//           };
//           cb(res);
//         }
//       })
//       .catch(error => {
//         let res = {
//           status: false,
//           message: message.commonError,
//           type: message.error
//         };
//         cb(res);
//       });
//   };
// };

/****** action geting pages data ********/
export const getPagesData = (params, cb) => {
  //let query = { userId: params.userId },
  return dispatch => {
    RestClient.get(
      `${API_URL}/admin/getpages`,
      {},
      params.token
    )
      .then(result => {
        if (result.statusCode === 200) {
          dispatch(getPagedata(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getPaymentData = (params, cb) => {
  return dispatch => {
    RestClient.get(
    `${API_URL}/admin/paymentList`,
    {},
    params.token
    )
    .then(result => {
      if (result.statusCode === 200) {
        dispatch(getPaymentdata(result.data));
        let res = {
        status: true,
        message: result.message,
        type: message.success,
        data: result.data
        };
        cb(res);
      } else {
        let res = {
        status: false,
        message: result.message,
        type: message.error
        };
        cb(res);
      }
    })
    .catch(error => {
      let res = {
      status: false,
      message: message.commonError,
      type: message.error
      };
      cb(res);
    });
  };
};
  export const getRequestsData = (params, cb) => {
    return dispatch => {
      RestClient.get(
      `${API_URL}/admin/getallrequests`,
      {type: params.type},
      params.token
      )
      .then(result => {
        if (result.statusCode === 200) {
        dispatch(getRequestdata(result.data));
        let res = {
        status: true,
        message: result.message,
        type: message.success,
        data: result.data
        };
        cb(res);
      } else {
        let res = {
        status: false,
        message: result.message,
        type: message.error
        };
        cb(res);
      }
      })
      .catch(error => {
        let res = {
        status: false,
        message: message.commonError,
        type: message.error
        };
        cb(res);
      });
    };
  };


  /****** action creator to add driver ********/
// export const updateProfile = (params, token, cb) => {
//   return dispatch => {
//     RestClient.putFormData(`${API_URL}/user/updateprofile`, params, token)
//       .then(result => {
//         if (result.status === 200) {
//           //dispatch(login_Success(result.data));
//           let res = {
//             status: true,
//             message: result.data.message,
//             type: message.success,
//             data: result.data
//           };
//           cb(res);
//         } else {
//           let res = {
//             status: false,
//             message: result.message,
//             type: message.error
//           };

//           cb(res);
//         }
//       })
//       .catch(error => {
//         let res = {
//           status: false,
//           message: message.commonError,
//           type: error.message
//         };
//         cb(res);
//       });
//   };
// };