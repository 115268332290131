import React, { Component } from 'react';
import Table from '../components/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLevels, deleteLevel, changeLevelStatus } from '../actions/admin';
import * as toast from '../actions/toast-actions';
import _ from 'underscore';
import loading from '../img/loading.gif';


class Levels extends Component {
    constructor(props) {
        super(props);
        this.state = {
          flag: false
        };
      }
    
      openModal(props, tab) {
        let data = props.original;
        if (tab) {
          this.props.history.push(`/documents/${data.userId}/docs`);
          //window.open(`/documents/${data.userId}`, '_blank'); //to open new page2
        } else {
          this.props.history.push(`/documents/${data.userId}/vehicle`);
        }
      }
    
      componentDidMount() {
        let context = this;
        let params = {
          token: context.props.user.loginToken
        };
        context.props.getLevels(params, res => { this.setState({ flag: true }); });
      }    

      changeStatus(option,levelId) {
        let context = this;
        let params = {
          token: context.props.user.loginToken,
          id:levelId
        };
        if(option == '0' || option == '1'){
          params.status = option;
          this.props.changeLevelStatus(params,(res)=>{
            if(res.status){
              toast.toastAction(res.status, res.message);   
              let updateStatus = _.findWhere(this.props.user.levelData, { id: levelId });
              updateStatus = { ...updateStatus, status: parseInt(option) };
            }else{
              toast.toastAction(res.status, res.message);
            }
          })
        }else if(option == '3'){
          this.props.history.push('/levels/edit-level/'+btoa(levelId));
        }else if(option == '4'){
         
          this.props.deleteLevel(params,(res)=>{
            if(res.status){
              toast.toastAction(res.status, res.message);
              this.componentDidMount();
            }else{
              toast.toastAction(res.status, res.message);
            }
          })
        }
      }

      test = () => {
        this.props.history.push('/levels/add-level');
      }
    
      render() {
    
        const columns = [
          {
            Header: 'Name',
            accessor: 'name'
          },
          {
            Header: 'Visible to all',
            accessor: 'status',
            Cell: props => (
              <i className={props.original.status == 1 ? 'fa fa-check custom-check' :'fa fa-ban custom-ban'} aria-hidden="true" ></i>
            )
          },
          {
            Header: 'Action',
            accessor: 'action',
            sortable: false,
            filterable: false,
            Cell: props => (
              <select
                id="chan"
                onClick={e => {
                  this.changeStatus(e.target.value, props.original.id);
                }}
              >
                <option value="Select">Select Action</option>
                {props.original.status == 1 ? <option value='0'>Block</option> : <option value='1'>Approve</option>}
                {/* <option value='2'>Edit</option> */}
                <option value='3'>Edit</option>
                <option value='4'>Delete</option>
              </select>
            )
          }
        ];
    
         let levelData = this.props.user.levelData;
    
        return (
          <section className="main-content frontend" id="home">
            <section className="content">
              <div className="main-container">
                <div className="container-fluid">
    
                  <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Levels</h1>
                  </div>
                  <div className="row">
                     <div className="col-md-12 col-sm-12">
                      <button className='add-driver1' onClick={this.test}>Add Level</button>
                    </div> 
                    <div className="col-xl-12 col-lg-12 table-custom">
                      {this.state.flag ? <Table data={levelData} columns={columns} tableType={'levels'} fileName={'levels'} /> : (
                        <img src={loading} className="loading" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
    
        );
      }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
//   changeStatus: bindActionCreators(changeStatus, dispatch),
  getLevels: bindActionCreators(getLevels, dispatch),
  deleteLevel: bindActionCreators(deleteLevel, dispatch),
  changeLevelStatus: bindActionCreators(changeLevelStatus, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Levels);
