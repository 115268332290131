import React, { Component } from 'react';
import Table from '../components/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCommission } from '../actions/admin';
import * as toast from '../actions/toast-actions';
import AlertMsg from '../components/AlertMsg';
import _ from 'underscore';
import loading from '../img/loading.gif';

class FairMange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      msg: '',
      msgType: '',
      msgStatus: false,
      flag: true
    };
  }

  handelCommission = (event) => {
    event.preventDefault();
    this.setState({flag:false})
    let c = document.getElementById('com').value;
    let data={commission: parseInt(c), token: this.props.user.loginToken };
    if(/^\d+$/.test(c)){
       this.props.setCommission(data, res => {
        if (res.status) {
          this.setState({flag:true})
          toast.toastAction(res.status, res.message);
          this.resetFields();
         //this.props.history.replace('/');
        } else {
          this.setState({
            open: true,
            msg: res.message,
            msgType: res.type,
            msgStatus: res.status,
            flag: true
          });
        }
      });
    }
    else{
      this.setState({
        open: true,
        msg: 'This field can not be empty',
        msgType: 'error',
        msgStatus: false,
        flag: true
      });

    }
  };

  handelCarType = (event) => {
    event.preventDefault();
    this.setState({flag:false})
    let message;
    let whiteSpace = /\S/
  //  let formdata: FormData = new FormData();
    let perMile = event.target.per.value;
    let basefare = event.target.base.value;
    let com = event.target.come.value;
    let des =  event.target.des.value;
    let cap =  event.target.cap.value;
    let type =  event.target.carType.value;
    let image =  event.target.veh.files[0];
  
  let formData = new FormData();    //formdata object

  formData.append('image', image);
  formData.append('name', type);
  formData.append('description', des);
  formData.append('passengerCapacity', cap);
  formData.append('perMileFairAmount', parseInt(perMile));
  formData.append('commission', parseInt(com));
  formData.append('baseFair', parseInt(basefare));

    if( type != 'select' && whiteSpace.test(des)){
      let token = this.props.user.loginToken;
       this.props.addCarType(formData, token, res => {
        if (res.status) {
          toast.toastAction(res.status, "Data is successfully updated");
            this.setState({flag:true})
            //this.resetFields();
            setTimeout(()=> {
              this.props.history.push('/vehicletypes')
            }, 3000);
            //this.props.history.replace('/');
            
        } else {
          this.setState({
            open: true,
            msg: res.message,
            msgType: res.type,
            msgStatus: res.status,
            flag: true
          });
        }
      });
    }
    else{
      message = type == 'select' ? 'You have to select a valid car type' : 'Description value in not valid';
      this.setState({
        open: true,
        msg: message,
        msgType: 'error',
        msgStatus: false,
        flag: true
      });

    }
  };

  resetFields = () => {
    if( document.getElementById('com')){
      document.getElementById('com').value = '';
    }else{
      document.getElementById('per').value = '';
      document.getElementById('base').value = '';
      document.getElementById('car-type').value = 'Select';
      document.getElementById('come').value = '';
      document.getElementById('des').value='';
      document.getElementById('cap').value='';
      document.getElementById('veh').value='';
    }
    
  }

  render() {
    let form = this.props.location.pathname;
    if(form != '/globalfair'){
      return (
        <section className="main-content frontend" id="home">
        <section className="content">
        <div className="main-container">
        <div className="container-fluid">
        
        <AlertMsg
        onPress={() => this.setState({ open: false })}
        isShowingModal={this.state.open}
        msg={this.state.msg}
        type={this.state.msgType}
        status={this.state.msgStatus}
        />
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 class="h3 mb-0 text-gray-800">Add Car Fare</h1>
          </div>
        <div className="row">
          <div class="col-md-12 col-sm-12 driver-class1">
          <button className='add-driver1' onClick={()=>{ this.props.history.goBack();}}>Go Back</button>
          </div>
          <div class="mainouter col-xl-12">
      { this.state.flag ? 
        <form onSubmit={this.handelCarType}>
        <div className="col-xl-12 col-lg-12 table-custom driver-table">
          <div class="col-md-6 col-sm-12 add-rider-clm">
            <div class="add-rider-outer">
            
            <label>Amount Per Mile<span className='red'> *</span></label>
            <input type="number" placeholder="Amount Per Mile" id='per' name='per' min='1' required/>
            <label>Base Fare<span className='red'> *</span></label>
            <input type="number" placeholder="Base Fair" id='base' min='1' name='base' required/>
            <label>Commission (%)<span className='red'> *</span></label>
            <input type="number" placeholder="Commission" name='come' min='1' max='100' required/>
            <label>Description<span className='red'> *</span></label>
            <input type="text" placeholder="Description" name='des' required/>
            <label>Passenger Capacity<span className='red'> *</span></label>
            <input type="number" placeholder="Passenger Capacity" name='cap' min='1' max='10' required/>
            <label>Vehicle Image<span className='red'> *</span></label>
          <input id='veh' type="file" name="veh" accept="image/*" required/>
            <div class="add-rider-outer">
              <label>Vehicle type<span className='red'> *</span></label>
                <select name='carType' className='drop'>
                    <option value="select">Select Car Type</option>
                    <option value="Hatchback">Hatchback</option>
                    <option value='Sedan'>Sedan</option>
                </select>
                </div>
            {/* <label>Car Descriptionl<span>*</span></label>
            <input type="text" placeholder="Description"/> */}
            
            {/* <label>Passenger Capacity<span>*</span></label>
            <input type="text" placeholder="Passenger Capacity"/>
            <label>Car Image<span>*</span></label>
            <input type="file" name="pic" accept="image/*"/> */}
            </div>
            </div>
          
            <div class="col-md-12 col-sm-12 rider-btns">
            <button className='add-driver1' type='submit'>Add Fare</button>
            <button className='add-driver1' type='reset'>Reset</button>
            </div>
            </div>
        </form> : (
                  <img src={loading} className="loading" />
                ) }
      </div>
      </div>
       </div>
    </div>
  </section>
      </section>
      );
    }else{
      return (
        <section className="main-content frontend" id="home">
        <section className="content">
        <div className="main-container">
        <div className="container-fluid">
        <AlertMsg
        onPress={() => this.setState({ open: false })}
        isShowingModal={this.state.open}
        msg={this.state.msg}
        type={this.state.msgType}
        status={this.state.msgStatus}
      />
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 class="h3 mb-0 text-gray-800">Add Global Fare</h1>
          </div>
        <div className="row">
          
          <div class="mainouter col-xl-12">
          { this.state.flag ? 
          <form onSubmit={this.handelCommission}>
         <div className="col-xl-12 col-lg-12 table-custom driver-table">
          <div class="col-md-6 col-sm-12 add-rider-clm">
            <div class="add-rider-outer">
             <label>Commission (%)<span className='red'> *</span></label>
             <input type="number" placeholder="Commission" id='com' min='1' max='100'/>
            
             <div class="col-md-12 col-sm-12 rider-btns">
            <button className='add-driver1' type='submit'>Add Fare</button>
            <button className='add-driver1' type='reset'>Reset</button>
            </div>
                </div>
            </div>
            </div>
            </form> : (
                  <img src={loading} className="loading" />
                ) }
           
            
            </div>
      </div>
       </div>
    </div>
  </section>
      </section>
  
      );
    }
      
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  setCommission: bindActionCreators(setCommission, dispatch),
//  addCarType: bindActionCreators(addCarType, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(FairMange);
