import React, { Component } from 'react';
import Table from '../components/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserRating } from '../actions/admin';
import _ from 'underscore';
import loading from '../img/loading.gif';

class RiderReviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: false
    };
  }

  componentDidMount() {
    let context = this;
    let params = {
      token: context.props.user.loginToken
    };
    context.props.getUserRating(params, res => {this.setState({flag:true});});
  }

  render() {
 const columns =[
          {
            Header: 'Booking Number',
            accessor: 'bookingNumber'
          },
          {
            Header: 'Rider Name(Avg Rating)',
            accessor: 'avgUser'
          },
          {
            Header: 'Driver Name',
            accessor: 'driverName'
          },
          {
            Header: 'Rating',
            accessor: 'ratedByUser'
          },
          {
            Header: 'Date',
            accessor: 'createdAt'
          },
          // {
          //   Header: 'View',
          //   accessor: 'action',
          //   Cell: props => (
          //     <div class="col-md-12 col-sm-12 driver-class">
          //   <button className='add-driver' onClick={() => {
          //          this.openModal(props,true);
          //        }}>View</button>
          //   </div>
          //   )
          // }
        ];
  let userData = this.props.user.ratingData;
  //let userData = [{ bookingNumber: 88973344, driverName: 'Anish (4.1)',userName: 'Sam', rating: 4, createdAt: '29-05-2019' },{ bookingNumber: 88973345, driverName: 'Max (4.1)',userName: 'Tom', rating: 4, createdAt: '01-06-2019' },{ bookingNumber: 88973346, driverName: 'Tony (3.1)',userName: 'James', rating: 3, createdAt: '01-06-2019' },{ bookingNumber: 88973349, driverName: 'Larry (5.0)',userName: 'Jamy', rating: 5, createdAt: '02-06-2019' },{ bookingNumber: 88973350, driverName: 'King (3.3)',userName: 'Samy', rating: 3, createdAt: '04-06-2019' },{ bookingNumber: 88973352, driverName: 'Casy (4.1)',userName: 'Hank', rating: 4, createdAt: '04-06-2019' }]
    return (
      <section className="main-content frontend" id="home">
      <section className="content">
      <div className="main-container">
      <div className="container-fluid">
     
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Rider Reviews</h1>
      </div>
      <div className="row">
        <div className="col-xl-12 col-lg-12 table-custom">
          { this.state.flag ? <Table data={ userData } columns={ columns } tableType={ 'reviews' } fileName={'Rider Revews'}/> : (
                  <img src={loading} className="loading" />
                ) }
      </div>
    </div>
     </div>
  </div>
</section>
    </section>

    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  getUserRating: bindActionCreators(getUserRating, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(RiderReviews);