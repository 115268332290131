import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateUser } from '../actions/admin';
import * as toast from '../actions/toast-actions';
import _ from 'underscore';
import AlertMsg from '../components/AlertMsg';
import loading from '../img/loading.gif';
import Select from 'react-select';
import { Junior_options, Secondary_options, Primary_options, University_options } from '../utilities/SchoolsRawData';


class EditUser extends Component {
  constructor(props) {
    super(props);
    const {userId} = props.match.params;
    this.state = {
      flag: true,
      
      userId: !_.isEmpty(userId)? atob(userId) : null,
      name: '',
      email: '',
      username: '',
      dob: '',
      mobile_number: '',
      primary_school: '',
      secondary_school: '',
      university: '',
      junior_college: '',
      complete_phone_number: '',
      country:'',
      profile_image:'',
        
      isEditable: true,
      formTitle: 'Edit User',
      buttonText: 'Update'
    };
  }

  componentDidMount() {
    let context = this;
    const {userId} = this.state

    if(userId){
       const userData = _.findWhere(this.props.user.driverData,{id:parseInt(userId)});
    //    console.log('user',userInfo.userId,this.props.user.driverData,userData);
      const editableState = {
                              userId: userData.id,
                              name: userData.name,
                              email: userData.email,
                              username: userData.username,
                              dob: userData.dob,
                              mobile_number: userData.mobile_number,
                              primary_school: userData.primary_school,
                              secondary_school: userData.secondary_school,
                              university: userData.university,
                              junior_college: userData.junior_college,
                              complete_phone_number: userData.complete_phone_number,
                              country: userData.country,
                              profile_image:userData.profile_image
                            };
      this.setState(editableState);

    }
    let params = {
      token: context.props.user.loginToken
    };


  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { isEditable, userId, name, email, username, dob, university, 
      primary_school, secondary_school, junior_college, mobile_number, complete_phone_number, country, profile_image} = this.state
      const userInfo = {
        id: userId,
        name: name,
        email: email,
        username: username,
        dob: dob,
        mobile_number: mobile_number,
        primary_school: primary_school,
        secondary_school: secondary_school,
        university: university,
        junior_college: junior_college,
        complete_phone_number: complete_phone_number,
        country: country,
        profile_image: profile_image
      };
      console.log('userInfo',userInfo);
    this.setState({ flag: false });

    // let questname = this.state.question;
    // let question_short = questname.blocks[0].data.text;
    
//     let formData = new FormData();
//     formData.append('name', JSON.stringify(this.state.question));
//     formData.append('question_short', question_short);
//     formData.append('subject_id', this.state.subject_id);
//     formData.append('topic_id', this.state.topic_id);
//    // formData.append('question_image', this.state.question_image);
//     formData.append('option1_text', JSON.stringify(this.state.option1));
//   //  formData.append('option1_image', this.state.option1_image);
//     formData.append('option2_text', JSON.stringify(this.state.option2));
//   //  formData.append('option2_image', this.state.option2_image);
//     formData.append('option3_text', JSON.stringify(this.state.option3));
//   //  formData.append('option3_image', this.state.option3_image);
//     formData.append('option4_text', JSON.stringify(this.state.option4));
//   //  formData.append('option4_image', this.state.option4_image);
//     formData.append('answer', this.state.answer);
//   //  formData.append('answer_image', this.state.answer_image);
//     formData.append('explanation', this.state.explanation);
//     formData.append('level', this.state.level);

    let token = this.props.user.loginToken;
    if(isEditable){
       
      this.props.updateUser(userInfo, token, res => {
        console.log(res);
        if (res.status) {
          this.setState({ flag: true });
          this.props.history.push('/all-users');
          toast.toastAction(res.status, res.message);
  
        } else {
          toast.toastAction(res.status, res.message);
          this.setState({
            open: true,
            msg: res.message,
            msgType: res.type,
            msgStatus: res.status,
            flag: true
          });
        }
      });
    }

  };

  handleChange = event => {    
    this.setState({
      
          [event.target.id]: event.target.value
        
    });
  };

  _onChange = event => {
    if (event.target.files && event.target.files[0]) {
      this.setState({ [event.target.id]: event.target.files[0] });
    };
  };

  resetState = () => {
    this.setState({
      flag: true,
      subject_id: '',
      topic_id: '',
      question: '',
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      answer: '',
      explanation: '',
      level: ''
    });
  }


  render() {
    const {formTitle, buttonText, isEditable, name, email, username, dob, university, 
        primary_school, secondary_school, junior_college, mobile_number, complete_phone_number, country } = this.state;

    return (


      <section className="main-content frontend" id="home">

        <section className="content">
          <div className="main-container">
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{formTitle}</h1>
              </div>
              <div className="row">
                {/* <div className="col-md-12 col-sm-12 driver-class1">
                  <button className='add-driver1' onClick={() => { this.props.history.goBack(); }}>Go Back</button>
                </div> */}
                <div className="mainouter col-xl-12">
                  {this.state.flag ?
                    <form onSubmit={this.handleSubmit}>
                      <div className="col-xl-12 col-lg-12 table-custom driver-table">
                        
                        <div className="col-md-6 col-sm-12 add-rider-clm">
                          <div className="add-rider-outer">
                            <label>Name<span className='red'> *</span></label>
                            
                            <input type="text" name='name' placeholder="Enter Name" value={name} id='name' onChange={this.handleChange} required />
                            
                            <label>Username</label>
                            
                            <input type="text" name='username' placeholder="Enter username" value={username} id='username' onChange={this.handleChange} readOnly />
                            
                            <label>Date of Birth</label>                            
                            <input type="text" name='dob' placeholder="Enter dob" value={dob} id='dob' onChange={this.handleChange}   />
                            
                            <label>Junior College</label>                            
                            <select value={junior_college} id="junior_college" name="junior_college" onChange={this.handleChange} >
                              <option value="">Select</option>
                              {Junior_options.map((jo,i)=>{ 
                                return (
                                  <option key={i} value={jo.value}>{jo.label}</option>
                                )
                              })}
                            </select>
                            
                            <label>Mobile Number<span className='red'> *</span></label>                            
                            <input type="text" name='mobile_number' placeholder="Enter Number" value={mobile_number} id='mobile_number' onChange={this.handleChange} required />

                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 add-rider-clm">
                          <div className="add-rider-outer">
                             <label>Email<span className='red'> *</span></label>                            
                            <input type="email" name='email' placeholder="Enter Email" value={email} id='email' onChange={this.handleChange} readOnly />
                            
                            <label>Primary School</label>                            
                            <select name="primary_school" id="primary_school" value={primary_school} onChange={this.handleChange} >
                              <option value="">Select</option>
                              {Primary_options.map((po,i)=>{ 
                                return (
                                  <option key={i} value={po.value}>{po.label}</option>
                                )
                              })}
                            </select>
                            <label>Secondary School</label>                            
                            <select name="secondary_school" id="secondary_school" value={secondary_school} onChange={this.handleChange} >
                              <option value="">Select</option>
                              {Secondary_options.map((so,i)=>{ 
                                return (
                                  <option key={i} value={so.value}>{so.label}</option>
                                )
                              })}
                            </select>
                            <label>Phone Number</label>                            
                            <input type="text" name='complete_phone_number' placeholder="Enter Phone Number" value={complete_phone_number} id='complete_phone_number' onChange={this.handleChange}   />
                            
                            <label>Country</label>                            
                            <input type="text" name='country' placeholder="Enter Country" value={country} id='country' onChange={this.handleChange}   />
                            
                            <label>University</label>                            
                            <select name="university"  id="university" value={university} onChange={this.handleChange} >
                              <option value="">Select</option>
                              {University_options.map((uo,i)=>{ 
                                return (
                                  <option key={i} value={uo.value}>{uo.label}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12 rider-btns">
                          <button className='add-driver1' type='submit'>{buttonText}</button>
                          {!isEditable &&<button className='add-driver1' type='reset' onClick={this.resetState}>Reset</button>}
                        </div>
                      </div>
                    </form>
                    : (
                      <img src={loading} className="loading" />
                    )}

                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  updateUser: bindActionCreators(updateUser, dispatch),

});
export default connect(mapStateToProps, mapDispatchToProps)(EditUser);