import React, { Component } from 'react';
import Table from '../components/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeStatus, getDriverData, getDriverdata } from '../actions/admin';
import * as toast from '../actions/toast-actions';
import _ from 'underscore';
import loading from '../img/loading.gif';
import $ from 'jquery';
import closeIcon from '../img/close-icon.png';

class BlockUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag:false,
      userId : '',
      b1: 'NA',
      b2: 'NA',
      b3:'NA'
    };
  }

  // openModal(props, tab) {
  //   let data = props.original;
  //   if(tab){
  //     this.props.history.push(`/documents/${data.userId}/docs`);
  //     //window.open(`/documents/${data.userId}`, '_blank'); //to open new page2
  //   }else{
  //     this.props.history.push(`/documents/${data.userId}/vehicle`);
  //   }
  // }

  componentDidMount() {
    let context = this;
    let params = {
      token: context.props.user.loginToken
    };
    context.props.getDriverData(params, res => {this.setState({flag: true});});
  }

  changeStatus = (id, reason) => {
    if (reason !== '') {
      let data = { userId: id, token:this.props.user.loginToken, reason: reason, status: 1};
      this.props.changeStatus(data, res => {
            if (res.status) {
                toast.toastAction(res.status, 'User is Successfully unblocked');
                $('[data-dismiss=modal]').trigger({ type: 'click'});
                let update = this.props.user.driverData.filter(
                    item => item.userId !== id
                  );
                  this.props.getDriverdata(update);
            } else {
                toast.toastAction(res.status, res.message);
            }
            }
        )
    ;}
    else{
        toast.toastAction(false, 'Reason field can not be empty');
    }
  };


  openModal = (props, type) => {
      //console.log('===========id', props.original)
      if(type == 1){
        let reason = props.original.blockReasons;
        if(reason && reason.length)
        this.setState({ b1:reason[0], b2: reason[1] , b3: reason[2]  });
      }else{
        $('#reason').val('');
    this.setState({ userId: props.original.userId });
      }
    
  }

  render() {
 const columns =[
          {
            Header: 'Full Name',
            accessor: 'fullName'
          },
          {
            Header: 'Email',
            accessor: 'email'
          },
        //   {
        //     Header: 'Role',
        //     accessor: 'userActiveRole',
        //   },
          {
            Header: 'Mobile',
            accessor: 'completePhoneNumber'
          },
          {
            Header: 'Rating',
            accessor: 'rating'
          },
          {
            Header: 'Reasons',
            accessor: 'comment',
            sortable: false,
            filterable: false,
            Cell: props => (
                <button
                type="button"
                className="add-driver"
                onClick={() => {
                  this.openModal(props, 1);
                }}
                data-toggle="modal"
                href="#myModals"
              >View
                </button>
            )
          },
          {
            Header: 'Status',
            accessor: 'status',
             Cell: props => (  
            <i className={props.original.status == 1 ? 'fa fa-check custom-check': props.original.status == 2 ? 'fa fa-times custom-times' : props.original.status == 3 ? 'fa fa-ban custom-ban' : 'fa fa-clock-o' } aria-hidden="true" ></i>
            )
          },
          {
            Header: 'Action',
            accessor: 'action',
            sortable: false,
            filterable: false,
            Cell: props => (
                <button
                type="button"
                className="add-driver"
                onClick={() => {
                  this.openModal(props, 2);
                }}
                data-toggle="modal"
                href="#myModal"
              >UnBlock
                </button>
            )
          }
        ];

  let userData = this.props.user.driverData.filter(
      item => {
          if(item.status == 3 ){
              item.fullName = item.fullName.capitalizeFirstLetter();
              //item.userActiveRole = item.userActiveRole == 1 ? 'Driver' : 'Rider'
            return item;
          }
    });
      
    return (
        <div>
      <section className="main-content frontend" id="home">
      <section className="content">
      <div className="main-container">
      <div className="container-fluid">

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Blocked Users</h1>
      </div>
      <div className="row">
      
        <div className="col-xl-12 col-lg-12 table-custom">
        { this.state.flag ? <Table data={ userData } columns={ columns } tableType={ 'drivers1' } fileName={'Blocked Users'}/> : (
                  <img src={loading} className="loading" />
                ) }
      </div>
    </div>
     </div>
  </div>
</section>
    </section>
    <div
    aria-hidden="true"
    aria-labelledby="myModalLabel"
    role="dialog"
    tabIndex="-1"
    id="myModal"
    className="modal fade"
    >
    <div className="modal-dialog report-abuse">
      <div className="modal-content">
        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
          <img src = {closeIcon} />
        </button>
        <h4 align="center">
         Un Block Reason
        </h4>
        <div className="col-md-12  text-center">
          <textarea id = "reason" align = 'center'></textarea>
          <button className="report-abuse-btn" onClick={() => {
            this.changeStatus(this.state.userId, document.getElementById('reason').value);
          }}>UnBlock</button>
        </div>
      </div>
    </div>

    </div>

    <div
    aria-hidden="true"
    aria-labelledby="myModalLabel"
    role="dialog"
    tabIndex="-1"
    id="myModals"
    className="modal fade"
    >
    <div className="modal-dialog report-abuse">
      <div className="modal-content">
        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
          <img src = {closeIcon} />
        </button>
        <h4 align="center">
         Block Reason
        </h4>
        <div className="col-md-12  text-center">
          <ui>
            <li>{this.state.b1}</li>
            <li>{this.state.b2}</li>
            <li>{this.state.b3}</li>
          </ui>
          <button className="report-abuse-btn" data-dismiss="modal" aria-hidden="true">close</button>
        </div>
      </div>
    </div>
    
    </div>
    </div>
   
    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  changeStatus: bindActionCreators(changeStatus, dispatch),
  getDriverData: bindActionCreators(getDriverData, dispatch),
  getDriverdata: bindActionCreators(getDriverdata, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(BlockUsers);
