import React, { Component } from 'react';
import { connect } from 'react-redux';
import page from '../img/page-not-found.png'
import { Gmaps, Marker } from 'react-gmaps';
import constants from '../constants/connection';
const params = {v:constants.v, key: constants.GOOGLE_API_KEY };
//import { bindActionCreators } from 'redux';
//import { verification } from '../actions/admin';
//import AlertMsg from '../components/AlertMsg';
//import Message from '../constants/messages';


class Docdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      msg: '',
      msgType: '',
      msgStatus: false,
      fullName: '',
      insuranceDoc: '',
      licenseNumber: '',
      licenseDoc: '',
      licenseDocBack: '',
      licenseIssuedOn: '',
      licenseExpiryDate: '',
      regDoc :'',
      vehicleImage: '',
      brand:'',
      model:'',
      year: '',
      color: '',
      carNumberId:'',
      permitDoc: ''
    };
    this.userId = props.match.params.userId;
    //this.bookId = props.match.params.bookingNumber
    this.kind = props.match.params.type;
  }

 componentDidMount(){
   if(this.kind == 'docs'){
       let userData = this.props.user.driverData.filter(
          item => item.userId == this.userId
        );
        
    if(userData.length){
      let data = userData[0].driverDetails;
      // this.setState({ fullName: 'Tom Stack', insuranceDoc: 'http://www.pdf995.com/samples/pdf.pdf',licenseNumber:data.licenseNumber, licenseDoc:data.licenseDoc, licenseIssuedOn:data.licenseIssuedOn, licenseExpiryDate:data.licenseExpiryDate, regDoc:data.regDoc, vehicleImage:data.vehicleImage, brand:data.brand, model:data.model, year:data.year, color:data.color, carNumberId:data.carNumberId, permitDoc:data.permitDoc });
      this.setState({ fullName: userData[0].fullName, insuranceDoc: data.insuranceDoc,licenseNumber:data.licenseNumber, licenseDoc:data.licenseDoc, licenseDocBack: data.licenseDocBack, licenseIssuedOn:data.licenseIssuedOn, licenseExpiryDate:data.licenseExpiryDate, regDoc:data.regDoc, vehicleImage:data.vehicleImage, brand:data.brand, model:data.model, year:data.year, color:data.color, carNumberId:data.carNumberId, permitDoc:data.permitDoc });
    }
   }else{

   }
 }

 checkExtenction = (f) => {
    if(f.endsWith('.png') || f.endsWith('.jpg') || f.endsWith('.jpeg')){
      return true;
    }else{
      return false;
    }
 }

 // draw polyline on map
 handleMapCreated = ( map) => {
  let booking = this.props.booking.bookingData.filter(item => item.bookingNumber == this.userId);
  booking = booking[0];
  let loc = booking.driverLatLog.map(i=> {return {lat:i.lat, lng:i.log}} );
  
  const poly = new window.google.maps.Polyline({
    path: loc,
    geodesic: true,
    strokeColor: '#FF0000',
    strokeOpacity: 1.0,
    strokeWeight: 2,
  })

  poly.setMap(map)
}

  render() {
    if(this.kind == 'vehicle') {
      return (
        <section className="main-content frontend" id="home">
        <section className="content">
        <div className="main-container">
        <div className="container-fluid">

        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Vehicle Details</h1>
        </div>
        <div class="col-md-12 col-sm-12 driver-class1">
        <button className='add-driver1' onClick={()=>{ this.props.history.goBack();}}>Go Back</button>
        </div>

        <div className="row">
        <div className="col-md-4 col-sm-6">
          <div class="licence-outer">
            <h4> vehicle Image </h4>
                <div class="licence-content">
            <h5> <b>Brand:</b><span> {this.state.brand}</span></h5>
            <h5> <b>Plate Number:</b><span> {this.state.carNumberId}</span></h5>
            <h5> <b>Color:</b> <span>{this.state.color}</span></h5>
            <h5> <b>Model:</b> <span>{this.state.model}</span></h5>
            <h5> <b>Year:</b> <span>{this.state.year}</span></h5>
            </div>
            <div class="licence-img">
                 {this.checkExtenction(this.state.vehicleImage)? <img src={this.state.vehicleImage}/> : <i class="fa fa-file-pdf-o fa-5x" onClick={()=>{ window.open(this.state.vehicleImage, '_blank');}} aria-hidden="true"></i>}
            </div>
            </div>
          </div>
      </div>
       </div>
    </div>
  </section>
      </section>

      );
    }
  else if(this.kind == 'details'){
      let booking = this.props.booking.bookingData.filter(item => item.bookingNumber == this.userId);
      booking = booking[0];
      let last = booking.driverLatLog.length ? booking.driverLatLog.slice(-1)[0] : '';
      
      return (
        <section className="main-content frontend" id="home">
        <section className="content">
        <div className="main-container">
        <div className="container-fluid">

        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Invoice</h1>
        </div>
        <div class="col-md-12 col-sm-12 driver-class1">
        <button className='add-driver1' onClick={()=>{ this.props.history.goBack();}}>Go Back</button>
        </div>

        <div className="row">
        <div className="col-md-6 col-sm-6">

        <div className='map-img'>
        <div className='map-img-div'>
        {booking.driverLatLog.length ? 
            <Gmaps
                width={'100%'}
                onClick={e => this.onClick(this,e)}
                height={'500px'}
                lat={ booking.driverLatLog[0].lat}
                lng={booking.driverLatLog[0].log}
                zoom={15}
                loadingMessage={'loading'}
                params={params}
                onMapCreated={this.handleMapCreated}>
                <Marker
               lat={ booking.driverLatLog[0].lat}
               lng={booking.driverLatLog[0].log}
                draggable={false}
                ref="Marker"
                />
                <Marker
                lat={ last.lat}
                lng={last.log}
                draggable={true}
                ref="Marker"
                />
            </Gmaps> : <span className="not-img-div" title='Route did not found'><img src={page}></img></span>}
            </div>

          {/* <div className="map-img-div">
          {booking.mapImage.length? <img src={booking.mapImage}></img> : <span className="not-img-div"><img src={page}></img></span>}
             
          </div> */}
          <div className="map-description">
            <p><i class="fa fa-map-marker" aria-hidden="true"></i> <span><b>Pickup Location :</b></span> <span>{booking.pickupAddress}</span></p>
            <p><i class="fa fa-map-marker" aria-hidden="true"></i> <span><b>Drop Location :</b></span> <span>{booking.dropAddress}</span></p>
          </div>
          
        </div>
        </div>
        <div className="col-md-6 col-sm-6">

        <div class="licence-outer">
            <h4> Fare Breakdown For this Ride</h4>
            <div class="licence-content">
            <h5> <b>Payment Method:</b><span> { booking.paymentMethod } </span></h5>
            <h5> <b>Distance:</b><span>{booking.distance}</span></h5>
            <h5> <b>Estimated Fare:</b><span> {booking.tentativeBudget}</span></h5>
            <h5> <b>Tip:</b><span> {booking.tip}</span></h5>
            {/* <h5> <b>Commission:</b><span> {}</span></h5> */}
            <h5> <b>Total Fare:</b><span>{booking.totalCost}</span></h5>
            </div>
            </div>
        </div>

      </div>
       </div>
    </div>
  </section>
      </section>

      );
    }else{
      return (
        <section className="main-content frontend" id="home">
        <section className="content">
        <div className="main-container">
        <div className="container-fluid">

        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Vehicle Documents of {this.state.fullName}</h1>
        </div>
        <div class="col-md-12 col-sm-12 driver-class1">
        <button className='add-driver1' onClick={()=>{ this.props.history.goBack();}}>Go Back</button>
        </div>
        <div className="row">
        <div className="col-md-4 col-sm-6">
        <div class="licence-outer">
            <h4> Insurance </h4>
            <div class="licence-img">
              {this.checkExtenction(this.state.insuranceDoc)? <img src={this.state.insuranceDoc}/> :<i class="fa fa-file-pdf-o fa-5x" onClick={()=>{ window.open(this.state.insuranceDoc, '_blank');}} aria-hidden="true"></i>}
            </div>
            </div>
        </div>

        <div className="col-md-4 col-sm-6">
        <div class="licence-outer">
            <h4> Registration </h4>
            <div class="licence-img">
              {this.checkExtenction(this.state.regDoc)? <img src={this.state.regDoc}/> :  <i class="fa fa-file-pdf-o fa-5x" onClick={()=>{ window.open(this.state.regDoc, '_blank');}} aria-hidden="true"></i>}
            </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-6">
          <div class="licence-outer">
            <h4> Permit </h4>
            <div class="licence-img">
              {this.checkExtenction(this.state.permitDoc)? <img src={this.state.permitDoc}/> : <i class="fa fa-file-pdf-o fa-5x" onClick={()=>{ window.open(this.state.permitDoc, '_blank');}} aria-hidden="true"></i>}
            </div>
            </div>
          </div>

        <div className="col-md-4 col-sm-6">
        <div class="licence-outer">
            <h4> License Front </h4>
                <div class="licence-content">
                <h5><b>License Number : </b>{this.state.licenseNumber}</h5>
                <h5><b>License IssuedOn: </b>{this.state.licenseIssuedOn}</h5>
                <h5><b>License Expiry Date : </b>{this.state.licenseExpiryDate}</h5>
                </div>
                <div class="licence-img">
                {this.checkExtenction(this.state.licenseDoc)? <img src={this.state.licenseDoc}/> :<i class="fa fa-file-pdf-o fa-5x" onClick={()=>{ window.open(this.state.licenseDoc, '_blank');}} aria-hidden="true"></i>}
                </div>
            </div>
        </div>

        <div className="col-md-4 col-sm-6">
        <div class="licence-outer">
            <h4> License Back </h4>
            <div class="licence-content">
                <h5><b>License Number : </b>{this.state.licenseNumber}</h5>
                <h5><b>License IssuedOn: </b>{this.state.licenseIssuedOn}</h5>
                <h5><b>License Expiry Date : </b>{this.state.licenseExpiryDate}</h5>
                </div>
                <div class="licence-img">
                {this.checkExtenction(this.state.licenseDoc)? <img src={this.state.licenseDoc}/> :<i class="fa fa-file-pdf-o fa-5x" onClick={()=>{ window.open(this.state.licenseDoc, '_blank');}} aria-hidden="true"></i>}
                </div>
            </div>
        </div>

          {/* <div className="col-md-4 col-sm-6">
          <div class="licence-outer">
            <h4> vehicle Image </h4>
                <div class="licence-content">
            <h5> <b>Brand:</b> {this.state.brand}</h5>
            <h5> <b>Plate Number:</b> {this.state.carNumberId}</h5>
            <h5> <b>Model:</b> {this.state.model}</h5>
            </div>
            <div class="licence-img">
            <img src={this.state.vehicleImage} />
            </div>
            </div>
          </div> */}
      </div>
       </div>
    </div>
  </section>
      </section>

      );
    }
  }
}

const mapStateToProps = state => ({ user: state.user, booking: state.booking });
// const mapDispatchToProps = dispatch => ({
// });

export default connect(mapStateToProps)(Docdetails);