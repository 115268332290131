import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from '../actions/admin';
import AlertMsg from '../components/AlertMsg';
// import AlertMsg from '../components/AlertMsg';
import Message from '../constants/messages';
// import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
// import { Link } from 'react-router-dom';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password_visibility: false,
      validationErr: null,
      open: false,
      loggingIn: false,
      msg: '',
      msgType: '',
      msgStatus: false
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.handleSubmitFailed = this.handleSubmitFailed.bind(this);
    this.handlePasswordVisibility = this.handlePasswordVisibility.bind(this);
  }

  /******* form validation errors */
  setErrors(message) {
    this.setState({
      validationErr: message
    });
  }

  /*********  Handle on submit failed ***********/
  handleSubmitFailed = user => {
    if (user.email.errors.required) {
      this.setErrors(Message.requiredField('Email'));
    } else if (user.email.errors.validateEmail) {
      this.setErrors(Message.validEmail);
    } else if (user.password.errors.required) {
      this.setErrors(Message.requiredField('Password'));
    }
  };

  /*************** User Login *************/
  handleLogin = (event) => {
    event.preventDefault();
    console.log('tani');
    let user = { email: this.state.username, password: this.state.password, role: '2' };
    if (this.state.username !== '' && this.state.password !== '') {
      this.props.history.replace('/dashboard');
      this.setState({ loggingIn: true });
      this.props.login(user, res => {
        if (res.status) {
          this.props.history.replace('/dashboard');
          this.setState({ loggingIn: true });
          document.getElementById('reset').click();
        } else {
          console.log('in here=========');
          this.setState({
            open: true,
            msg: res.message,
            msgType: res.type,
            msgStatus: res.status
          });
        }
      });
    }
    else {
      this.setState({
        open: true,
        msg: 'These fields can not be empty',
        msgType: 'error',
        msgStatus: false
      });
    }
  };
  /*************** Password Visibility toggle *************/
  handlePasswordVisibility = () => {
    this.setState({
      password_visibility: !this.state.password_visibility
    });
  };
  handleChange = event => {
    //  console.log("sd");
    this.setState({ username: event.target.value });
  };
  handleChangeP = event => {
    this.setState({ password: event.target.value });
  }

  forgetPassword = () => {
    this.props.history.push('/forgetpassword');
  }

  render() {
    return (
      <div className="bg-gradient-primary custom-padding">
        <div className="container">
          <AlertMsg
            onPress={() => this.setState({ open: false })}
            isShowingModal={this.state.open}
            msg={this.state.msg}
            type={this.state.msgType}
            status={this.state.msgStatus}
          />

          <div className="row justify-content-center">

            <div className="col-xl-10 col-lg-12 col-md-9">

              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-6 d-none d-lg-block"></div>
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                        </div>
                        <form className="user" onSubmit={this.handleLogin} >
                          <div className="form-group">
                            <input type="email" className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address..." onChange={this.handleChange} />
                          </div>
                          <div className="form-group">
                            <input type="password" className="form-control form-control-user" id="exampleInputPassword" placeholder="Password" onChange={this.handleChangeP} />
                          </div>
                          <div className="form-group">
                            <div className="custom-control custom-checkbox small">
                              <input type="checkbox" className="custom-control-input" id="customCheck" />
                              <label className="custom-control-label" for="customCheck">Remember Me</label>
                            </div>
                          </div>
                          <button type='submit' className="btn btn-primary btn-user btn-block"  >
                            Login
                </button>

                          {/* <a href="index.html" className="btn btn-google btn-user btn-block">
                  <i className="fab fa-google fa-fw"></i> Login with Google
                </a>
                <a href="index.html" className="btn btn-facebook btn-user btn-block">
                  <i className="fab fa-facebook-f fa-fw"></i> Login with Facebook
                </a> */}
                        </form>
                        <hr />
                        <div className="text-center">
                          <a className="small" onClick={this.forgetPassword}>Forgot Password?</a>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  login: bindActionCreators(login, dispatch)
});

export default connect(null, mapDispatchToProps)(Login);
