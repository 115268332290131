/*
 * @file: Connection.js
 * @description: Connection file for the application
 * @date: 21.11.2018
 * @author: Megha Sethi
 * */

import * as PATH from './app-config';

export const API_URL = PATH.API_URL;
export const STAGING_URL = PATH.STAGING_API_URL;
export const SOCKET_URL = PATH.SOCKET_URL;

export default PATH;
