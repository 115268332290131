/*
 * @file: booking.js
 * @description: It Contain action types Related Action.
 * @date: 4.7.2019
 * @author: Megha Sethi
 */

/******** Booking *********/
export const REPORT_ABUSE = 'REPORT_ABUSE';
export const REMOVE_ABUSE = 'REMOVE_ABUSE';
export const BOOK_DATA = 'BOOK_DATA';
