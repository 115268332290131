import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addDrivers, forgetPassword } from '../actions/admin';
import * as toast from '../actions/toast-actions';
import _ from 'underscore';
import AlertMsg from '../components/AlertMsg';
import loading from '../img/loading.gif';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

class AddDrivers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: true,
      startDate: moment().valueOf(),
      expDate: moment().valueOf(),
      dob: moment().valueOf(),
      firstName: '',
      lastName: '',
      pass: '',
      email: '',
      carNumber: '',
      brand: '',
      modal: '',
      color: '',
      year: '',
      phoneCode: '',
      phoneNumber: '',
      licenseNumber: '',
      carType: '',
      veh: '',
      driverImage: '',
      insuranceDoc: '',
      permitDoc: '',
      licenseDoc: '',
      licenseDocBack: '',
      regDoc: ''
    };
  }

  handleDate = (t, date) => {
    let d = moment(date).valueOf();
    if (t == 1) {
      this.setState({
        startDate: d
      });
    } else if (t == 0) {
      this.setState({
        expDate: d
      });
    } else if (t == 2) {
      this.setState({
        dob: d
      });
    }
  }

  handelSubmit = (event) => {
    event.preventDefault();
    let whiteSpace = /\S/;
    //let alfa = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$')
    let message;
    this.setState({ flag: false });
    let formData = new FormData();
    let firstName = event.target.firstName.value;
    let lastName = event.target.lastName.value;
    let pass = event.target.pass.value;
    let veh = this.state.veh;
    let carNumber = event.target.carNumber.value;
    let brand = event.target.brand.value;
    let model = event.target.model.value;
    let color = event.target.color.value;
    let year = event.target.year.value;
    let phone = { code: '+' + event.target.phoneCode.value, number: event.target.phoneNumber.value };
    let driverImage = this.state.driverImage;
    let insuranceDoc = this.state.insuranceDoc;
    let permitDoc = this.state.permitDoc;
    let licenseDoc = this.state.licenseDoc;
    let licenseDocBack = this.state.licenseDocBack;
    let licenseNumber = event.target.licenseNumber.value;
    // let issuedOn = event.target.issuedOn.value;
    // let expOn = event.target.expOn.value;
    let issuedOn = this.state.startDate;
    let expOn = this.state.expDate;
    let regDoc = this.state.regDoc;
    let carType = event.target.carType.value;

    let email = event.target.email.value;

    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('password', pass);
    formData.append('phone', JSON.stringify(phone));
    formData.append('payKey', 1);
    if (email.length) {
      formData.append('email', email);
    }

    formData.append('userActiveRole', 1);
    //formData.append('profile', profile);
    formData.append('vehicleImage', veh);
    formData.append('carNumberId', carNumber);
    formData.append('brand', brand);
    formData.append('model', model);
    formData.append('color', color);
    formData.append('year', year);

    formData.append('driverImage', driverImage);
    formData.append('insuranceDoc', insuranceDoc);
    formData.append('permitDoc', permitDoc);
    formData.append('licenseDoc', licenseDoc);
    formData.append('licenseDocBack', licenseDocBack);
    formData.append('licenseNumber', licenseNumber);
    formData.append('licenseIssuedOn', issuedOn);
    formData.append('licenseExpiryDate', expOn);
    formData.append('regDoc', regDoc);
    formData.append('carId', carType);
    formData.append('dob', this.state.dob);

    // if(regex.test(lastName)){
    //   console.log('=======')
    // }

    //formData.append('token', this.props.user.token);
    //let carType = event.target.carType.value;

    if (carType != 'select' && whiteSpace.test(firstName) && whiteSpace.test(lastName) && whiteSpace.test(brand) && whiteSpace.test(issuedOn) && whiteSpace.test(expOn) && this.state.veh !== '' && this.state.driverImage !== '' && this.state.insuranceDoc !== '' && this.state.permitDoc !== '' && this.state.licenseDoc !== '' && this.state.regDoc !== '') {
      let token = this.props.user.token;
      this.props.addDrivers(formData, token, res => {
        if (res.status) {
          this.setState({ flag: true });
          toast.toastAction(res.status, res.message);
          setTimeout(() => {
            this.props.history.push('/drivers');
          }, 3000);
        } else {
          this.setState({
            open: true,
            msg: res.message,
            msgType: res.type,
            msgStatus: res.status,
            flag: true
          });
        }
      });
    }
    else {
      console.log('======logele',);
      if (this.state.veh == '') {
        message = 'Vehicle Image can not be empty';
      }
      else if (this.state.driverImage == '') {
        message = 'Driver Image can not be empty';
      } else if (this.state.insuranceDoc == '') {
        message = 'Insurance field can not be empty';
      } else if (this.state.permitDoc.length == '') {
        message = 'Permit field can not be empty';
      } else if (this.state.licenseDoc.length == '') {
        message = 'License Image can not be empty';
      } else if (this.state.regDoc.length == '') {
        message = 'Registration Image can not be empty';
      } else {
        message = carType == 'select' ? 'You have to select a valid car type' : 'Invalid data foramt';
      }

      this.setState({
        open: true,
        msg: message,
        msgType: 'error',
        msgStatus: false,
        flag: true
      });
    }
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  resetState = () => {
    this.setState({
      flag: true,
      startDate: moment().valueOf(),
      expDate: moment().valueOf(),
      firstName: '',
      lastName: '',
      pass: '',
      email: '',
      carNumber: '',
      brand: '',
      modal: '',
      color: '',
      year: '',
      phoneCode: '',
      phoneNumber: '',
      licenseNumber: '',
      carType: '',
      driverImage: '',
      insuranceDoc: '',
      permitDoc: '',
      licenseDoc: '',
      regDoc: ''
    });
  }

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      console.log('==========', event.target);
      this.setState({ [event.target.id]: event.target.files[0], });
    };
  }

  render() {
    //let alfa = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$')
    return (
      <section className="main-content frontend" id="home">
        <section className="content">
          <div className="main-container">
            <div className="container-fluid">
              <AlertMsg
                onPress={() => this.setState({ open: false })}
                isShowingModal={this.state.open}
                msg={this.state.msg}
                type={this.state.msgType}
                status={this.state.msgStatus}
              />
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Add Driver</h1>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 driver-class1">
                  <button className='add-driver1' onClick={() => { this.props.history.goBack(); }}>Go Back</button>
                </div>
                <div className="mainouter col-xl-12">
                  {this.state.flag ?
                    <form onSubmit={this.handelSubmit}>
                      <div className="col-xl-12 col-lg-12 table-custom driver-table">
                        <div className="col-md-6 col-sm-12 add-rider-clm">
                          <div className="add-rider-outer">
                            <label>First Name<span className='red'> *</span></label>
                            <input type="text" name='firstName' placeholder="First Name" value={this.state.firstName} id='firstName' onChange={this.handleChange} required />
                            <label>Last Name<span className='red'> *</span></label>
                            <input type="text" name='lastName' placeholder="Last Name" value={this.state.lastName} id='lastName' onChange={this.handleChange} required />
                            <label>E-mail</label>
                            <input type="email" placeholder="E-mail" name='email' value={this.state.email} id='email' onChange={this.handleChange} autocomplete="off" />
                            <label>Password <span className='red'> *</span> <i className="fa fa-cog pass" title="Password must be alphanumeric with 6 to 15 characters"></i>
                            </label>
                            <input type="password" name='pass' autocomplete="new-password" placeholder="Password" minLength='6' maxLength='15' id='pass' required />

                            <label>Vehicle Image<span className='red'> *</span></label>
                            <span className='input-output'> {this.state.veh.name}</span>
                            <input id='veh' className='input-hid' type="file" name="veh" accept="image/*, .pdf" id='veh' onChange={this.onImageChange} />

                            <label>Car Number Plate<span className='red'> *</span></label>
                            <input type="text" placeholder="Number Id" name='carNumber' value={this.state.carNumber} id='carNumber' onChange={this.handleChange} required />

                            <label>Brand<span className='red'> *</span></label>
                            <input type="text" placeholder="Brand" name='brand' value={this.state.brand} id='brand' onChange={this.handleChange} required />

                            <label>Model<span className='red'> *</span></label>
                            <input type="text" placeholder="Model" name='model' value={this.state.modal} id='modal' onChange={this.handleChange} required />

                            <label>Year<span className='red'> *</span></label>
                            <input type="number" placeholder="Year" name='year' min='0' value={this.state.year} id='year' onChange={this.handleChange} required />

                            <label>Color<span className='red'> *</span></label>
                            <input type="text" placeholder="Color" name='color' value={this.state.color} id='color' onChange={this.handleChange} required />
                            <label>Car Type<span className='red'> *</span></label>
                            <select name='carType' className='drop' value={this.state.carType} id='carType' onChange={this.handleChange} >
                              <option value="select">Select Car Type</option>
                              <option value="Hatchback">Hatchback</option>
                              <option value='Sedan'>Sedan</option>
                            </select>

                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 add-rider-clm">
                          <div className="add-rider-outer">

                            <label>Phone Code<span className='red'> *</span></label>
                            <input type="number" min='0' placeholder="Phone Code" name='phoneCode' value={this.state.phoneCode} id='phoneCode' onChange={this.handleChange} required />
                            <label>Phone<span className='red'> *</span></label>
                            <input id='phone' type="number" placeholder="Phone" min='0' maxLength='10' name='phoneNumber' value={this.state.phoneNumber} id='phoneNumber' onChange={this.handleChange} required />

                            <label>Driver Image<span className='red'> *</span></label>
                            <span className='input-output'> {this.state.driverImage.name}</span>
                            <input type="file" className='browse-area input-hid' id="driverImage" accept="image/*" onChange={this.onImageChange} name='driverImage' accept="image/*, .pdf" />

                            <label>Permit<span className='red'> *</span></label>
                            <span className='input-output'> {this.state.permitDoc.name}</span>
                            <input type="file" className='input-hid' accept="image/*" name='permitDoc' id='permitDoc' onChange={this.onImageChange} accept="image/*, .pdf" />
                            <label>Insurance<span className='red'> *</span></label>
                            <span className='input-output'> {this.state.insuranceDoc.name}</span>
                            <input type="file" className='input-hid' id='insuranceDoc' onChange={this.onImageChange} accept="image/*" name='insuranceDoc' accept="image/*, .pdf" />

                            <label>Registration<span className='red'> *</span></label>
                            <span className='input-output'> {this.state.regDoc.name}</span>
                            <input type="file" className='input-hid' accept="image/*" name='regDoc' id='regDoc' onChange={this.onImageChange} accept="image/*, .pdf" />


                            <label>License Front<span className='red'> *</span></label>
                            <span className='input-output'> {this.state.licenseDoc.name}</span>
                            <input type="file" className='input-hid' accept="image/*" name='licenseDoc' id='licenseDoc' onChange={this.onImageChange} accept="image/*, .pdf" />

                            <label>License Back<span className='red'> *</span></label>
                            <span className='input-output'> {this.state.licenseDocBack.name}</span>
                            <input type="file" className='input-hid' accept="image/*" name='licenseDocBack' id='licenseDocBack' onChange={this.onImageChange} accept="image/*, .pdf" />

                            <label>License Number<span className='red'> *</span></label>
                            <input type="text" placeholder="License Number" name='licenseNumber' value={this.state.licenseNumber} id='licenseNumber' onChange={this.handleChange} required />

                            <label>License Issued On<span className='red'> *</span></label>
                            {/* <DatePicker
                              selected={this.state.startDate}
                              onSelect={this.handleSelect}
                              onChange={(e) => { this.handleDate(1, e); }}
                              showMonthDropdown
                              showYearDropdown
                              maxDate={moment().toDate()}
                              required
                            /> */}
                            {/* <input type="text" placeholder="License Issued On" name='issuedOn' min='0' required/> */}

                            <label>License Expiry Date<span className='red'> *</span></label>
                            {/* <DatePicker
                              selected={this.state.expDate}
                              onSelect={this.handleSelect}
                              onChange={(e) => { this.handleDate(0, e); }}
                              showMonthDropdown
                              showYearDropdown
                              minDate={moment().toDate()}
                              name='expOn'
                              required
                            /> */}
                            {/* <input type="text" placeholder="License Expiry Date" name='expOn' min='0' required/> */}
                            <label>Date of Birth<span className='red'> *</span></label>
                            {/* <DatePicker
                              selected={this.state.dob}
                              onSelect={this.handleSelect}
                              onChange={(e) => { this.handleDate(2, e); }}
                              showMonthDropdown
                              showYearDropdown
                              maxDate={moment().toDate()}
                              name='dob'
                              required
                            /> */}
                          </div>
                        </div>
                        <div className="col-md-12 col-sm-12 rider-btns">
                          <button className='add-driver1' type='submit' >Add Driver</button>
                          <button className='add-driver1' type='reset' onClick={this.resetState}>Reset</button>
                        </div>
                      </div>
                    </form>
                    : (
                      <img src={loading} className="loading" />
                    )}

                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  addDrivers: bindActionCreators(addDrivers, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddDrivers);