import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addDrivers, forgetPassword, updateSubject } from '../actions/admin';
import * as toast from '../actions/toast-actions';
import _ from 'underscore';
import AlertMsg from '../components/AlertMsg';
import loading from '../img/loading.gif';

class AddSubject extends Component {
  constructor(props) {
    super(props);
    const {subjectId} = props.match.params;
    this.initialState = {
      flag: true,
      name: '',
      subjectId: !_.isEmpty(subjectId) ? atob(subjectId) : null,
      formTitle: 'Add Subject',
      buttontext: 'Add',
      isEditable: false
    };
    this.state = this.initialState;
  }

  componentDidMount(){
    const {subjectId} = this.state;
    if(subjectId){
      let subjectData = _.findWhere(this.props.user.subjectData,{id:parseInt(subjectId)});
      console.log('subjectData',subjectId,this.props.user.subjectData,subjectData);
      this.setState({
        name: subjectData.name,
        isEditable: true,
        formTitle: "Update Subject",
        buttontext: "Update"
      });
    }
  }

  handelSubmit = (event) => {
    event.preventDefault();
    const {subjectId, isEditable} = this.state;
    this.setState({ flag: false });
    let token = this.props.user.loginToken;

    if(isEditable){
      this.props.updateSubject({id: subjectId, name: this.state.name}, token, res => {
        console.log(res);
        if (res.status) {
          this.setState({ flag: true });
          toast.toastAction(res.status, res.message);
          setTimeout(() => {
            this.props.history.push('/subjects');
          }, 3000);
        } else {
          toast.toastAction(res.status, res.message);
          this.setState({
            open: true,
            msg: res.message,
            msgType: res.type,
            msgStatus: res.status,
            flag: true
          });
        }
      });
    }else{
      this.props.addDrivers({name: this.state.name}, token, res => {
        console.log(res);
        if (res.status) {
          this.setState({ flag: true });
          toast.toastAction(res.status, res.message);
          setTimeout(() => {
            this.props.history.push('/subjects');
          }, 3000);
        } else {
          toast.toastAction(res.status, res.message);
          this.setState({
            open: true,
            msg: res.message,
            msgType: res.type,
            msgStatus: res.status,
            flag: true
          });
        }
      });
    }

  };



  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  resetState = () => {
    this.setState({
      flag: true,
      name: '',
    });
  }

  componentWillReceiveProps(props){   
    if(_.isEmpty(props.match.params)){
      this.setState(this.initialState);
    }
  }

  render() {

    const {formTitle,buttontext, name, isEditable}= this.state;

    //let alfa = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$')
    return (
      <section className="main-content frontend" id="home">
        <section className="content">
          <div className="main-container">
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{formTitle}</h1>
              </div>
              <div className="row">
                {/* <div className="col-md-12 col-sm-12 driver-class1">
                  <button className='add-driver1' onClick={() => { this.props.history.goBack(); }}>Go Back</button>
                </div> */}
                <div className="mainouter col-xl-12">
                  {this.state.flag ?
                    <form onSubmit={this.handelSubmit}>
                      <div className="col-xl-12 col-lg-12 table-custom driver-table">
                        <div className="col-md-6 offset-md-3 col-sm-12 add-rider-clm">
                          <div className="add-rider-outer">
                            <label>Subject Name<span className='red'> *</span></label>
                            <input type="text" name='name' placeholder="Enter Subject" value={name} id='name' onChange={this.handleChange} required />
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12 rider-btns">
                          <button className='add-driver1' type='submit' >{buttontext}</button>
                          {!isEditable &&<button className='add-driver1' type='reset' onClick={this.resetState}>Reset</button>}
                        </div>
                      </div>
                    </form>
                    : (
                      <img src={loading} className="loading" />
                    )}

                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  addDrivers: bindActionCreators(addDrivers, dispatch),
  updateSubject: bindActionCreators(updateSubject, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddSubject);