import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetPassword } from '../actions/admin';
import AlertMsg from '../components/AlertMsg';


class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      msg: '',
      msgType: '',
      msgStatus: false
    };
    this.handleReset = this.handleReset.bind(this);
  }

  handleReset = async (event) => {
    event.preventDefault();
    let pass = document.getElementById('pass').value;
    let confirm = document.getElementById('pass2').value;
    if(pass !== confirm || pass === '' || confirm === ''){
      this.setState({
        open: true,
        msg: 'Password did not match',
        msgType: 'error',
        msgStatus: false
      });

    }else{
      let data={ password:pass, token: this.props.user.token };
      await this.props.resetPassword(data, res => {
        if (res.status) {
           this.props.history.replace('/');
        } else {
           this.setState({
             open: true,
             msg: res.message,
             msgType: res.type,
             msgStatus: res.status
            });
           }
        });
    }
  };

  render() {
    return (
      <div className="bg-gradient-primary custom-padding">
  <div class="container">
    <AlertMsg
      onPress={() => this.setState({ open: false })}
      isShowingModal={this.state.open}
      msg={this.state.msg}
      type={this.state.msgType}
      status={this.state.msgStatus}
    />
<div class="row justify-content-center">

  <div class="col-xl-10 col-lg-12 col-md-9">

    <div class="card o-hidden border-0 shadow-lg my-5">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
          <div class="col-lg-6">
            <div class="p-5 change-password">
              <div class="text-center">
                <h1 class="h4 text-gray-900 mb-4">Change Password</h1>
              </div>
              <form class="user" onSubmit={this.handleReset}  >
                <div class="form-group">
                  <input type="password" class="form-control form-control-user" id="pass" aria-describedby="emailHelp" placeholder="Enter New Password"/>
                </div>
                <div class="form-group">
                  <input type="password" class="form-control form-control-user" id="pass2" aria-describedby="emailHelp" placeholder="Confirm Password"/>
                </div>

                <button type='submit' class="btn btn-primary btn-user btn-block" >
                  Reset Password
                </button>
              </form>
              <hr/>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</div>
</div>

    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
    resetPassword: bindActionCreators(resetPassword, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
