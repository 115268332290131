import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addTopic, updateTopic, getSubjects } from '../actions/admin';
import * as toast from '../actions/toast-actions';
import _ from 'underscore';
import AlertMsg from '../components/AlertMsg';
import loading from '../img/loading.gif';

class AddTopic extends Component {
  constructor(props) {
    super(props);
    const {topicId} = props.match.params;
    this.initialState = {
      flag: true,
      name: '',
      subject_id: '',
      topicId: topicId ? atob(topicId) : null,
      isEditable: false,
      formTitle: "Add Topic",
      buttonText: "Add" 
    };
    this.state = this.initialState;
  }

  handelSubmit = (event) => {
    event.preventDefault();
    const {isEditable, topicId} = this.state;
    // let formData = new FormData();
    // let name = event.target.name.value;

    // formData.append('name', name);
    if(this.state.subject_id == '') {
      toast.toastAction(false, 'Please select some subject');;
      return;
    }
    this.setState({ flag: false });
    if(isEditable){
      let token = this.props.user.loginToken;
      this.props.updateTopic({ id: topicId, name: this.state.name, subject_id: this.state.subject_id, status: '1' }, token, res => {
        console.log(res);
        if (res.status) {
          this.setState({ flag: true });
          toast.toastAction(res.status, res.message);
          this.props.history.push('/topics');
  
        } else {
          toast.toastAction(res.status, res.message);
          this.setState({
            open: true,
            msg: res.message,
            msgType: res.type,
            msgStatus: res.status,
            flag: true
          });
        }
      });
    }else{

      let token = this.props.user.loginToken;
      this.props.addTopic({ name: this.state.name, subject_id: this.state.subject_id, status: '1' }, token, res => {
        console.log(res);
        if (res.status) {
          this.setState({ flag: true });
          toast.toastAction(res.status, res.message);
          this.props.history.push('/topics');
  
        } else {
          toast.toastAction(res.status, res.message);
          this.setState({
            open: true,
            msg: res.message,
            msgType: res.type,
            msgStatus: res.status,
            flag: true
          });
        }
      });
    }

  };

  componentDidMount(){
    let context = this;
    const {topicId} = this.state;
    // _.findWhere()
    console.log('topicID',topicId)
    if(topicId){

      let topicData = _.findWhere(this.props.user.topicsData, { id: parseInt(topicId) });
      console.log('topicData',topicId,this.props.user.topicsData, topicData)
      this.setState({
        name: topicData.name,
        subject_id: topicData.subject_id,
        isEditable: true,
        formTitle: "Update Topic",
        buttonText: "Update"

      });
    }
    let params = {
      token: context.props.user.loginToken
    };
    if(!this.props.user.subjectData.length){      
      context.props.getSubjects(params, res => { this.setState({ flag: true });});
    }
  }

  handleChange = event => {
    console.log(event);
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  resetState = () => {
    this.setState({
      flag: true,
      name: '',
    });
  }

  componentWillReceiveProps(props){   
    if(_.isEmpty(props.match.params)){
      this.setState(this.initialState);
    }
  }

  render() {
    const {formTitle, buttonText, name, subject_id, isEditable} = this.state
    //let alfa = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$')
    return (
      <section className="main-content frontend" id="home">
        <section className="content">
          <div className="main-container">
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{formTitle}</h1>
              </div>
              <div className="row">
                {/* <div className="col-md-12 col-sm-12 driver-class1">
                  <button className='add-driver1' onClick={() => { this.props.history.goBack(); }}>Go Back</button>
                </div> */}
                <div className="mainouter col-xl-12">
                  {this.state.flag ?
                    <form onSubmit={this.handelSubmit}>
                      <div className="col-xl-12 col-lg-12 table-custom driver-table">
                        <div className="col-md-6 col-sm-12 add-rider-clm">
                          <div className="add-rider-outer">
                          <label>Subjects<span className='red'> *</span></label>
                            <select name='subject_id' className='drop' value={this.state.subject_id} id='subject_id' onChange={this.handleChange} >
                            <option value="">Select Subject</option>
                              {this.props.user.subjectData.length > 0 && this.props.user.subjectData.map((e, key) => {
                                  return <option key={key} value={e.id}>{e.name}</option>;
                              })} 
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 add-rider-clm">
                          <div className="add-rider-outer">
                            
                            <label>Topic Name<span className='red'> *</span></label>
                            <input type="text" name='name' placeholder="Enter Topic" value={this.state.name} id='name' onChange={this.handleChange} required />
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12 rider-btns">
                          <button className='add-driver1' type='submit' >{buttonText}</button>
                         {!isEditable && <button className='add-driver1' type='reset' onClick={this.resetState}>Reset</button>}
                        </div>
                      </div>
                    </form>
                    : (
                      <img src={loading} className="loading" />
                    )}

                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  addTopic: bindActionCreators(addTopic, dispatch),
  updateTopic: bindActionCreators(updateTopic, dispatch),
  getSubjects: bindActionCreators(getSubjects, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddTopic);