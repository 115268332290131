/*
 * @file: index.js
 * @description: It Contain User Account Related Action Creators.
 * @date: 20.11.2018
 * @author: Megha Sethi
 */

import RestClient from '../../utilities/RestClient';
import message from '../../constants/messages';
import { API_URL } from '../../constants/connection';
import * as TYPE from '../../constants/actionTypes/booking';

//Action Creator For Reducers

export const reportAbuse = data => ({ type: TYPE.REPORT_ABUSE, data: data });
export const removeAbuse = data => ({ type: TYPE.REMOVE_ABUSE, data: data });
export const bookingData = data => ({ type: TYPE.BOOK_DATA, data: data });

// Thunk Action Creators For Api

/****** action creator to change user/drivers status and approve drivers ********/
export const changeStatus = (params, cb) => {
  return dispatch => {
    let token = params.token;
    delete params.token;
    RestClient.post(`${API_URL}/admin/approveaction`, params, token)
      .then(result => {
        if (result.statusCode === 200) {
          //dispatch(login_Success(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};

/****** action creator to solve abuse between rider/driver ********/
export const solveAbuse = (params, cb) => {
  return dispatch => {
    let token = params.token;
    delete params.token;
    RestClient.put(`${API_URL}/admin/resolveissue`, params, token)
      .then(result => {
        if (result.statusCode === 200) {
            //delete from redux storage
            dispatch(removeAbuse(result.data));
            let res = {
                status: true,
                message: result.message,
                type: message.success,
                data: result.data
            };
            cb(res);
        } else {
            let res = {
                status: false,
                message: result.message,
                type: message.error
            };

            cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};
/****** action creator to get report abuse data  ********/
export const getReportAbuse = (params, cb) => {
  return dispatch => {
    RestClient.get(`${API_URL}/admin/abuseReport`, {}, params.token)
      .then(result => {
        if (result.statusCode === 200) {
          dispatch(reportAbuse(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};

/****** action creator to get booking data  ********/
export const getBookingData = (params, cb) => {
  return dispatch => {
    RestClient.get(`${API_URL}/admin/getbookingdata`, {type: params.type}, params.token)
      .then(result => {
        if (result.statusCode === 200) {
          dispatch(bookingData(result.data));
          let res = {
            status: true,
            message: result.message,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message,
            type: message.error
          };

          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: error.message
        };
        cb(res);
      });
  };
};