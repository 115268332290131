import React, { Component } from 'react';
import Table from '../components/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getBookingData } from '../actions/booking';
import _ from 'underscore';
import loading from '../img/loading.gif';

class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
     flag: false
    };
    this.type = props.match.params.type;
  }

  componentDidMount() {
    let context = this;
    let params = {
      token: context.props.user.loginToken,
      type : 0
    };
    context.props.getBookingData(params, res => {this.setState({flag:true })});
  }

  openModal(props, tab) {
    let data = props.original;
   // console.log('======data==', data);
    if(tab){
      this.props.history.push(`/documents/${data.bookingNumber}/details`);
      //window.open(`/documents/${data.userId}`, '_blank'); //to open new page2
    }
  }

  render() {
    const columns =[
      {
        Header: 'Booking Number',
        accessor: 'bookingNumber'
      },
      {
        Header: 'Rider Name',
        accessor: 'userName'
      },
      {
        Header: 'Drives Name',
        accessor: 'driverName'
      },    
      {
        Header: 'Fare',
        accessor: 'totalCost'
      },
      {
        Header: 'Admin Earning',
        accessor: 'adminEarning'
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: props => (  
          <i className={props.original.status == 3 ? 'fa fa-check custom-check': props.original.status == 6 ||  props.original.status == 7  ? 'fa fa-times-circle-o' : 'fa fa-spinner' } aria-hidden="true" ></i>
          )
      },
      {
        Header: 'Trip date',
        accessor: 'createdAt'
      },  
      {
        Header: 'Action',
        accessor: 'action',
        sortable: false,
        filterable: false,
        Cell: props => (
          <div class="col-md-12 col-sm-12 driver-class">
        <button className='add-driver' onClick={() => {
                   this.openModal(props,true);
                 }}>View</button>
        </div>
        )
      }
    ];

    let data;
    let title = 'All Rides';
    let kind = this.type;
    let t = 'rides'
    //let kind = this.props.location.pathname;
    if(this.props.match.url == "/rides/history"){
      data = this.props.booking.bookingData;
    }else{
      t = 'rides2';
      if(kind == "canceled"){
        title = 'Cancelled Rides'
        data = this.props.booking.bookingData.filter(
          item => (item.status == 6 || item.status == 7));
      }else if(kind == "ongoing"){
        title = 'Ongoing Rides'
        data = this.props.booking.bookingData.filter(
          item => ([1,2,4,5].includes(item.status)));
      }else if(kind == "completed"){
        title = 'Completed Rides'
        data = this.props.booking.bookingData.filter(
          item => (item.status == 3 ))
      }
    }
    
   
   //let data = this.props.booking.bookingData;
      return (
        <section className="main-content frontend" id="home">
        <section className="content">
        <div className="main-container">
        <div className="container-fluid">
  
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800"> {title}</h1>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 table-custom">
          { this.state.flag ? <Table data={ data } columns={ columns } tableType={ t } fileName={title}/> : (
                    <img src={loading} className="loading" />
                  ) }
          </div>
      </div>
       </div>
    </div>
  </section>
      </section>
  
      );
    }
}

const mapStateToProps = state => ({ user: state.user,  booking : state.booking });
const mapDispatchToProps = dispatch => ({
  getBookingData: bindActionCreators(getBookingData, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Booking);
