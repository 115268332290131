import React, { Component } from 'react';
import Table from '../../components/Table';
import AlertMsg from '../../components/AlertMsg';
//import CKEditor from 'ckeditor4-react';
import * as toast from '../../actions/toast-actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPagesData} from '../../actions/admin';
//import { updatePage } from '../../actions/admin';
import loading from '../../img/loading.gif';
import _ from 'underscore';

// const CKEditorConfiguration = {
//   allowedContent: true,
//   extraPlugins: 'divarea',
//   forcePasteAsPlainText: true,
//   extraAllowedContent : 'span;ul;li;table;td;style;[id];();{*}',
//   autoParagraph: false,
//   entities: false,
//   // removeButtons: 'Save,Templates,Cut,Undo
// };

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: false,
      editpage: false,
      editdata:{},
      open: false,
      msg: '',
      msgType: '',
      msgStatus: false,
      flag: true,
      container_html: '',
      img_preview: ''
    };
 //   this.onCKEditorChange = this.onCKEditorChange.bind( this );
  }

  componentDidMount() {
    let context = this;
    let params = {
      token: context.props.user.loginToken
    };
   
    context.props.getPagesData(params, res => {this.setState({flag:true});});
  }

  handelUpdatePage = (event) => {
    event.preventDefault();
    this.setState({flag:false});

    let title =  event.target.title.value;
    let _id =  this.state.editdata._id;
    let description =  event.target.description.value;
    let banner_btn_title =  event.target.banner_btn_title.value;
    let banner_btn_link =  event.target.banner_btn_link.value;
    let banner_img =  event.target.banner_img.files[0] ? event.target.banner_img.files[0] : this.state.editdata.banner_img;
    let container_html =  this.state.container_html ? this.state.container_html : this.state.editdata.container_html;
    let meta =  {
      title: event.target.title.value,
      description: event.target.description.value
    };

    let formData = new FormData();    //formdata object
    formData.append('_id', _id);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('banner_btn_title', banner_btn_title);
    formData.append('banner_btn_link', banner_btn_link);
    formData.append('banner_img', banner_img);
    formData.append('container_html', container_html);
    formData.append('meta', JSON.stringify(meta));

      let token = this.props.user.loginToken;
    //   this.props.updatePage(formData, token, res => {
    //   this.setState({
    //     img_preview: ''
    //   });
    //   if (res.status) {
    //     toast.toastAction(res.status, 'Data is successfully updated');
    //       this.setState({flag:true, editpage: false});
    //       this.componentDidMount();
    //   } else {
    //     this.setState({
    //       open: true,
    //       msg: res.message,
    //       msgType: res.type,
    //       msgStatus: res.status,
    //       flag: true
    //     });
    //   }
    // });
  };

  // onCKEditorChange( evt ) {
  //   this.setState( {
  //       container_html: evt.editor.getData()
  //   } );
  // }

  onImgChange = ( evt ) => {
    this.setState({
      img_preview: URL.createObjectURL(evt.target.files[0])
    });
  }

  render() {
    const columns =[
      {
        Header: 'Title',
        accessor: 'title'
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
      {
        Header: 'Action',
        accessor: 'action',
        sortable: false,
        filterable: false,
        Cell: props => (
          <a
          href="javascript:void(0);"
          id="edit_page"
          onClick={e => {
            this.setState({editdata:props.original,editpage:true, img_preview:props.original.banner_img});
          }}  
        > 
            Edit
        </a>
        )
      }
    ];
    let data = this.props.user.pageData;
    return (
      <div>
        {(!this.state.editpage) ?
          <section className="main-content frontend" id="home">
            <section className="content">
              <div className="main-container">
              <div className="container-fluid">
              
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <h1 className="h3 mb-0 text-gray-800"> Pages Listing</h1>
                </div>
                <div className="row">
                  {/*<div class="col-md-12 col-sm-12 driver-class1">
                    <button className='add-driver1' onClick={()=>{this.props.history.push('/addpage');}}>Add Page</button>
                  </div>*/}
                  <div className="col-xl-12 col-lg-12 table-custom">
                    { this.state.flag ? <Table data={ data } columns={ columns } tableType={ 'pages' } fileName={'Pages Listing'}/> : (
                              <img src={loading} className="loading" />
                            ) }
                    
                  </div>
                </div>
              </div>
            </div>
              
            </section>
            
          </section>

         : 

          <section className="main-content frontend" id="home">
            <section className="content">
              <div className="main-container">
                <div className="container-fluid">
                
                  <AlertMsg
                    onPress={() => this.setState({ open: false })}
                    isShowingModal={this.state.open}
                    msg={this.state.msg}
                    type={this.state.msgType}
                    status={this.state.msgStatus}
                  />
    
                  <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Edit Page</h1>
                  </div>
                  <div className="row">
                    <div class="col-md-12 col-sm-12 driver-class1">
                      <button className='add-driver1' onClick={()=>{ this.setState({editpage:false});}}>Go Back</button>
                    </div>
                    <div class="mainouter col-xl-12">
                      { this.state.flag ? 
                        <form onSubmit={this.handelUpdatePage}>
                          <div className="col-xl-12 col-lg-12 table-custom driver-table">
                            <div class="col-md-6 col-sm-12 add-rider-clm">
                              <div class="add-rider-outer">
                                <label>Title<span className='red'> *</span></label>
                                <input type="text" placeholder="Title" id='title' name='title' defaultValue={this.state.editdata.title} required/>
                                <label>Description<span className='red'> *</span></label>
                                <input type="text" placeholder="Description" id='description' name='description' defaultValue={this.state.editdata.description} required />
                                <label>Banner Button Title<span className='red'> *</span></label>
                                <input type="text" placeholder="Banner Button Title" id='banner_btn_title' name='banner_btn_title'  defaultValue={this.state.editdata.banner_btn_title} required/>
                                <label>Banner Button Link<span className='red'> *</span></label>
                                <input type="text" placeholder="Banner Button Link" id='banner_btn_link' name='banner_btn_link' defaultValue={this.state.editdata.banner_btn_link} required/>
                                <label>Banner Image<span className='red'> *</span></label>
                                <input id='banner_img' type="file" name="banner_img" accept="image/*" onChange={this.onImgChange}/>
                                <img src={this.state.img_preview} width="50%" class="img-preview"></img>
                                <label>Container HTML<span className='red'> *</span></label>
                                {/* <CKEditor 
                                  type="classic"
                                  config={ CKEditorConfiguration }
                                  onChange={this.onCKEditorChange}
                                  data={this.state.editdata.container_html}
                                /> */}
                              </div>
                            </div>
                        
                            <div class="col-md-12 col-sm-12 rider-btns">
                              <button className='add-driver1' type='submit'>Update Page</button>
                              <button className='add-driver1' type='reset'>Reset</button>
                            </div>
                          </div>
                        </form> 
                        : (
                          <img src={loading} className="loading" />
                        ) }
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section> 
         
         }
      </div>
    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  getPagesData: bindActionCreators(getPagesData, dispatch),
//  updatePage: bindActionCreators(updatePage, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Pages);
