
if (process.env.NODE_ENV === 'production') {
  module.exports = {
    API_URL: 'https://pyramid.itechnolabs.tech/api/v1',
    STAGING_API_URL: 'https://rontaxiapp.yapits.com/api/v1/',
    SOCKET_URL: 'https://rontaxiapp.yapits.com/api/v1/',
    GOOGLE_API_KEY: 'AIzaSyDOa2PRHCvDxZMRz8Q3xHiPbqmufWPXQww',
    v: '3.exp'
  };
} else {
  module.exports = {
    API_URL: 'http://localhost:3014/api/v1',
    STAGING_API_URL: 'http://staging.com:4190/api',
    SOCKET_URL: 'http://localhost:3007',
    GOOGLE_API_KEY: 'AIzaSyDOa2PRHCvDxZMRz8Q3xHiPbqmufWPXQww',
    v: '3.exp'
  };
}
