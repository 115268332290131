import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addQuestion, updateQuestion, getSubjects, getTopics, getLevels } from '../actions/admin';
import * as toast from '../actions/toast-actions';
import _ from 'underscore';
import AlertMsg from '../components/AlertMsg';
import loading from '../img/loading.gif';
import EditorJs from 'react-editor-js';
import { EDITOR_JS_TOOLS } from '../utilities/tools';


class AddQuestion extends Component {
  constructor(props) {
    super(props);
    const {questionId} = props.match.params;
    this.initialState = {
      flag: true,
      subject_id: '',
      topic_id: '',
      question: '',
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      answer: '',
      explanation: '',
      level: '',
      questionId: !_.isEmpty(questionId) ? atob(questionId) : null,
      isEditable: false,
      questionDataSaved: false,
      formTitle: 'Add Question',
      buttonText: 'Add'
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    let context = this;
    const {questionId} = this.state

    if(questionId){
      const questionData = _.findWhere(this.props.user.questionsData,{id:parseInt(questionId)});
      const editableState = {
                              subject_id: questionData.subject_id,
                              topic_id: questionData.topic_id,
                              question: JSON.parse(questionData.name),
                              option1: JSON.parse(questionData.option1_text),
                              option2: JSON.parse(questionData.option2_text),
                              option3: JSON.parse(questionData.option3_text),
                              option4: JSON.parse(questionData.option4_text),
                              answer: questionData.answer,
                              explanation: questionData.explanation,
                              level: questionData.level,
                              formTitle: 'Edit Question',
                              buttonText: 'Update',
                              isEditable: true
                            };
      this.setState(editableState);

    }
    let params = {
      token: context.props.user.loginToken
    };
    if(!this.props.user.subjectData.length){      
      context.props.getSubjects(params, res => { this.setState({ flag: true });});
    }
    if(!this.props.user.topicsData.length){      
      context.props.getTopics(params, res => { this.setState({ flag: true });});
    }
    if(!this.props.user.levelData.length){      
      context.props.getLevels(params, res => { this.setState({ flag: true });});
    }

  }

  handleSubmit = (event) => {
    event.preventDefault();
    const {questionId, isEditable, questionDataSaved} = this.state

    if(!isEditable && !questionDataSaved){
      toast.toastAction(false, 'you need to save Question first');
      return false;
    }
    this.setState({ flag: false });

    let questname = this.state.question;
    let question_short = questname.blocks[0].data.text;
    
    let formData = new FormData();
    formData.append('name', JSON.stringify(this.state.question));
    formData.append('question_short', question_short);
    formData.append('subject_id', this.state.subject_id);
    formData.append('topic_id', this.state.topic_id);
   // formData.append('question_image', this.state.question_image);
    formData.append('option1_text', JSON.stringify(this.state.option1));
  //  formData.append('option1_image', this.state.option1_image);
    formData.append('option2_text', JSON.stringify(this.state.option2));
  //  formData.append('option2_image', this.state.option2_image);
    formData.append('option3_text', JSON.stringify(this.state.option3));
  //  formData.append('option3_image', this.state.option3_image);
    formData.append('option4_text', JSON.stringify(this.state.option4));
  //  formData.append('option4_image', this.state.option4_image);
    formData.append('answer', this.state.answer);
  //  formData.append('answer_image', this.state.answer_image);
    formData.append('explanation', this.state.explanation);
    formData.append('level', this.state.level);

    let token = this.props.user.loginToken;
    if(isEditable){
      formData.append('id',questionId);
      this.props.updateQuestion(formData, token, res => {
        console.log(res);
        if (res.status) {
          this.setState({ flag: true });
          toast.toastAction(res.status, res.message);
          this.props.history.push('/questions');
  
        } else {
          toast.toastAction(res.status, res.message);
          this.setState({
            open: true,
            msg: res.message,
            msgType: res.type,
            msgStatus: res.status,
            flag: true
          });
        }
      });
    }else{

      this.props.addQuestion(formData, token, res => {
        console.log(res);
        if (res.status) {
          this.setState({ flag: true });
          toast.toastAction(res.status, res.message);
          this.props.history.push('/questions');
  
        } else {
          toast.toastAction(res.status, res.message);
          this.setState({
            open: true,
            msg: res.message,
            msgType: res.type,
            msgStatus: res.status,
            flag: true
          });
        }
      });
    }

  };

  handleChange = event => {
    console.log(event);
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  _onChange = event => {
    if (event.target.files && event.target.files[0]) {
      this.setState({ [event.target.id]: event.target.files[0] });
    };
  };

  resetState = () => {
    this.setState({
      flag: true,
      subject_id: '',
      topic_id: '',
      question: '',
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      answer: '',
      explanation: '',
      level: ''
    });
  }

  async onSave() {
    const outputData = await this.editorInstance.save();
    const outputData1 = await this.editorOption1Instance.save();
    const outputData2 = await this.editorOption2Instance.save();
    const outputData3 = await this.editorOption3Instance.save();
    const outputData4 = await this.editorOption4Instance.save();
    this.setState({ 
      question: outputData, 
      option1: outputData1, 
      option2: outputData2,
      option3: outputData3,
      option4: outputData4,
      questionDataSaved: true
    });
    toast.toastAction(true, 'Question info saved');
  }

  componentWillReceiveProps(props){   
    if(_.isEmpty(props.match.params)){
      this.setState(this.initialState);
    }
  }

  render() {
    const {formTitle, buttonText, question, option1, option2, option3, option4,
       subject_id, topic_id, answer, explanation, level, isEditable} = this.state

    /* let data = {
      time: 1556098174501,
      blocks: [
        {
          type: 'header',
          data: {
            text: 'Editor.js',
            level: 2
          }
        },
        {
          type: 'paragraph',
          data: {
            text:
              'Hey. Meet the new Editor. On this page you can see it in action — try to edit this text.'
          }
        },
        {
          type: 'header',
          data: {
            text: 'Key features',
            level: 3
          }
        },
        {
          type: 'list',
          data: {
            style: 'unordered',
            items: [
              'It is a block-styled editor',
              'It returns clean data output in JSON',
              'Designed to be extendable and pluggable with a simple API'
            ]
          }
        },
        {
          type: 'header',
          data: {
            text: 'What does it mean «block-styled editor»',
            level: 3
          }
        },
        {
          type: 'paragraph',
          data: {
            text:
              'Workspace in classic editors is made of a single contenteditable element, used to create different HTML markups. Editor.js <mark class="cdx-marker">workspace consists of separate Blocks: paragraphs, headings, images, lists, quotes, etc</mark>. Each of them is an independent contenteditable element (or more complex structure) provided by Plugin and united by Editor\'s Core.'
          }
        },
        {
          type: 'paragraph',
          data: {
            text:
              'There are dozens of <a href="https://github.com/editor-js">ready-to-use Blocks</a> and the <a href="https://editorjs.io/creating-a-block-tool">simple API</a> for creation any Block you need. For example, you can implement Blocks for Tweets, Instagram posts, surveys and polls, CTA-buttons and even games.'
          }
        },
        {
          type: 'header',
          data: {
            text: 'What does it mean clean data output',
            level: 3
          }
        },
        {
          type: 'paragraph',
          data: {
            text:
              'Classic WYSIWYG-editors produce raw HTML-markup with both content data and content appearance. On the contrary, Editor.js outputs JSON object with data of each Block. You can see an example below'
          }
        },
        {
          type: 'paragraph',
          data: {
            text:
              'Given data can be used as you want: render with HTML for <code class="inline-code">Web clients</code>, render natively for <code class="inline-code">mobile apps</code>, create markup for <code class="inline-code">Facebook Instant Articles</code> or <code class="inline-code">Google AMP</code>, generate an <code class="inline-code">audio version</code> and so on.'
          }
        },
        {
          type: 'paragraph',
          data: {
            text:
              'Clean data is useful to sanitize, validate and process on the backend.'
          }
        },
        {
          type: 'delimiter',
          data: {}
        },
        {
          type: 'paragraph',
          data: {
            text:
              'We have been working on this project more than three years. Several large media projects help us to test and debug the Editor, to make it\'s core more stable. At the same time we significantly improved the API. Now, it can be used to create any plugin for any task. Hope you enjoy. 😏'
          }
        },
        {
          type: 'image',
          data: {
            file: {
              url:
                'https://codex.so/upload/redactor_images/o_e48549d1855c7fc1807308dd14990126.jpg'
            },
            caption: '',
            withBorder: true,
            stretched: false,
            withBackground: false
          }
        }
      ],
      version: '2.12.4'
    }; */
    //let alfa = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$')

   console.log('questi..',question)
    return (


      <section className="main-content frontend" id="home">

        <section className="content">
          <div className="main-container">
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{formTitle}</h1>
              </div>
              <div className="row">
                {/* <div className="col-md-12 col-sm-12 driver-class1">
                  <button className='add-driver1' onClick={() => { this.props.history.goBack(); }}>Go Back</button>
                </div> */}
                <div className="mainouter col-xl-12">
                  {this.state.flag ?
                    <form onSubmit={this.handleSubmit}>
                      <div className="col-xl-12 col-lg-12 table-custom driver-table">
                        <div className="col-md-6 col-sm-12 add-rider-clm">
                          <div className="add-rider-outer">

                            <label>Question<span className='red'> *</span></label>
                            {/* <input type="text" name='question_text' placeholder="Enter Question" value={this.state.question_text} id='question_text' onChange={this.handleChange} required /> */}
                            <EditorJs data={question} instanceRef={instance => this.editorInstance = instance} tools={EDITOR_JS_TOOLS}  />

                            {/* <div id="editor-container"></div> */}

                            {/* <label>Question Image<span className='red'> *</span></label>
                            <input type="file" name='question_image' id='question_image' accept="image/*" onChange={this._onChange} /> */}

                            <label>Option 1<span className='red'> *</span></label>
                            <EditorJs data={option1} instanceRef={instance => this.editorOption1Instance = instance} tools={EDITOR_JS_TOOLS}  />
                            {/* <input type="text" name='option1_text' placeholder="Enter Option 1" value={this.state.option1_text} id='option1_text' onChange={this.handleChange} required />
                            <input type="file" name='option1_image' accept="image/*" id='option1_image' onChange={this._onChange} /> */}

                            <label>Option 2<span className='red'> *</span></label>
                            <EditorJs data={option2} instanceRef={instance => this.editorOption2Instance = instance} tools={EDITOR_JS_TOOLS}  />
                            {/* <input type="text" name='option2_text' placeholder="Enter Option 2" value={this.state.option2_text} id='option2_text' onChange={this.handleChange} required />
                            <input type="file" name='option2_image' accept="image/*" id='option2_image' onChange={this._onChange} /> */}

                            <label>Option 3<span className='red'> *</span></label>
                            <EditorJs data={option3} instanceRef={instance => this.editorOption3Instance = instance} tools={EDITOR_JS_TOOLS}  />
                            {/* <input type="text" name='option3_text' placeholder="Enter Option 3" value={this.state.option3_text} id='option3_text' onChange={this.handleChange} required />
                            <input type="file" name='option3_image' accept="image/*" id='option3_image' onChange={this._onChange} /> */}

                            <label>Option 4<span className='red'> *</span></label>
                            <EditorJs data={option4} instanceRef={instance => this.editorOption4Instance = instance} tools={EDITOR_JS_TOOLS}  />
                            {/* <input type="text" name='option4_text' placeholder="Enter Option 4" value={this.state.option4_text} id='option4_text' onChange={this.handleChange} required />
                            <input type="file" name='option4_image' accept="image/*" id='option4_image' onChange={this._onChange} /> */}
                            <div className="col-md-12 col-sm-12 driver-class1">
                              <button onClick={this.onSave.bind(this)} type="button">
                                  Save Content
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12 add-rider-clm">
                          <div className="add-rider-outer">
                            <label>Subjects<span className='red'> *</span></label>
                            <select name='subject_id' className='drop' value={this.state.subject_id} id='subject_id' onChange={this.handleChange} >
                              <option value="">Select Subject</option>
                              {this.props.user.subjectData.length > 0 && this.props.user.subjectData.map((e, key) => {
                                return <option key={key} value={e.id}>{e.name}</option>;
                              })}
                            </select>

                            <label>Topics<span className='red'> *</span></label>
                            <select name='topic_id' className='drop' value={this.state.topic_id} id='topic_id' onChange={this.handleChange} >
                              <option value="">Select Topic</option>
                              {this.props.user.topicsData.length > 0 && this.props.user.topicsData.map((e, key) => {
                                return <option key={key} value={e.id}>{e.name}</option>;
                              })}
                            </select>

                            <label>Answer<span className='red'> *</span></label>
                            <input type="text" name='answer' placeholder="Enter Answer" value={this.state.answer} id='answer' onChange={this.handleChange} required />
                            {/* <input type="file" name='answer_image' placeholder="Enter Answer" accept="image/*" id='answer_image' onChange={this._onChange} /> */}

                            <label>Explanation<span className='red'> *</span></label>
                            <textarea type="text" name='explanation' placeholder="Enter Explanation" value={this.state.explanation} id='explanation' onChange={this.handleChange}></textarea>

                            <label>Level<span className='red'> *</span></label>
                            <select name='level' className='drop' id='level' onChange={this.handleChange} value={level}>
                              <option value="">Select Level</option>
                              {this.props.user.levelData.length > 0 && this.props.user.levelData.map((e, key) => {
                                return <option key={key} value={e.id}>{e.name}</option>;
                              })}
                              {/* <option value="primary">Primary</option>
                              <option value="secondary">Secondary</option>
                              <option value="tertiary">Tertiary</option> */}
                            </select>

                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12 rider-btns">
                          <button className='add-driver1' type='submit'>{buttonText}</button>
                          {!isEditable &&<button className='add-driver1' type='reset' onClick={this.resetState}>Reset</button>}
                        </div>
                      </div>
                    </form>
                    : (
                      <img src={loading} className="loading" />
                    )}

                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  addQuestion: bindActionCreators(addQuestion, dispatch),
  updateQuestion: bindActionCreators(updateQuestion, dispatch),
  getSubjects: bindActionCreators(getSubjects, dispatch),
  getTopics: bindActionCreators(getTopics, dispatch),
  getLevels: bindActionCreators(getLevels, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);