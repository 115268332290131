import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { verification } from '../actions/admin';
import AlertMsg from '../components/AlertMsg';


class OtpVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      msg: '',
      msgType: '',
      msgStatus: false
    };
    this.otpVerification = this.otpVerification.bind(this);
  }

  otpVerification(event){
    event.preventDefault();
    let otp = document.getElementById('otp').value;
    let data={otp:parseInt(otp), token: this.props.user.token };
    if(otp !== ''){
       this.props.verification(data, res => {
        if (res.status) {
         this.props.history.replace('/resetpassword');
        } else {
          this.setState({
            open: true,
            msg: res.message,
            msgType: res.type,
            msgStatus: res.status
          });
        }
      });
    }
    else{
      this.setState({
        open: true,
        msg: 'This field can not be empty',
        msgType: 'error',
        msgStatus: false
      });

    }
  };

  render() {
    return (
      <div className="bg-gradient-primary custom-padding">
  <div className="container">
    <AlertMsg
      onPress={() => this.setState({ open: false })}
      isShowingModal={this.state.open}
      msg={this.state.msg}
      type={this.state.msgType}
      status={this.state.msgStatus}
    />
<div className="row justify-content-center">

  <div className="col-xl-10 col-lg-12 col-md-9">

    <div class="card o-hidden border-0 shadow-lg my-5">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
          <div class="col-lg-6">
            <div class="p-5 forgot-password">
              <div class="text-center">
                <h1 class="h4 text-gray-900 mb-4">Otp Verification </h1>
              </div>
             
            <form className="user" onSubmit={this.otpVerification}>
            <div className="form-group">
              <input type="string" className="form-control form-control-user"  id="otp" aria-describedby="emailHelp" placeholder="Enter Otp.."/>
            </div>
            <button type='submit' className="btn btn-primary btn-user btn-block" >
              Reset
            </button>
            </form>
              <hr/>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</div>
</div>

    );
  }
}



const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
    verification: bindActionCreators(verification, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(OtpVerification);