/*
 * @file: booking.js
 * @description: Reducers and actions for store/manipulate booking's  data
 * @date: 5.7.2019
 * @author: Megha Sethi
*/
import _ from 'underscore';
import * as TYPE from '../../constants/actionTypes/booking';
import { bookingData } from '../../actions/booking';

/******** Reducers ********/

const initialState = {
  reportAbuse :[],
  bookingData: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.REPORT_ABUSE:
        return { ...state, ...{reportAbuse: action.data} };
    case TYPE.BOOK_DATA:
      return { ...state, ...{bookingData: action.data} };
    case TYPE.REMOVE_ABUSE:
        let _reportAbuse = state.reportAbuse;
        let _reportAbuseIndex = _.findIndex(_reportAbuse, { bookingId: action.data._id });
        _reportAbuse.splice(_reportAbuseIndex, 1);
        return { ...state, reportAbuse: _reportAbuse };
    default:
        return state;
  }
}
