import React, { Component } from 'react';
import Table from '../components/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPaymentData } from '../actions/admin';
//import * as toast from '../actions/toast-actions';
import _ from 'underscore';
import loading from '../img/loading.gif';

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      earing: 0,
      flag:false
    };
  }

  componentDidMount() {
    let context = this;
    let params = {
      token: context.props.user.loginToken
    };
    context.props.getPaymentData(params, res => {
      this.setState({flag: true});});
  }

  render() {
    const columns =[
      {
        Header: 'Booking No',
        accessor: 'bookingNumber'
      },
      {
        Header: 'Driver Name',
        accessor: 'driverName'
      },
      {
        Header: 'Rider Name',
        accessor: 'riderName'
      },
      {
        Header: 'Trip date',
        accessor: 'createdAt'
      },
      {
        Header: 'Total Fare',
        accessor: 'totalCost'
      },
      {
        Header: 'Tip',
        accessor: 'tip'
      },
      {
        Header: 'Ride status',
        accessor: 'rideStatus',
        Cell: props => (  
          <i className={props.original.rideStatus == 1 ? "fa fa-check custom-check": props.original.rideStatus == 2 ? "fa fa-times custom-times" : props.original.rideStatus == 3 ? "fa fa-check custom-check" : "fa fa-clock-o" } aria-hidden="true" ></i>
          )
      },
      {
        Header: 'Payment Method',
        accessor: 'paymentMethod'
      }
    ];
    let userData = this.props.user.paymentData;

    return (
      <section className="main-content frontend" id="home">
      <section className="content">
      <div className="main-container">
      <div className="container-fluid">

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Payment Listing</h1>
      </div>
      <div className="row">
      <div className="col-xl-12 col-lg-12 table-custom">
      { this.state.flag ? <Table data={ userData } columns={ columns } tableType={ 'payment' } fileName={'Payment List'}  />: (
                  <img src={loading} className="loading" />
                ) }
        </div>
    </div>
     </div>
  </div>
</section>
    </section>

    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  getPaymentData: bindActionCreators(getPaymentData, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Payment);
