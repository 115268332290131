import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getSearchData, getDriverData, getDriverdata, getRatingdata, getUserRating, getCardata, getCarsData, getPaymentData, getPaymentdata, getRequestdata, getRequestsData } from '../actions/admin';
import { reportAbuse, getReportAbuse, getBookingData, bookingData } from '../actions/booking';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import XLSX from 'xlsx';

//import { bindActionCreators } from 'redux';

class Table extends Component {
  constructor() {
    super();
    this.state = {
      search: '',
      data: []
    };
    this.printTable = this.printTable.bind(this);
    //this.savePdf = this.savePdf.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
    this.resetFields = this.resetFields.bind(this);
  }
  /**Print Table component */
  printTable = () => {
    window.print();
  };

  savePdf = () => {
    const input =
      document.getElementsByClassName('react-table') &&
      document.getElementsByClassName('react-table')[0];
    if (input) {
      /**Parse HTML to canvas */
      html2canvas(input).then(canvas => {
        /**Get Image from canvas */
        const imgData = canvas.toDataURL('image/png');
        /**Create new pdf obect and set configurations as in landscape mode */
        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'in'
          //format: [4,2]
        });
        let width = pdf.internal.pageSize.getWidth();
        let height = pdf.internal.pageSize.getHeight();
        //pdf.addImage(imgData, 'JPEG', 0,0, width, height);

        /**Add image in pdf object and set parameters */
        pdf.addImage(imgData, 'JPEG', 0, 0);
        /**Dowanload pdf */
        pdf.save('download.pdf');
      });
    }
  };

  /**Export Table component as Excel */
  exportExcel = (columns, data, tableType, fileName) => {
    let tableData = [],
      tableHeader = [];
    //console.log('====colu', tableType)
    columns.forEach(column => {
      //let userArray = [column.serialNumber, user.refrenceNumber, user.waitTime]
      if (column.Header !== 'Action' && column.Header !== 'Documents') {
        tableHeader.push(column.Header);
      }
    });

    tableData.push(tableHeader);
    if (data !== undefined) {
      data.forEach(rowData => {
        let tableRow = [];
        if (tableType == 'drivers' || tableType == 'riders' || tableType == 'drivers1') {
          if (tableType == 'drivers') {
            tableRow = [
              rowData.fullName,
              rowData.email,
              tableType == 'drivers' ? rowData.taxiCount = 1 : '',
              rowData.completePhoneNumber,
              rowData.rating,
              rowData.status
            ];
          } else {
            tableRow = [
              rowData.fullName,
              rowData.email,
              rowData.completePhoneNumber,
              rowData.rating,
              rowData.status
            ];
          }

        } else if (tableType == 'rides' || tableType == 'rides2') {
          tableRow = [
            rowData.bookingNumber,
            rowData.userName,
            rowData.driverName,
            rowData.totalCost,
            rowData.status,
            //rowData.status == rowData.status,
            rowData.createdAt,
          ];
        } else if (tableType == 'schedule') {
          tableRow = [
            rowData.bookingNumber,
            rowData.userName,
            rowData.totalFare,
            rowData.status,
            rowData.createdAt,
          ];
        } else if (tableType == 'fairMange') {
          tableRow = [
            rowData.bookingNumber,
            rowData.driverName,
            rowData.userName,
            rowData.createdAt,
            rowData.totalFare,
            rowData.tip,
            rowData.rideStatus
          ];
        } else if (tableType == 'reviews') {
          tableRow = [
            rowData.bookingNumber,
            rowData.avgDriver,
            rowData.avgUser,
            rowData.ratedByDriver,
            rowData.createdAt,
          ];
        } else if (tableType == 'cars') {
          tableRow = [
            rowData.name,
            rowData.perMileFairAmount,
            rowData.baseFair,
            rowData.commission,
            rowData.passengerCapacity,
            rowData.activeStatus
          ];
        } else if (tableType == 'abuse') {
          tableRow = [
            rowData.bookingNumber,
            rowData.createdByName,
            rowData.driverName,
            rowData.isReportedByCustomer,
            rowData.reportedReasonByCustomer,
            rowData.isReportedByDriver,
            rowData.reportedReasonByDriver
          ];
        } else if (tableType == 'pages') {
          tableRow = [
            rowData.title,
            rowData.description
          ];
        } else if (tableType == 'requests') {
          tableRow = [
            rowData.name,
            rowData.phone,
            rowData.email,
            rowData.message,
            rowData.createdAt
          ];
        } else if (tableType == 'webrequests') {
          tableRow = [
            rowData.name,
            rowData.phone,
            rowData.passengers,
            rowData.cartype,
            rowData.date,
            rowData.time
          ];
        }
        tableData.push(tableRow);
      });
    }

    const wb = XLSX.utils.book_new();
    const wsAll = XLSX.utils.aoa_to_sheet(tableData);
    XLSX.utils.book_append_sheet(wb, wsAll, tableType);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  resetFields = (table) => {
    if (table == 'abuse') {
      //console.log(this.props, '=====prorp');
      document.getElementById('search-bar').value = '';
      let context = this;
      let params = {
        token: context.props.user.loginToken
      };
      context.props.getReportAbuse(params, res => { });
    }
    else if (table == 'drivers' || table == 'riders' || table == 'drivers1') {
      //console.log(this.props, '=====prorp');
      if (document.getElementById('status')) {
        document.getElementById('status').value = 'select';
      }
      document.getElementById('search-bar').value = '';
      //document.getElementById('status').value = 'select';
      document.getElementById('some-op').value = 'select';
      let context = this;
      let params = {
        token: context.props.user.loginToken
      };
      context.props.getDriverData(params, res => { });
    }
    else if (table == 'rides' || table == 'rides2') {
      //console.log(this.props, '=====prorp');
      if (document.getElementById('status')) {
        document.getElementById('status').value = 'select';
      }
      document.getElementById('search-bar').value = '';
      document.getElementById('some-op').value = 'select';
      let context = this;
      let params = {
        type: 0,
        token: context.props.user.loginToken
      };
      context.props.getBookingData(params, res => { });
    }
    else if (table == 'schedule') {
      //console.log(this.props, '=====prorp');
      document.getElementById('search-bar').value = '';
      document.getElementById('some-op').value = 'select';
      let context = this;
      let params = {
        type: 1,
        token: context.props.user.loginToken
      };
      context.props.getBookingData(params, res => { });
    }
    else if (table == 'reviews') {
      //console.log(this.props, '=====prorp');
      document.getElementById('search-bar').value = '';
      document.getElementById('some-op').value = 'select';
      let context = this;
      let params = {
        token: context.props.user.loginToken,
        type: 1
      };
      context.props.getUserRating(params, res => { });
    }
    else if (table == 'cars') {
      //console.log(this.props, '=====prorp');
      document.getElementById('search-bar').value = '';
      document.getElementById('some-op').value = 'select';
      document.getElementById('status').value = 'select';
      let context = this;
      let params = {
        token: context.props.user.loginToken
      };
      context.props.getCarsData(params, res => { });
    }
    else if (table == 'payment') {
      //console.log(this.props, '=====prorp');
      document.getElementById('search-bar').value = '';
      document.getElementById('some-op').value = 'select';
      let context = this;
      let params = {
        token: context.props.user.loginToken
      };
      context.props.getPaymentData(params, res => { });

    }
    else if (table == 'requests' || table == 'webrequests') {
      //console.log(this.props, '=====prorp');
      let type = table == 'requests' ? 1 : 2;
      document.getElementById('search-bar').value = '';
      document.getElementById('some-op').value = 'select';
      let context = this;
      let params = {
        token: context.props.user.loginToken,
        type: type
      };
      context.props.getRequestsData(params, res => { });
    }
  }

  onSearch = (e) => {
    let key1 = document.getElementById('search-bar').value;
    if (e) {
      let code = e.keyCode || e.which;
      if (code === 13) {
        this.searchInside(key1);
      }
    } else {
      this.searchInside(key1);
    }
  }

  searchInside = (key1) => {
    let params;
    let context = this;
    let table = context.props.tableType;
    key1 = key1.length ? key1 : 'empty';
    if (table == 'abuse') {
      params = {
        type: table,
        key1: key1,
        token: context.props.user.loginToken
      };
    } else if (table == 'drivers' || table == 'riders' || table == 'cars' || table == 'drivers1') {
      let k = '3';
      if (document.getElementById('status')) {
        k = document.getElementById('status').value;
      }

      let filter = document.getElementById('some-op').value;
      params = {
        type: table,
        key1: key1,
        key2: k,
        filter: filter,
        token: context.props.user.loginToken
      };
    }
    else if (table == 'rides' || 'reviews' || 'payment' || 'requests' || 'webrequests') {
      let k = 'empty';
      if (document.getElementById('status')) {
        k = document.getElementById('status').value;
      }
      let filter = document.getElementById('some-op').value;
      params = {
        type: table,
        key1: key1,
        key2: k,
        filter: filter,
        token: context.props.user.loginToken
      };
    }
    context.props.getSearchData(params, res => {
      //console.log('====+++d', res, table);
      if (res.status) {
        if (table == 'abuse') {
          context.props.reportAbuse(res.data);
        }
        else if (table == 'drivers' || table == 'riders' || table == 'drivers1') {
          context.props.getDriverdata(res.data);
        }
        else if (table == 'rides' || table == 'rides2' || table == 'schedule') {
          context.props.bookingData(res.data);
        }
        else if (table == 'reviews') {
          context.props.getRatingdata(res.data);
        }
        else if (table == 'cars') {
          context.props.getCardata(res.data);
        }
        else if (table == 'payment') {
          context.props.getPaymentdata(res.data);
        }
        else if (table == 'requests' || table == 'webrequests') {
          context.props.getRequestdata(res.data);
        }

      }
    });
  }

  // componentDidMount(){
  //   this.setState({data: this.props.data })
  // }

  render() {
    let context = this.props;
    let data = this.props.data;

    return (
    //   <div>
    //     {context.tableType != 'pages' ?
    //       <div className="no-print">
    //         <div className="btn-group drop-button pull-right">
    //           <button className="btn btn-default dropdown-toggle" data-toggle="dropdown">
    //             {' '}
    //             <i className="fa fa-cog" aria-hidden="true" />
    //           </button>
    //           <ul className="dropdown-menu pull-right">
    //             <li>
    //               <a href="javascript:void(0);" onClick={() => this.printTable()}>
    //                 Print
    //               </a>
    //             </li>
    //             <li>
    //               <a
    //                 href="javascript:void(0);"
    //                 onClick={() =>
    //                   this.exportExcel(context.columns, data, context.tableType, context.fileName)
    //                 }
    //               >
    //                 Export to Excel
    //               </a>
    //             </li>
    //             <li>
    //               <a href="javascript:void(0);"
    //                 onClick={() =>
    //                   this.savePdf()
    //                 }>
    //                 Save as PDF
    //               </a>
    //             </li>
    //           </ul>
    //         </div>
    //       </div>
    //       : ''}

    //     {context.tableType != 'pages' ?
    //       <div className="no-print">
    //         <div className="search-div">
    //           <span>Search</span>
    //           {context.tableType == 'riders' || context.tableType == 'drivers' || context.tableType == 'drivers1' ?
    //             <select id='some-op'>
    //               <option value="select">All</option>
    //               <option value='name'>Name</option>
    //               <option value='mobile'>Mobile Number</option>
    //               <option value='email'>Email</option>
    //             </select> : context.tableType == 'cars' ? <select id='some-op'>
    //               <option value="select">All</option>
    //               <option value='name'>Type</option>
    //               <option value='pmfa'>Price Per Mile</option>
    //               <option value='baseFair'>Base Fare</option>
    //               <option value='pc'>Person Capacity </option>
    //             </select> : context.tableType == 'rides' || context.tableType == 'rides2' || context.tableType == 'payment' ? <select id='some-op'>
    //               <option value="select">All</option>
    //               <option value='bK'>Booking Number</option>
    //               <option value='dName'>Driver Name</option>
    //               <option value='rName'>Rider Name</option>

    //             </select> : context.tableType == 'schedule' ? <select id='some-op'>
    //               <option value="select">All</option>
    //               <option value='bK'>Booking Number</option>
    //               <option value='rName'>Rider Name</option>
    //             </select> : context.tableType == 'requests' ? <select id='some-op'>
    //               <option value="select">All</option>
    //               <option value='name'> Name</option>
    //               <option value='email'>Email</option>
    //               <option value='phone'>Phone Number</option>
    //             </select> : context.tableType == 'webrequests' ? <select id='some-op'>
    //               <option value="select">All</option>
    //               <option value='name'> Name</option>
    //               <option value='car'>Car Type</option>
    //               <option value='phone'>Phone Number</option>
    //             </select> : ''
    //           }
    //           <input id='search-bar'
    //             onKeyPress={(e) => { this.onSearch(e); }}
    //           // value={this.state.search}
    //           // onChange={e => this.setState({ search: e.target.value })}

    //           />
    //           {context.tableType == 'riders' || context.tableType == 'drivers' ?
    //             <select id='status'>
    //               <option value="select">All Status</option>
    //               <option value='1'>Accepted</option>
    //               <option value='0'>Pending</option>
    //               <option value='2'>Rejected</option>
    //               <option value='3'>Block</option>
    //             </select> : context.tableType == 'rides' ?
    //               <select id='status'>
    //                 <option value="select">All Status</option>
    //                 <option value='3'>Completed</option>
    //                 <option value='1'>Ongoing Ride</option>
    //                 <option value='6'>Cancelled</option>
    //               </select>
    //               : context.tableType == 'reviews' ?
    //                 <select id='some-op'>
    //                   <option value="select">All</option>
    //                   <option value='bk'>Booking Number</option>
    //                   <option value='dName'>Driver Name</option>
    //                   <option value='rName'>Rider Name</option>
    //                 </select> : context.tableType == 'cars' ?
    //                   <select id='status'>
    //                     <option value="select">Select Status</option>
    //                     <option value='active'>Active</option>
    //                     <option value='deactive'>Inactive</option>
    //                   </select> : ''}
    //           <input type="submit" value="Search" className="btnalt button11" id="Search" name="Search" title="Search" onClick={() => { this.onSearch(); }}></input>
    //           <input type="button" value="Reset" className="btnalt button11" onClick={(e) => { this.resetFields(context.tableType); }}></input>
    //         </div>
    //       </div>
    //       : ''}

    //     {/* <div className="no-print">
    //     <div class="search-div">
    //       <span>Search</span>
    //       <input
    //         value={this.state.search}
    //         onChange={e => this.setState({ search: e.target.value })}
    //       />
    //       </div>
    //     </div> */}

        <ReactTable
          className={'highlight'}
          loading={false}
          data={data}
          //showPaginationTop = {true}
          //collapseOnSortingChange={true}
          columns={context.columns}
          defaultPageSize={10}
          pageSizeOptions={[3, 10, 25, 50, 100]}
          minRows={3}
          //filterable = {true}
          className="table table-striped  table-bordered react-table"
          style={{ width: '100%', textAlign: 'center' }}
          getTheadProps={() => {
            /** getTheadProps is function that returns props to decorate all the table header elements of the columns */
            return {
              style: {
                background: '#200f5a',
                color: '#fff'
              }
            };

          }}
          previousText={<i className="fa fa-angle-double-left fa-size"></i>}
          nextText={<i className="fa fa-angle-double-right fa-size"></i>}
        />
    //   </div>
     );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  getSearchData: bindActionCreators(getSearchData, dispatch),
  reportAbuse: bindActionCreators(reportAbuse, dispatch),
  getReportAbuse: bindActionCreators(getReportAbuse, dispatch),
  getDriverData: bindActionCreators(getDriverData, dispatch),
  getDriverdata: bindActionCreators(getDriverdata, dispatch),
  bookingData: bindActionCreators(bookingData, dispatch),
  getBookingData: bindActionCreators(getBookingData, dispatch),
  getRatingdata: bindActionCreators(getRatingdata, dispatch),
  getUserRating: bindActionCreators(getUserRating, dispatch),
  getCardata: bindActionCreators(getCardata, dispatch),
  getCarsData: bindActionCreators(getCarsData, dispatch),
  getPaymentdata: bindActionCreators(getPaymentdata, dispatch),
  getPaymentData: bindActionCreators(getPaymentData, dispatch),
  getRequestdata: bindActionCreators(getRequestdata, dispatch),
  getRequestsData: bindActionCreators(getRequestsData, dispatch)

});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
