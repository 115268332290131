import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addLevel, getLevel, updateLevel } from '../actions/admin';
import * as toast from '../actions/toast-actions';
import _ from 'underscore';
import AlertMsg from '../components/AlertMsg';
import loading from '../img/loading.gif';

class AddLevel extends Component {
  constructor(props) {
    super(props);
    const { levelID} = props.match.params;
    this.initialState = {
      formTitle: 'Add Level',
      buttontext: 'Add',
      flag: true,
      name: '',      
      levelID: levelID ? atob(levelID) : null, 
      isEditable: false    
    };
    this.state = this.initialState;  
  }

  componentDidMount(){
    const {levelID} = this.state;
    if(levelID){
      
      this.setState({
        formTitle: 'Edit Level',
        buttontext: 'Update',
        isEditable: true
      });

      let token = this.props.user.loginToken;
      this.props.getLevel({token:token, level_id:levelID},(res)=>{
        if(res.status){
          this.setState({name: res.data.name});
          console.log('Level Info',res);
        }
      });

    }
  }

  handelSubmit = (event) => {
    event.preventDefault();
    const {isEditable,levelID} = this.state;
    this.setState({ flag: false });

    let token = this.props.user.loginToken;

    if(isEditable){
      this.props.updateLevel({id: levelID, name: this.state.name}, token, res => {
        console.log(res);
        if (res.status) {
          this.setState({ flag: true });
          toast.toastAction(res.status, res.message);
          setTimeout(() => {
            this.props.history.push('/levels');
          }, 3000);
        } else {
          toast.toastAction(res.status, res.message);
          this.setState({
            open: true,
            msg: res.message,
            msgType: res.type,
            msgStatus: res.status,
            flag: true
          });
        }
      });
    }else{
      this.props.addLevel({name: this.state.name}, token, res => {
        console.log(res);
        if (res.status) {
          this.setState({ flag: true });
          toast.toastAction(res.status, res.message);
          setTimeout(() => {
            this.props.history.push('/levels');
          }, 3000);
        } else {
          toast.toastAction(res.status, res.message);
          this.setState({
            open: true,
            msg: res.message,
            msgType: res.type,
            msgStatus: res.status,
            flag: true
          });
        }
      });
    }

  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  resetState = () => {
    this.setState({
      flag: true,
      name: '',
    });
  }

  componentWillReceiveProps(props){   
    if(_.isEmpty(props.match.params)){
      this.setState(this.initialState);
    }
  }

  render() {
    const {formTitle, buttontext, name, isEditable} = this.state;
    //let alfa = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$')
    return (
      <section className="main-content frontend" id="home">
        <section className="content">
          <div className="main-container">
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{formTitle}</h1>
              </div>
              <div className="row">
                {/* <div className="col-md-12 col-sm-12 driver-class1">
                  <button className='add-driver1' onClick={() => { this.props.history.goBack(); }}>Go Back</button>
                </div> */}
                <div className="mainouter col-xl-12">
                  {this.state.flag ?
                    <form onSubmit={this.handelSubmit}>
                      <div className="col-xl-12 col-lg-12 table-custom driver-table">
                        <div className="col-md-6 offset-md-3 col-sm-12 add-rider-clm">
                          <div className="add-rider-outer">
                            <label>Level Name<span className='red'> *</span></label>
                            <input type="text" name='name' placeholder="Enter Level Name" value={name} id='name' onChange={this.handleChange} required />
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12 rider-btns">
                          <button className='add-driver1' type='submit' >{buttontext}</button>
                          {!isEditable && <button className='add-driver1' type='reset' onClick={this.resetState}>Reset</button>}
                        </div>
                      </div>
                    </form>
                    : (
                      <img src={loading} className="loading" />
                    )}

                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
    addLevel: bindActionCreators(addLevel, dispatch),
    getLevel: bindActionCreators(getLevel, dispatch),
    updateLevel: bindActionCreators(updateLevel, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddLevel);