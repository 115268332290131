import React, { Component } from 'react';
import Table from '../components/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeStatus, getDriverData, getDriverdata } from '../actions/admin';
import * as toast from '../actions/toast-actions';
import _ from 'underscore';
import loading from '../img/loading.gif';

class Riders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: false
    };
  }

  componentDidMount() {
    let context = this;
    let params = {
      token: context.props.user.loginToken
    };
    context.props.getDriverData(params, res => { this.setState({ flag: true }); });
  }

  changeStatus = (sta, id) => {
    if (sta !== 'Select') {
      let data = { userId: id, token: this.props.user.loginToken, status: parseInt(sta) };
      this.props.changeStatus(data, res => {
        if (res.status) {
          toast.toastAction(res.status, res.message);
          let updateStatus = _.findWhere(this.props.user.driverData, { userId: id });
          updateStatus = { ...updateStatus, status: parseInt(sta) };
          let index = this.props.user.driverData.findIndex(item => item.userId === id);
          this.props.user.driverData.splice(index, 1, updateStatus);
          this.props.getDriverdata(this.props.user.driverData);
        } else {
          toast.toastAction(res.status, res.message);
        }
      });
    }
  };

  render() {
    const columns = [
      {
        Header: 'Full Name',
        accessor: 'fullName'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Mobile',
        accessor: 'completePhoneNumber'
      },
      {
        Header: 'Rating',
        accessor: 'rating'
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: props => (
          <i className={props.original.status == 1 ? 'fa fa-check custom-check' : props.original.status == 2 ? 'fa fa-times custom-times' : props.original.status == 3 ? 'fa fa-ban custom-ban' : 'fa fa-clock-o'} aria-hidden="true" ></i>
        )
      },
      {
        Header: 'Action',
        accessor: 'action',
        sortable: false,
        filterable: false,
        Cell: props => (
          <select
            id="chan"
            onClick={e => {
              this.changeStatus(e.target.value, props.original.userId);
            }}
          >
            <option value="Select">Select Status</option>
            {props.original.status == 1 ? <option value='3'>Block</option> : <option value='1'>Approve</option>}
            {props.original.status != 2 ? <option value='2'>Reject</option> : ''}
          </select>
        )
      }
    ];

    let userData = this.props.user.driverData.filter(
      item => (item.userActiveRole == 0 && item.status != 3));

    return (
      <section className="main-content frontend" id="home">
        <section className="content">
          <div className="main-container">
            <div className="container-fluid">

              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Parents</h1>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12 table-custom">
                  {this.state.flag ? <Table data={userData} columns={columns} tableType={'riders'} fileName={'Riders'} /> : (
                    <img src={loading} className="loading" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  changeStatus: bindActionCreators(changeStatus, dispatch),
  getDriverData: bindActionCreators(getDriverData, dispatch),
  getDriverdata: bindActionCreators(getDriverdata, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Riders);
