import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { logOut, getDriverData, setCommission } from '../actions/admin';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap';
import logo from '../img/logo.png';
import profile from '../img/profile.jpg';
//import 'jquery'

class Header extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      active: 0
    };
  }
  
  /**************  logout function ***************/
  logout(data) {
    let context = this;
    let _history = context.props.children;
    let logoutObj = {
      token: data.loginToken,
    };
    context.props.logOut(logoutObj, res=> {
      if (res.status) {
        //window.location.replace('/');
        _history.props.history.replace('/');
      } else {
        _history.props.history.replace('/');
      }
    });
  }


  tables = (num) => {
    if(num == 1) {
       //window.location.replace('/riders');
       this.props.children.props.history.push('/parents');
    }
    if(num == 2) {
      this.props.children.props.history.push('/all-users');
    }
  }

  activeClass = (r,r1,r2,r3,r4,r5,r6,r7) => {
    let path = this.props.children.props.location.pathname;
    path = path.startsWith('/doc') ? '/'+path.split('/')[3] : path.startsWith('/driver') ? '/'+path.split('/')[1] :path.startsWith('/rides') ? '/'+path.split('/')[1]  : path;
    if(path == r || path == r1 || path == r2 || path == r3 || path == r4 || path == r5 || path == r6 || path == r7){
      return 'nav-item active';
    }else{
      return 'nav-item';
    }
  }

  showDropdown = (s,r, r2, r3, r4,r5, r6) => {
    let path = this.props.children.props.location.pathname;
    path = path.startsWith('/rides') ? '/'+path.split('/')[1] : path.startsWith('/documents') ? '/'+path.split('/')[3] : path; 
    if(path == r || path == r2 || path == r3 || path == r4 || path == r5 || path == r6){
      return  s == 1 ? 'collapse show' : 'collapse-item active2';
    }else{
      return s == 1 ?'collapse' : 'collapse-item';
    }
  }
render() {
  return (
    <div id="wrapper">
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion sidebar-custom1" id="accordionSidebar">

    {/* <!-- Sidebar - Brand --> */}
    <a className="sidebar-brand d-flex align-items-center " onClick={()=>{this.props.children.props.history.push('/');}} >
      <div className="sidebar-brand-icon ">
        <img className='dash-logo' src={logo} />
      </div>
    </a>

    {/* <!-- Divider --> */}
    <hr className="sidebar-divider my-0" />

    {/* <!-- Nav Item - Dashboard --> */}
    <li className={this.activeClass('/dashboard', '/')}>
      <a className="nav-link" onClick={()=>{this.props.children.props.history.push('/');}}>
        <i className="fas fa-fw fa-tachometer-alt"></i>
        <span>Dashboard</span></a>
    </li>

    {/* <!-- Divider --> */}
    <hr className="sidebar-divider" />

    <li className={this.activeClass('/all-users', '/parents', '/driver/adduser', '/vehicle', '/docs', '/blockedusers','/drivers/adduser')}>
      <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages" aria-controls="collapsePages">
        <i className="fa fa-users" aria-hidden="true"></i>
        <span>Users Management</span>
      </a>
      <div id="collapsePages" className={this.showDropdown(1,'/all-users','/parents', '/blockedusers', '/drivers/adduser','/docs', '/vehicle' )} aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
          <a className={this.showDropdown(0,'/all-users','/drivers/adduser','/docs','/vehicle'  )} onClick={()=>{this.tables(2);}} >All Users</a>
          {/* <a className={this.showDropdown(0,'/parents')} onClick={()=>{this.tables(1);}} >Parents</a> */}
          {/* <a  className={this.showDropdown(0,'/blockedusers')} onClick={()=>{this.props.children.props.history.push('/blockedusers');}} >Blocked Users</a> */}
        </div>
      </div>
    </li>
    <li className={this.activeClass('/subjects', '/subjects/add')}>
      <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages1" aria-controls="collapsePages">
        <i className="fa fa-user" aria-hidden="true"></i>
        <span>Subjects</span>
      </a>
      <div id="collapsePages1" className={this.showDropdown(1,'/subjects', '/subjects/add')}  aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
          <div className="collapse-divider"></div>
          <a className={this.showDropdown(0,'/subjects' )} onClick={()=>{this.props.children.props.history.push('/subjects');}} >All Subjects</a>
          <a className={this.showDropdown(0,'/subjects/addsubject')} onClick={()=>{this.props.children.props.history.push('/subjects/addsubject');}} >Add Subject</a>
          {/* <a className={this.showDropdown(0,'/abuse/rides')} onClick={()=>{this.props.children.props.history.push('/abuse/rides');}} >Abuse Reports</a> */}

        </div>
      </div>
    </li>
    <li className={this.activeClass('/topics', '/topics/addtopic')}>
      <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages2" aria-controls="collapsePages">
        <i className="fa fa-user" aria-hidden="true"></i>
        <span>Topics</span>
      </a>
      <div id="collapsePages2" className={this.showDropdown(1,'/topics', '/topics/addtopic')}  aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
          <div className="collapse-divider"></div>
          <a className={this.showDropdown(0,'/topics' )} onClick={()=>{this.props.children.props.history.push('/topics');}} >All Topics</a>
          <a className={this.showDropdown(0,'/topics/addtopic')} onClick={()=>{this.props.children.props.history.push('/topics/addtopic');}} >Add Topic</a>
          {/* <a className={this.showDropdown(0,'/abuse/rides')} onClick={()=>{this.props.children.props.history.push('/abuse/rides');}} >Abuse Reports</a> */}

        </div>
      </div>
    </li>
    <li className={this.activeClass('/questions', '/questions/add-question')}>
      <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages3" aria-controls="collapsePages">
        <i className="fa fa-user" aria-hidden="true"></i>
        <span>Questions</span>
      </a>
      <div id="collapsePages3" className={this.showDropdown(1,'/questions', '/questions/add-question')}  aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
          <div className="collapse-divider"></div>
          <a className={this.showDropdown(0,'/questions','/questions/edit-question/:questionId' )} onClick={()=>{this.props.children.props.history.push('/questions');}} >All Questions</a>
          <a className={this.showDropdown(0,'/questions/add-question')} onClick={()=>{this.props.children.props.history.push('/questions/add-question');}} >Add Question</a>
          {/* <a className={this.showDropdown(0,'/abuse/rides')} onClick={()=>{this.props.children.props.history.push('/abuse/rides');}} >Abuse Reports</a> */}

        </div>
      </div>
    </li>
    <li className={this.activeClass('/levels', '/levels/add-level')}>
      <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages4" aria-controls="collapsePages">
        <i className="fa fa-user" aria-hidden="true"></i>
        <span>Levels</span>
      </a>
      <div id="collapsePages4" className={this.showDropdown(1,'/levels', '/levels/add-level')}  aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
          <div className="collapse-divider"></div>
          <a className={this.showDropdown(0,'/levels' )} onClick={()=>{this.props.children.props.history.push('/levels');}} >All Levels</a>
          <a className={this.showDropdown(0,'/levels/add-level')} onClick={()=>{this.props.children.props.history.push('/levels/add-level');}} >Add Level</a>
          {/* <a className={this.showDropdown(0,'/abuse/rides')} onClick={()=>{this.props.children.props.history.push('/abuse/rides');}} >Abuse Reports</a> */}

        </div>
      </div>
    </li>
    {/*<li className={this.activeClass('/rides/history', '/abuse/rides', '/rides', '/details', '/scheduled/rides', '/ongoing/rides', '/completed/rides', '/canceled/rides')}>
      <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages1" aria-controls="collapsePages">
        <i className="fa fa-user" aria-hidden="true"></i>
        <span>Subjects</span>
      </a>
      <div id="collapsePages1" className={this.showDropdown(1,'/rides/history','/rides', '/scheduled/rides', '/abuse/rides', '/details')}  aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
          <div className="collapse-divider"></div>
          <a className={this.showDropdown(0,'/rides','/details' )} onClick={()=>{this.props.children.props.history.push('/rides/history');}} >All Rides</a>
          <a className={this.showDropdown(0,'/scheduled/rides')} onClick={()=>{this.props.children.props.history.push('/scheduled/rides');}} >Scheduled Rides</a>
          <a className={this.showDropdown(0,'/abuse/rides')} onClick={()=>{this.props.children.props.history.push('/abuse/rides');}} >Abuse Reports</a>

        </div>
      </div>
    </li>

     <li className={this.activeClass('/globalfair', '/fairmanage')}>
      <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages3" aria-controls="collapsePages">
        <i className="fa fa-money" aria-hidden="true"></i>
        <span>Fare Management</span>
      </a>
      <div id="collapsePages3" className={this.showDropdown(1,'/globalfair','/fairmanage')} aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
          <div className="collapse-divider"></div>
          <a className={this.showDropdown(0,'/globalfair')} onClick={()=>{this.props.children.props.history.push('/globalfair');}} >Add Global Fare</a>
          <a className={this.showDropdown(0,'/fairmanage')} onClick={()=>{this.props.children.props.history.push('/fairmanage');}} >Add Car Fare</a>
        </div>
      </div>
    </li>

    <li className={this.activeClass('/payment', '/eee')}>
      <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages4" aria-controls="collapsePages">
        <i className="fa fa-credit-card" aria-hidden="true"></i>
        <span>Payments</span>
      </a>
      <div id="collapsePages4"  className={this.showDropdown(1,'/payment')} aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
          <div className="collapse-divider"></div>
          <a className={this.showDropdown(0,'/payment')} onClick={()=>{this.props.children.props.history.push('/payment');}}> Payments Listing</a>
        </div>
      </div>
    </li>

    <li className={this.activeClass('/driver', '/rider/reviews', '/reviews')}>
      <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages5" aria-controls="collapsePages">
      <i className="fa fa-users" aria-hidden="true"></i>
        <span>Reviews</span>
      </a>
      <div id="collapsePages5" className={this.showDropdown(1,'/rider/reviews','/driver/reviews', '/reviews')} aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
          <div className="collapse-divider"></div>
          <a className={this.showDropdown(0,'/driver/reviews')} onClick={()=>{this.props.children.props.history.push('/driver/reviews');}}> Driver Reviews</a>
          <a className={this.showDropdown(0,'/rider/reviews')} onClick={()=>{this.props.children.props.history.push('/rider/reviews');}}> Rider Reviews</a>
        </div>
      </div>
    </li>

    <li className={this.activeClass('/vehicletypes')}>
      <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages6" aria-controls="collapsePages">
      <i className="fa fa-car" aria-hidden="true"></i>
        <span>Vehicle Types</span>
      </a>
      <div id="collapsePages6" className={this.showDropdown(1,'/vehicletypes')} aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
          <div className="collapse-divider"></div>
          <a className={this.showDropdown(0,'/vehicletypes')} onClick={()=>{this.props.children.props.history.push('/vehicletypes');}}> Vehicle Listing</a>
        </div>
      </div>
    </li>

    
    <li className={this.activeClass('/sosnumber')}>
      <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages7" aria-controls="collapsePages">
      <i className="fa fa-volume-control-phone" aria-hidden="true"></i>
        <span>Sos</span>
      </a>
      <div id="collapsePages7" className={this.showDropdown(1,'/sosnumber')} aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
          <div className="collapse-divider"></div>
          <a className={this.showDropdown(0,'/sosnumber')} onClick={()=>{this.props.children.props.history.push('/sosnumber');}}> Sos Information</a>
        </div>
      </div>
    </li>
    <li className={this.activeClass('/pages', '/addpage')}>
      <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages8" aria-controls="collapsePages">
      <i className="fa fa-globe" aria-hidden="true"></i>
        <span>Pages</span>
      </a>
      <div id="collapsePages8" className={this.showDropdown(1,'/pages')} aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
          <div className="collapse-divider"></div>
          <a className={this.showDropdown(0,'/pages')} onClick={()=>{this.props.children.props.history.push('/pages');}}> Pages Listing</a>
        </div>
      </div>
    </li>

    <li className={this.activeClass('/contactus','/bookingrequests')}>
      <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages9" aria-controls="collapsePages">
      <i class="fa fa-book" aria-hidden="true"></i>
        <span>All Requests</span>
      </a>
      <div id="collapsePages9" className={this.showDropdown(1,'/contactus','/bookingrequests')} aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
          <div className="collapse-divider"></div>
          <a className={this.showDropdown(0,'/bookingrequests')} onClick={()=>{this.props.children.props.history.push('/bookingrequests');}}> Booking Requests</a>
        
        <a className={this.showDropdown(0,'/contactus')} onClick={()=>{this.props.children.props.history.push('/contactus');}}> Contact us Listing</a>
        </div>
      </div>
    </li>

    <li className={this.activeClass('/servicingarea')}>
      <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages11" aria-controls="collapsePages">
      <i className="fa fa-book" aria-hidden="true"></i>
        <span>Servicing Area</span>
      </a>
      <div id="collapsePages11" className={this.showDropdown(1,'/servicingarea')} aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
          <div className="collapse-divider"></div>
          <a className={this.showDropdown(0,'/servicingarea')} onClick={()=>{this.props.children.props.history.push('/servicingarea');}}> Update Servicing Area</a>
        
        </div>
      </div>
    </li> */}

    {/* <!-- Divider --> */}
    <hr className="sidebar-divider d-none d-md-block" />

  </ul>

<div id="content-wrapper" className="d-flex flex-column">

{/* <!-- Main Content --> */}
<div id="content">

  <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

    {/* <!-- Sidebar Toggle (Topbar) --> */}
    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
      <i className="fa fa-bars"></i>
    </button>

    {/* <!-- Topbar Navbar --> */}
    <ul className="navbar-nav ml-auto">

      {/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
      <li className="nav-item dropdown no-arrow d-sm-none">
        <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="fas fa-search fa-fw"></i>
        </a>
        {/* <!-- Dropdown - Messages --> */}
        <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
          <form className="form-inline mr-auto w-100 navbar-search">
            <div className="input-group">
              <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
              <div className="input-group-append">
                <button className="btn btn-primary" type="button">
                  <i className="fas fa-search fa-sm"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </li>

      <div className="topbar-divider d-none d-sm-block"></div>

      {/* <!-- Nav Item - User Information --> */}
      <li className="nav-item dropdown no-arrow">
        <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span className="mr-2 d-none d-lg-inline text-gray-600 small">{this.props.user.fullName}</span>
          <img className="img-profile rounded-circle" src={this.props.user.profile_image == null ? profile :this.props.user.profile_image } />
        </a>
        {/* <!-- Dropdown - User Information --> */}
        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">

          <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" onClick={()=>this.logout(this.props.user)}>
            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
            Logout
          </a>
        </div>
      </li>

    </ul>

  </nav>
  {this.props.children}
  </div>
  <footer className="sticky-footer bg-white">
    <div className="container my-auto">
      <div className="copyright text-center my-auto">
        <span>Copyright &copy; Pyramid Admin 2021</span>
      </div>
    </div>
  </footer>

  </div>
  </div>
);
};
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  logOut: bindActionCreators(logOut, dispatch),
  getDriverData:bindActionCreators(getDriverData, dispatch),
  setCommission:bindActionCreators(setCommission, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(Header);
