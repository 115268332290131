import React, { Component } from 'react';
import Table from '../components/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getRequestsData } from '../actions/admin';
import _ from 'underscore';
import loading from '../img/loading.gif';

class RequestsToAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
     flag: false,
     data: []
    };
    this.type = props.match.params.type;
  }

  componentDidMount() {
    let context = this;
    let params = {
      token: context.props.user.loginToken,
      type: 1
    };
    context.props.getRequestsData(params, res => {
           this.setState({flag:true});
        });
  }

  render() {
    const columns =[
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Phone Number',
        accessor: 'phone'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },    
    //   {
    //     Header: 'Subject',
    //     accessor: 'subject'
    //   },
      {
        Header: 'Message',
        accessor: 'message'
      },
      {
        Header: ' Date',
        accessor: 'createdAt'
      }
    ];
    let data = this.props.user.requestData;
      return (
        <section className="main-content frontend" id="home">
        <section className="content">
        <div className="main-container">
        <div className="container-fluid">
  
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800"> Contact us List </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 table-custom">
          { this.state.flag ? <Table data={ data } columns={ columns } tableType={ 'requests' } fileName={'Requests'}/> : (
                    <img src={loading} className="loading" />
                  ) }
          </div>
      </div>
       </div>
    </div>
  </section>
      </section>
  
      );
    }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
    getRequestsData: bindActionCreators(getRequestsData, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(RequestsToAdmin);
