/*
 * @file: user.js
 * @description: It Contain action types Related Action.
 * @date: 26.09.2018
 * @author: Megha Sethi
 */

/******** User *********/
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOG_OUT = 'LOG_OUT';
export const REMEMBER_LOGIN = 'REMEMBER_LOGIN';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const DRIER_DATA = 'DRIER_DATA';
export const SUBJECTS_DATA = 'SUBJECTS_DATA';
export const LEVELS_DATA = 'LEVELS_DATA';
export const TOPICS_DATA = 'TOPICS_DATA';
export const QUESTIONS_DATA = 'QUESTIONS_DATA';
export const FORGET_TOKEN = 'FORGET_TOKEN';
export const CAR_DATA = 'CAR_DATA';
export const RATING_DATA = 'RATING_DATA';
export const PAGE_DATA = 'PAGE_DATA';
export const PAYMENT_DATA = 'PAYMENT_DATA';
export const REQUEST_DATA = 'REQUEST_DATA';
