import React, { Component } from 'react';
import Table from '../components/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getReportAbuse, solveAbuse } from '../actions/booking';
import * as toast from '../actions/toast-actions';
import $ from 'jquery';
import closeIcon from '../img/close-icon.png';
import loading from '../img/loading.gif';

class Abuse extends Component {
  constructor(props) {
    super(props);
    this.state = {
     bookingId : '',
     flag:false
    };
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    let context = this;
    let params = {
      token: context.props.user.loginToken
    };
    context.props.getReportAbuse(params, res => {this.setState({flag:true});});
  }

  changeStatus = (id, reason) => {
    if (reason !== '') {
      let data = { bookingId: id, token:this.props.user.loginToken, reason: reason};
      this.props.solveAbuse(data, res => {
        if (res.status) {
            toast.toastAction(res.status, res.message);
            $('[data-dismiss=modal]').trigger({ type: 'click' });
        } else {
            toast.toastAction(res.status, res.message);
        }
      });}
      else{
        toast.toastAction(false, 'Reason field can not be empty');
    }
  };

  openModal(props) {
    $('#reason').val('');
    this.setState({ bookingId: props.original.bookingId });
  }

  render() {
    const columns =[
      {
        Header: 'Booking Number',
        accessor: 'bookingNumber'
      },
      {
        Header: 'Rider Name',
        accessor: 'createdByName'
      },
      {
        Header: 'Driver Name',
        accessor: 'driverName'
      },
      {
        Header: 'Reported By Rider',
        accessor: 'isReportedByCustomer'
      },
      {
        Header: 'Rider\'s Reason',
        accessor: 'reportedReasonByCustomer'
      },
      {
        Header: 'Reported By Driver',
        accessor: 'isReportedByDriver'
      },
      {
        Header: 'Driver Reason',
        accessor: 'reportedReasonByDriver'
      },
      {
        Header: 'Action',
        accessor: 'action',
        sortable: false,
        filterable: false,
        Cell: props => (
            <button
            type="button"
            className="add-driver"
            onClick={() => {
              this.openModal(props);
            }}
            data-toggle="modal"
            href="#myModal"
          >Resolve
            </button>
        )
      }
    ];
    let data = this.props.booking.reportAbuse;
    if(data.length){
      data = data.filter(item => {
          item.createdByName = item.createdByName.capitalizeFirstLetter();
          item.driverName = item.driverName.capitalizeFirstLetter();
          return item;
      });
    }
    return (
      <div>
        <section className="main-content frontend" id="home">
        <section className="content">
        <div className="main-container">
        <div className="container-fluid">

        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Abuses Reports</h1>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 table-custom">
              { this.state.flag ? <Table data={ data } columns={ columns } tableType={ 'abuse' } fileName = {'Abuse Report'}/> : (
                  <img src={loading} className="loading" />
                ) }
          </div>
        </div>
      </div>
      </div>
      </section>  
      </section>
      <div
      aria-hidden="true"
      aria-labelledby="myModalLabel"
      role="dialog"
      tabIndex="-1"
      id="myModal"
      className="modal fade"
      >
      <div className="modal-dialog report-abuse">
        <div className="modal-content">
          <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
            <img src = {closeIcon} />
          </button>
          <h4 align="center">
          Resolve Request
          </h4>
          <div className="col-md-12  text-center">
            <textarea id = "reason" align = 'center'></textarea>
            <button className="report-abuse-btn" onClick={() => {
              this.changeStatus(this.state.bookingId, document.getElementById('reason').value);
            }}>Resolve issue</button>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
  }
}

const mapStateToProps = state => ({ user: state.user, booking : state.booking });
const mapDispatchToProps = dispatch => ({
  getReportAbuse: bindActionCreators(getReportAbuse, dispatch),
  solveAbuse: bindActionCreators(solveAbuse, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Abuse);