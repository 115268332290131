import React, { Component } from 'react';
import Table from '../components/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeSubjectStatus, getSubjects, getSubjectData, deleteSubject } from '../actions/admin';
import * as toast from '../actions/toast-actions';
import _ from 'underscore';
import loading from '../img/loading.gif';

class Subjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: false
    };
  }

  openModal(props, tab) {
    let data = props.original;
    if (tab) {
      this.props.history.push(`/documents/${data.userId}/docs`);
      //window.open(`/documents/${data.userId}`, '_blank'); //to open new page2
    } else {
      this.props.history.push(`/documents/${data.userId}/vehicle`);
    }
  }

  componentDidMount() {
    let context = this;
    let params = {
      token: context.props.user.loginToken
    };
    context.props.getSubjects(params, res => { this.setState({ flag: true }); });
  }


  changeStatus = (sta, id) => {
      let token = this.props.user.loginToken;
    if (sta !== 'Select' && sta !== '2' && sta !== '3' && sta !== '4') {
      let data = { id: id, token , status: sta };
      this.props.changeSubjectStatus(data, res => {
        if (res.status) {
          toast.toastAction(res.status, res.message);
          let updateStatus = _.findWhere(this.props.user.subjectData, { id: id });
          updateStatus = { ...updateStatus, status: parseInt(sta) };
          let index = this.props.user.subjectData.findIndex(item => item.id === id);
          let subjectsData = this.props.user.subjectData;
          subjectsData[index] = updateStatus;
          // this.props.user.subjectData = _.map(this.props.user.subjectData, { id: id }, {status:sta});
          this.props.getSubjectData(subjectsData);
        } else {
          toast.toastAction(res.status, res.message);
        }
      });
    } else if(sta == '3') {
      this.props.history.push(`/subjects/edit-subject/${btoa(id)}`);
    } else if(sta == '4')  {
      this.props.deleteSubject({id: id}, token, res => {
          if (res.status) {
            toast.toastAction(res.status, res.message);
            let updateStatus = _.findWhere(this.props.user.subjectData, { id: id });
            updateStatus = { ...updateStatus };
            let index = this.props.user.subjectData.findIndex(item => item.id === id);
            this.props.user.subjectData.splice(index, 1);
            this.props.getSubjectData(this.props.user.subjectData);
          } else {
            toast.toastAction(res.status, res.message);
          }
      });
      
    }
  };

  test = () => {
    this.props.history.push('/subjects/addsubject');
  }

  render() {

    const columns = [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Visible to all',
        accessor: 'status',
        Cell: props => (
          <i className={props.original.status == 1 ? 'fa fa-check custom-check' :'fa fa-ban custom-ban'} aria-hidden="true" ></i>
        )
      },
      {
        Header: 'Action',
        accessor: 'action',
        sortable: false,
        filterable: false,
        Cell: props => (
          <select
            id="chan"
            onClick={e => {
              this.changeStatus(e.target.value, props.original.id);
            }}
          >
            <option value="Select">Select Action</option>
            {props.original.status == 1 ? <option value='0'>Block</option> : <option value='1'>Approve</option>}
            {/* <option value='2'>Edit</option> */}
            <option value='3'>Edit</option>
            <option value='4'>Delete</option>
          </select>
        )
      }
    ];

     let subjectData = this.props.user.subjectData;

    return (
      <section className="main-content frontend" id="home">
        <section className="content">
          <div className="main-container">
            <div className="container-fluid">

              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Subjects</h1>
              </div>
              <div className="row">
                 <div className="col-md-12 col-sm-12">
                  <button className='add-driver1' onClick={this.test}>Add Subject</button>
                </div> 
                <div className="col-xl-12 col-lg-12 table-custom">
                  {this.state.flag ? <Table data={subjectData} columns={columns} tableType={'subjects'} fileName={'Subjects'} /> : (
                    <img src={loading} className="loading" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  changeSubjectStatus: bindActionCreators(changeSubjectStatus, dispatch),
  getSubjects: bindActionCreators(getSubjects, dispatch),
  deleteSubject: bindActionCreators(deleteSubject, dispatch),
  getSubjectData: bindActionCreators(getSubjectData, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Subjects);
