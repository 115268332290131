import * as TYPE from '../../constants/actionTypes/user';

/******** Reducers ********/

const initialState = {
  loggedIn: false,
  socketConnected: false,
  remember: {},
  driverData : [],
  subjectData : [],
  topicsData : [],
  questionsData : [],
  levelData: [],
  token: ''
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LOGIN_SUCCESS:
      return { ...state, ...{ loggedIn: true }, ...action.data };

    case TYPE.LOG_OUT:
      let _remember = state.remember;
      return { ...initialState, ...{ remember: _remember } };

    case TYPE.SOCKET_CONNECTED:
      return { ...state, ...{ socketConnected: true } };
    
    case TYPE.FORGET_TOKEN:
        return { ...state, ...{token: action.data} };  

    case TYPE.DRIER_DATA:
      return { ...state, ...{driverData: action.data} };

    case TYPE.SUBJECTS_DATA:
      return { ...state, ...{subjectData: action.data} };

    case TYPE.TOPICS_DATA:
      return { ...state, ...{topicsData: action.data} };

    case TYPE.QUESTIONS_DATA:
      return { ...state, ...{questionsData: action.data} };
    
      case TYPE.LEVELS_DATA:
      return { ...state, ...{levelData: action.data} };

    default:
      return state;
  }
}
