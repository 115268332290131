import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCommission, login_Success } from '../actions/admin';
import * as toast from '../actions/toast-actions';
import AlertMsg from '../components/AlertMsg';
import _ from 'underscore';
import loading from '../img/loading.gif';

class Sos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      step: 1,
      msg: '',
      msgType: '',
      msgStatus: false,
      flag: true,
      sosNumber: ''
    };
  }

  resetState = () => {
    this.setState({
      sosNumber: ''
    });
  }

  handelSosNumber = (event) => {
    event.preventDefault();
    this.setState({flag:false})
    let c = document.getElementById('sosNumber').value;
    let data={sosNumber: c, token: this.props.user.loginToken };
    if(/^\d+$/.test(c)){
       this.props.setCommission(data, res => {
        if (res.status) {
          let update = {...this.props.user, sosNumber: '+'+ this.state.sosNumber};
         // console.log('====up', update)
          this.props.login_Success(update);
          this.setState({flag:true, step:1})
          toast.toastAction(res.status, res.message);
        
         //this.props.history.replace('/');
        } else {
          this.setState({
            open: true,
            msg: res.message,
            msgType: res.type,
            msgStatus: res.status,
            flag: true
          });
        }
      });
    }
    else{
      this.setState({
        open: true,
        msg: 'This field can not be empty',
        msgType: 'error',
        msgStatus: false,
        flag: true
      });

    }
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  render() {
      if(this.state.step == 1){
        return (
            <section className="main-content frontend" id="home">
            <section className="content">
            <div className="main-container">
            <div className="container-fluid">
            <AlertMsg
            onPress={() => this.setState({ open: false })}
            isShowingModal={this.state.open}
            msg={this.state.msg}
            type={this.state.msgType}
            status={this.state.msgStatus}
          />
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 class="h3 mb-0 text-gray-800">The SOS Number</h1>
              </div>
            <div className="row">
              
              <div class="mainouter sos-outer">
              <div class="licence-outer">
                    
                <h5> <b>SOS Number:</b><span> { this.props.user.sosNumber }</span></h5>
               
                <div class="col-md-12 col-sm-12 driver-class1">
            <button className='add-driver1' onClick={()=>{ this.setState({step:2})}}>Update SOS</button>
            </div>
               
                </div> 
                </div>
          </div>
           </div>
        </div>
      </section>
          </section>
          );
      }else{
        return (
            <section className="main-content frontend" id="home">
            <section className="content">
            <div className="main-container">
            <div className="container-fluid">
            <AlertMsg
            onPress={() => this.setState({ open: false })}
            isShowingModal={this.state.open}
            msg={this.state.msg}
            type={this.state.msgType}
            status={this.state.msgStatus}
          />
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 class="h3 mb-0 text-gray-800">Update SOS Number</h1>
              </div>
            <div className="row">
            <div className="col-md-12 col-sm-12 driver-class1">
        <button className='add-driver1' onClick={()=>{ this.setState({step:1})}}>Go Back</button>
        </div>
              <div class="mainouter">
              { this.state.flag ? 
              <form onSubmit={this.handelSosNumber}>
             <div className="col-xl-12 col-lg-12 table-custom driver-table">
              <div class="col-md-12 col-sm-12 add-rider-clm">
                <div class="add-rider-outer">
                 <label className="add-support">Add Support Number<span className='red'> *</span></label>
                 <input type="number" placeholder="Sos Number" id='sosNumber' min='0' value={this.state.sosNumber} onChange={this.handleChange}/>
                
                 <div class="rider-btns">
                <button className='add-driver1 btn-margin' type='submit'>Update</button>
                <button className='add-driver1 btn-margin' type='reset' onClick={this.resetState}>Reset</button>
                </div>
                    </div>
                </div>
                </div>
                </form> : (
                      <img src={loading} className="loading" />
                    ) }
               
                
                </div>
          </div>
           </div>
        </div>
      </section>
          </section>
          );
      }
      
    }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  setCommission: bindActionCreators(setCommission, dispatch),
  login_Success: bindActionCreators(login_Success, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Sos);
