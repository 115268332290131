import React, { Component } from 'react';
import Table from '../components/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getBookingData } from '../actions/booking';
import _ from 'underscore';
import loading from '../img/loading.gif';

class Scheduled extends Component {
  constructor(props) {
    super(props);
    this.state = {
     flag: false
    };
  }

  componentDidMount() {
    let context = this;
    let params = {
      token: context.props.user.loginToken,
      type : 1
    };
    context.props.getBookingData(params, res => {this.setState({flag:true })});
  }

  openModal(props, tab) {
    let data = props.original;
    if(tab){
      this.props.history.push(`/documents/${data.bookingNumber}/details`);
      //window.open(`/documents/${data.userId}`, '_blank'); //to open new page2
    }
  }

  render() {
    const columns =[
      {
        Header: 'Booking Number',
        accessor: 'bookingNumber'
      },
      {
        Header: 'Rider Name',
        accessor: 'userName'
      },
      {
        Header: 'Fare',
        accessor: 'totalCost'
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: props => (  
          <i className={props.original.status == 3 ? 'fa fa-check custom-check': props.original.rideStatus == 6 ||  props.original.rideStatus == 7  ? 'fa fa-ban' : 'fa fa-spinner' } aria-hidden="true" ></i>
          )
      },
      {
        Header: 'Trip date',
        accessor: 'createdAt'
      },  
      // {
      //   Header: 'Action',
      //   accessor: 'action',
      //   sortable: false,
      //   filterable: false,
      //   Cell: props => (
      //     <div class="col-md-12 col-sm-12 driver-class">
      //   <button className='add-driver' onClick={() => {
      //              this.openModal(props,true);
      //            }}>View</button>
      //   </div>
      //   )
      // }
    ];
   
   let data = this.props.booking.bookingData;

    return (
      <section className="main-content frontend" id="home">
      <section className="content">
      <div className="main-container">
      <div className="container-fluid">

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800"> Scheduled Ride</h1>
      </div>
      <div className="row">
        <div className="col-xl-12 col-lg-12 table-custom">
          { this.state.flag ? <Table data={ data } columns={ columns } tableType={ 'schedule' } fileName={'Schedule'}/> : (
                  <img src={loading} className="loading" />
                ) }
        </div>
    </div>
     </div>
  </div>
</section>
    </section>
    );
   }
}

const mapStateToProps = state => ({ user: state.user,  booking : state.booking });
const mapDispatchToProps = dispatch => ({
  getBookingData: bindActionCreators(getBookingData, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Scheduled);
