import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { totalCount } from '../actions/admin';
import loading from '../img/loading.gif';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driverCount: 0,
      riderCount: 0,
      bookingCount: 0,
      completeRides: 0,
      cancelRides: 0,
      onWayRides: 0,
      earing: 0,
      block: 0,
      flag: false
    };
  }

  componentDidMount() {
    let context = this;
    let params = {
      token: context.props.user.loginToken
    };

    context.props.totalCount(params, res => {
      this.setState({ flag: true });
      if (res.status) {
        let data = res.data;
        this.setState({ driverCount: data.driverCount, riderCount: data.riderCount, totalRides: data.bookingCount, completeRides: data.completeRides, cancelRides: data.cancelRides, onWayRides: data.onWayRides, earing: data.earning, block: data.blockrider });
      }
    });
  }

  render() {
    return (
      <section className="main-content frontend" id="home">
        <section className="content">
          <div className="main-container">
            <div class="container-fluid">

              <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
              </div>
              {this.state.flag ?
                <div className="row">
                  <div class="col-md-6 col-sm-12 dashboard-col">
                    <div class="user-outer">
                      <h3><i class="fa fa-user" aria-hidden="true"></i> Site Statistics</h3>
                      <div class="col-xl-6 col-md-6 mb-4 dashboard-div second-div cur" onClick={() => { this.props.history.push('/drivers'); }}>
                        <div class="shadow">
                          <div class="col-md-4 icon-div">
                            <i class="fa fa-male"></i>
                          </div>
                          <div class="col-md-8 driver-text">
                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Drivers</div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.driverCount}</div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-6 col-md-6 mb-4 dashboard-div third-div cur" onClick={() => { this.props.history.push('/riders'); }}>
                        <div class="shadow">
                          <div class="col-md-4 icon-div">
                            <i class="fa fa-car" aria-hidden="true"></i>
                          </div>
                          <div class="col-md-8 driver-text">
                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Users</div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.riderCount}</div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-6 col-md-6 mb-4 dashboard-div sixth-div cur" onClick={() => { this.props.history.push('/blockedusers'); }}>
                        <div class="shadow">
                          <div class="col-md-4 icon-div">
                            <i class="fa fa-users" aria-hidden="true"></i>
                          </div>
                          <div class="col-md-8 driver-text">
                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Blocked Users</div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.block}</div>
                          </div>
                        </div>
                      </div>


                      <div class="col-xl-6 col-md-6 mb-4 dashboard-div first-div cur" onClick={() => { this.props.history.push('/payment'); }}>
                        <div class="shadow">
                          <div class="col-md-4 icon-div">
                            <i class="fa fa-money"></i>
                          </div>
                          <div class="col-md-8 driver-text">
                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Total Questions</div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.earing}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="col-md-6 col-sm-12 dashboard-col">
                    <div class="user-outer">
                      <h3><i class="fa fa-car" aria-hidden="true"></i>Total Exam Tests</h3>
                      <div class="col-xl-6 col-md-6 mb-4 dashboard-div fourth-div cur" onClick={() => { this.props.history.push('/rides/history'); }}>
                        <div class="shadow">
                          <div class="col-md-4 icon-div">
                            <i class="fa fa-cubes"></i>
                          </div>
                          <div class="col-md-8 driver-text">
                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Total Topics</div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.totalRides}</div>
                          </div>
                        </div>
                      </div>



                      <div class="col-xl-6 col-md-6 mb-4 dashboard-div fifth-div cur" onClick={() => { this.props.history.push('/rides/ongoing'); }}>
                        <div class="shadow">
                          <div class="col-md-4 icon-div">
                            <i class="fa fa-clone"></i>
                          </div>
                          <div class="col-md-8 driver-text">
                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1">On Going Tests </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.onWayRides}</div>
                          </div>
                        </div>
                      </div>


                      <div class="col-xl-6 col-md-6 mb-4 dashboard-div sixth-div cur" onClick={() => { this.props.history.push('/rides/canceled'); }}>
                        <div class="shadow">
                          <div class="col-md-4 icon-div">
                            <i class="fa fa-times-circle-o"></i>
                          </div>
                          <div class="col-md-8 driver-text">
                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Subscribed Users </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.cancelRides}</div>
                          </div>
                        </div>
                      </div>


                      <div class="col-xl-6 col-md-6 mb-4 dashboard-div seventh-div cur" onClick={() => { this.props.history.push('rides/completed'); }}>
                        <div class="shadow">
                          <div class="col-md-4 icon-div">
                            <i class="fa fa-check"></i>
                          </div>
                          <div class="col-md-8 driver-text">
                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Completed Tests </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.completeRides}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : (
                  <img src={loading} className="loading" />
                )}



              {/* <div class="row">

        <div class="col-xl-8 col-lg-7">
        
        <Table data={this.props.user.driverData} />

          <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Earnings Overview</h6>
              <div class="dropdown no-arrow">
                <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                  <div class="dropdown-header">Dropdown Header:</div>
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Something else here</a>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="chart-area">
                <canvas id="myAreaChart"></canvas>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-lg-5">
          <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Drivers</h6>
              <div class="dropdown no-arrow">
                <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                  <div class="dropdown-header">Dropdown Header:</div>
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Something else here</a>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="chart-pie pt-4 pb-2">
                <canvas id="myPieChart"></canvas>
              </div>

              <div class="chart-pie pt-4 pb-2">
                <div class="col-lg-6">
                    <h3>Drivers  Count : {this.props.user.driverData[0].driverCount}</h3>
                    <p>Today : <b>4</b></p>
                    <p>This Month : <b>0</b></p>
                    <p>This Year : <b>0</b></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">

        <div class="col-lg-6 mb-4">

          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Projects</h6>
            </div>
            <div class="card-body">
              <h4 class="small font-weight-bold">Server Migration <span class="float-right">20%</span></h4>
              <div class="progress mb-4">
                <div class="progress-bar bg-danger progress-20" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <h4 class="small font-weight-bold">Sales Tracking <span class="float-right">40%</span></h4>
              <div class="progress mb-4">
                <div class="progress-bar bg-warning" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <h4 class="small font-weight-bold">Customer Database <span class="float-right">60%</span></h4>
              <div class="progress mb-4">
                <div class="progress-bar" role="progressbar"  aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <h4 class="small font-weight-bold">Payout Details <span class="float-right">80%</span></h4>
              <div class="progress mb-4">
                <div class="progress-bar bg-info" role="progressbar"  aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <h4 class="small font-weight-bold">Account Setup <span class="float-right">Complete!</span></h4>
              <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 mb-4">
              <div class="card bg-primary text-white shadow">
                <div class="card-body">
                  Primary
                  <div class="text-white-50 small">#4e73df</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-4">
              <div class="card bg-success text-white shadow">
                <div class="card-body">
                  Success
                  <div class="text-white-50 small">#1cc88a</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-4">
              <div class="card bg-info text-white shadow">
                <div class="card-body">
                  Info
                  <div class="text-white-50 small">#36b9cc</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-4">
              <div class="card bg-warning text-white shadow">
                <div class="card-body">
                  Warning
                  <div class="text-white-50 small">#f6c23e</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-4">
              <div class="card bg-danger text-white shadow">
                <div class="card-body">
                  Danger
                  <div class="text-white-50 small">#e74a3b</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-4">
              <div class="card bg-secondary text-white shadow">
                <div class="card-body">
                  Secondary
                  <div class="text-white-50 small">#858796</div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="col-lg-6 mb-4">

          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Illustrations</h6>
            </div>
            <div class="card-body">
              <div class="text-center">
                <img class="img-fluid px-3 px-sm-4 mt-3 mb-4"  src="img/undraw_posting_photo.svg" alt="" />
              </div>
              <p>Add some quality, svg illustrations to your project courtesy of <a target="_blank" rel="nofollow" href="https://undraw.co/">unDraw</a>, a constantly updated collection of beautiful svg images that you can use completely free and without attribution!</p>
              <a target="_blank" rel="nofollow" href="https://undraw.co/">Browse Illustrations on unDraw &rarr;</a>
            </div>
          </div>

          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Development Approach</h6>
            </div>
            <div class="card-body">
              <p>SB Admin 2 makes extensive use of Bootstrap 4 utility classes in order to reduce CSS bloat and poor page performance. Custom CSS classes are used to create custom components and custom utility classes.</p>
              <p class="mb-0">Before working with this theme, you should become familiar with the Bootstrap framework, especially the utility classes.</p>
            </div>
          </div>
        </div>
      </div> */}
            </div>
          </div>
        </section>
      </section>

    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  totalCount: bindActionCreators(totalCount, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);