import React, { Component } from 'react';
import Table from '../components/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeStatus, getDriverData, getDriverdata } from '../actions/admin';
import * as toast from '../actions/toast-actions';
import _ from 'underscore';
import loading from '../img/loading.gif';

class Drivers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: false
    };
  }

  openModal(props, tab) {
    let data = props.original;
    if (tab) {
      this.props.history.push(`/documents/${data.userId}/docs`);
      //window.open(`/documents/${data.userId}`, '_blank'); //to open new page2
    } else {
      this.props.history.push(`/documents/${data.userId}/vehicle`);
    }
  }

  componentDidMount() {
    let context = this;
    let params = {
      token: context.props.user.loginToken
    };
    context.props.getDriverData(params, res => { this.setState({ flag: true }); });
  }


  changeStatus = (sta, id) => {
    if (sta !== 'Select' && sta !== 'edit') {
      let data = { id: id, token: this.props.user.loginToken, status: sta };
      this.props.changeStatus(data, res => {
        if (res.status) {
          toast.toastAction(res.status, res.message);
          let updateStatus = _.findWhere(this.props.user.driverData, { id: id });
          updateStatus = { ...updateStatus, status: sta };
          let index = this.props.user.driverData.findIndex(item => item.id === id);
          let usersData = this.props.user.driverData;
          usersData[index]=updateStatus;
          // console.log('u',id,this.props.user.driverData,usersData)
          // this.props.user.driverData.splice(index, 1, updateStatus);
          this.props.getDriverdata(usersData);
        } else {
          toast.toastAction(res.status, res.message);
        }
      });
    } else if(sta == 'edit') {
       this.props.history.push(`/users/edit-user/${btoa(id)}`);
    }
  };

  test = () => {
    this.props.history.push('/drivers/adduser');
  }

  render() {

    const columns = [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Username',
        accessor: 'username',
        // Cell: props => (
        //   <div className="col-md-12 col-sm-12 driver-class cur" onClick={() => {
        //     this.openModal(props, false);
        //   }}>
        //     1
        //   </div>
        //)
      },
      {
        Header: 'Mobile',
        accessor: 'mobile_number'
      },
      {
        Header: 'Role',
        accessor: 'role',
        sortable: false,
        filterable: false,
        Cell: props => (
          <p>{props.original.role == '0' ? 'Student' : 'Parent'}</p>
        )
      },
      {
        Header: 'Address',
        accessor: 'address',
        sortable: false,
        filterable: false,
        // Cell: props => (
        //   <i className="fa fa-file-text-o cur" aria-hidden="true" onClick={() => {
        //     this.openModal(props, true);
        //   }}></i>
        // )
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: props => (
          <i className={props.original.status == 1 ? 'fa fa-check custom-check' : props.original.status == 2 ? 'fa fa-times custom-times' : props.original.status == 3 ? 'fa fa-ban custom-ban' : 'fa fa-clock-o'} aria-hidden="true" ></i>
        )
      },
      {
        Header: 'Action',
        accessor: 'action',
        sortable: false,
        filterable: false,
        Cell: props => (
          <select
            id="chan"
            onClick={e => {
              this.changeStatus(e.target.value, props.original.id);
            }}
          >
            <option value="Select">Select Action</option>
            {props.original.status == 1 ? <option value='2'>Block</option> : <option value='1'>Approve</option>}
            <option value="edit">Edit</option>
          </select>
        )
      }
    ];
    let userData = this.props.user.driverData.filter(
       item => (item.status != 3));
    // let userData = this.props.user.driverData.filter(
    //   item => (item.userActiveRole == 1 && item.status != 3));

    return (
      <section className="main-content frontend" id="home">
        <section className="content">
          <div className="main-container">
            <div className="container-fluid">

              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">All Users</h1>
              </div>
              <div className="row">
                {/* <div className="col-md-12 col-sm-12 driver-class1">
                  <button className='add-driver1' onClick={this.test}>Add Student</button>
                </div> */}
                <div className="col-xl-12 col-lg-12 table-custom">
                  {this.state.flag ? <Table data={userData} columns={columns} tableType={'students'} fileName={'Students'} /> : (
                    <img src={loading} className="loading" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  changeStatus: bindActionCreators(changeStatus, dispatch),
  getDriverData: bindActionCreators(getDriverData, dispatch),
  getDriverdata: bindActionCreators(getDriverdata, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Drivers);
