import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Gmaps, Marker, Circle } from 'react-gmaps';
import constants from '../constants/connection';
import { bindActionCreators } from 'redux';
import { login_Success } from '../actions/admin';
import * as toast from '../actions/toast-actions';
import loading from '../img/loading.gif';
const params = {v:constants.v, key: constants.GOOGLE_API_KEY };

class ServingArea extends Component {
  state = {
    lat: this.props.user.location[1],
    lng: this.props.user.location[0],
    radius: this.props.user.radius,
    cen: this.props.user.centerPoint ? this.props.user.centerPoint : 'NA',
    flag: true
  };

  onMapCreated(map) {
    map.setOptions({
      disableDefaultUI: false,
      streetViewControl: false
    });
  }

  onClick(context,e) {
    const { latLng } = e;
    this.getAddress();
    context.setState({lat: latLng.lat(), lng: latLng.lng()})
  }

  onDragEnd(context,e) {
    const { latLng } = e;
    this.getAddress();
    context.setState({lat: latLng.lat(), lng: latLng.lng()})
  }

  getAddress = () => {
    let context = this;
    let l = {lat: context.state.lat, lng: context.state.lng};
    let geocoder = new window.google.maps.Geocoder();
      geocoder.geocode( {location:l }, function(results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
            //console.log('=========', results)
            context.setState({cen: results[0].formatted_address})
        }else{
            context.setState({cen: 'NA'})
        }
      });
  }
  
  // updateLoction = () => {
  //    // console.log('==========props')
  //     let context = this;
  //     context.setState({flag: false});
  //     let formData = new FormData();
  //     let location = { coordinates: [context.state.lng, context.state.lat], radius:context.state.radius, centerPoint: context.state.cen};
  //     formData.append('location', JSON.stringify(location) );
  //     context.props.updateProfile(formData, context.props.user.loginToken, res => {
  //     //  console.log('--------------res', res)
  //       if (res.status) {
  //           let update = {...this.props.user, location: [context.state.lng, context.state.lat], radius: context.state.radius,centerPoint: context.state.cen};
  //           this.props.login_Success(update);
  //           toast.toastAction(res.status, res.message);
  //         } else {
  //           toast.toastAction(false, 'SomeThing went wrong')
  //         }
  //         context.setState({flag: true});
  //     });
  // }

  reset = () => {
    let loc = this.props.user.location;
    this.setState({lat: loc[1], lng: loc[0], radius: this.props.user.radius});
}

changeRadius = event => {
    console.log('-----------rea', event.target.value)
    let radius = parseInt(event.target.value);
    this.setState({radius: radius});
}
 
  render() {
      console.log('=============', this.state)
    return (
    <div>
        <section className="main-content frontend" id="home">
        <section className="content">
        <div className="main-container">
        <div className="container-fluid">

        <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Serving Area </h1>
        </div>
        <div className='map'>
        { this.state.flag ? 
            <Gmaps
                width={'100%'}
                onClick={e => this.onClick(this,e)}
                height={'500px'}
                lat={this.state.lat}
                lng={this.state.lng}
                zoom={8}
                loadingMessage={'loading'}
                params={params}
                onMapCreated={this.onMapCreated}>   
                <Marker
               lat={this.state.lat}
               lng={this.state.lng}
                draggable={true}
            onDragEnd={e => this.onDragEnd(this,e)}
                ref="Marker"
                />
            <Circle
            lat={this.state.lat}
            lng={this.state.lng}
            radius={this.state.radius}
            onClick={e => this.onClick(this,e)} />
            </Gmaps>: (
                      <img src={loading} className="loading" />
                    ) }
            </div>
            <div className="map-lower-div">
            <span>Center Point</span> <textarea type='cen' id='cen' value={this.state.cen}></textarea>
            <span>Radius</span> <input type='number' id='rad' value={this.state.radius} onChange={this.changeRadius}></input>
            <div className="map-lower-btns">
            <button className="add-driver" onClick={this.updateLoction}> Update </button>
            <button className="add-driver" onClick={this.reset}> Cancel </button>
            </div>
            </div>
        </div>
     </div>
    </section>
    </section>
</div>
    );
  }
}
const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({ 
  //  updateProfile: bindActionCreators(updateProfile, dispatch),
    login_Success: bindActionCreators(login_Success, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServingArea);
